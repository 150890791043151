import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import PageTitle from "../components/PageTitle";
import PreviousPage from "../components/PreviousPage";
import SelectInput from "../components/SelectInput";
import { fetchDirectLocals, fetchPaymentMethod, updateApartment } from "../shared/queries";
import { BaseSelectItem, Local } from "../types";
import dayjs from 'dayjs';


export default function AssociateApartmentCard () {

  const { t } = useTranslation()

  const {
    data,
    refetch,
    isLoading
  } = useQuery('paymentMethods', fetchPaymentMethod)

  const {
    data: locals,
    refetch: refetchLocals,
    isLoading: isLoadingLocals
  } = useQuery('localsList', fetchDirectLocals)

  const mutatePaymentAccount = useMutation(({ local, method } : {local: Local, method: BaseSelectItem }) => updateApartment(local.local_key || '', {
    stripe_pm_id: method.value
  }), {
    onSuccess: () => {
      refetchLocals()
      toast.success(t('general.operationCompleted'))
    }
  })

  function getCurrentMethod (local: Local) {
    if (data) {
      const pm = data.results.find(pm => pm.id === local.stripe_pm_id)
      if (pm) {
        if (pm.card) {
          return {
            label: t('associateApartmentCard.card') + ' •••• •••• •••• ' + pm.card.last4 + ' ('+t('paymentMethods.expiresAt')+' ' + pm.card.exp_month + '/' + pm.card.exp_year+')',
            value: pm.id
          }
        } else if (pm.sepa_debit) {
          return {
            label: t('associateApartmentCard.sepa') + ' •••• •••• •••• ' + pm.sepa_debit.last4 + ' (' + t('paymentMethods.addedAt') +' '+ dayjs.unix(pm.created).format('DD/MM/YYYY') + ')',
            value: pm.id
          }
        }
      }
    }
    return null
  }

  function getMethodList () {
    if (!data) return []
    return data.results.map(pm => {
      console.log(pm)
      if (pm.card) {
        return {
          label: t('associateApartmentCard.card') + ' •••• •••• •••• ' + pm.card.last4 + ' ' + pm.card.exp_month + '/' + pm.card.exp_year + ' ('+t('paymentMethods.expiresAt')+' ' + pm.card.exp_month + '/' + pm.card.exp_year+')',
          value: pm.id
        }
      } else if (pm.sepa_debit) {
        return {
          label: t('associateApartmentCard.sepa') + ' •••• •••• •••• ' + pm.sepa_debit.last4,
          value: pm.id
        }
      }
      return {
        label: '',
        value: ''
      }
    })
  }

  return (
    <div>
      <PreviousPage className={'mb-6'} label={t('general.goBack')}></PreviousPage>
      
      <PageTitle
        title={t('associateApartmentCard.addPaymentMethod')}></PageTitle>

      <div className={'space-y-4'}>
        {
          locals && locals.results.map(l => (
            <div className={'flex items-center'} key={l.local_key}>
              <div className={'flex-1'}>
                {l.name}
              </div>
              <div className={'flex-1'}>
                {
                  data &&
                  <SelectInput<BaseSelectItem, false>
                    isLoading={mutatePaymentAccount.isLoading}
                    placeholder={t('associateApartmentCard.chooseSource')}
                    value={getCurrentMethod(l)}
                    options={getMethodList()}
                    onChange={item => {
                      if (item) {
                        mutatePaymentAccount.mutate({
                          local: l,
                          method: item
                        })
                      }
                    }} />
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )

}