import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import countryutils from "../../shared/countryutils";
import {
  getFormattedDate,
  getHumanDateFromISO,
} from "../../shared/utils/formatting";
import { GuestDocument, CheckinExtraFilled } from "../../types";
import AsyncImage from "../AsyncImage";
import ReservationDocumentUpdate from "./ReservationDocumentUpdate";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import { toast } from "react-toastify";
import { deleteSingleDocument, denyDocsResvKey } from "../../shared/queries";
import { useHistory } from "react-router-dom";

export default function ReservationDocument({
  document,
  reservation,
  resv_key,
  chk_documents_confirm,
  onUpdateReservation,
}: {
  reservation: any,
  document: GuestDocument;
  resv_key: string | undefined;
  chk_documents_confirm: boolean;
  onUpdateReservation?: () => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [country, setCountry] = useState<any>("...");
  const [citizenship, setCitizenship] = useState<any>("...");
  const [docCity, setDocCity] = useState<any>("...");
  const [docCountry, setDocCountry] = useState<any>("...");
  const [province, setProvince] = useState<any>("...");

  const [residenceCity, setResidenceCity] = useState<any>("...");
  const [residenceCountry, setResidenceCountry] = useState<any>("...");

  const [updateDocument, setUpdateDocument] = useState<boolean>(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] =
    useState<boolean>(false);
  const [deletionLoading, setDeletionLoading] = useState(false);

  const deletionDocumentMutation = useMutation(
    () =>
      deleteSingleDocument({ resv_key: resv_key, guest_id: document.guest_id }),
    {
      onSuccess: () => {
        setDeleteConfirmDialog(false);
        if (onUpdateReservation) onUpdateReservation();
        toast.success(t("general.operationCompleted"));
      },
      onError: () => {
        setDeleteConfirmDialog(false);
        if (onUpdateReservation) onUpdateReservation();
        toast.success(t("general.requestError"));
      },
      onMutate: () => {
        // console.log(false)
        setDeletionLoading(false);
      },
    }
  );

  const denyDocMutation = useMutation(
    (data: any) => denyDocsResvKey(data.resv_key, data.guest_id),
    {
      onSuccess: () => history.go(0),
      onError: (error: Error) => {
        toast.error(error.message || t('general.requestError'));
      },
    }
  )

  async function loadDatas() {
    const countryData = await countryutils.getCountry(document.country);
    const citizenshipData = await countryutils.getCountry(document.citizenship);
    const docCountryData = await countryutils.getCountry(document.doccountry);
    const docCityData = await countryutils.getMunicipality(
      document.doccountry,
      document.docprov,
      document.doccity
    );
    const provinceData = await countryutils.getMunicipality(
      document.country,
      document.prov,
      document.city
    );

    const residenceCountryData = await countryutils.getCountry(
      document.residencecountry
    );

    const residenceCityData = await countryutils.getMunicipality(
      document.residencecountry,
      document.residenceprov,
      document.residencecity
    );

    setCountry(countryData);
    setCitizenship(citizenshipData);
    setDocCountry(docCountryData);
    setProvince(provinceData);
    setDocCity(docCityData);

    setResidenceCountry(residenceCountryData);
    setResidenceCity(residenceCityData);
  }

  useEffect(() => {
    loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <div className={"border border-gray-300 rounded-2xl m-2 bg-white shadow"}>
      <ReservationDocumentUpdate
        visible={updateDocument}
        resv_key={resv_key}
        data={document}
        onClose={() => {
          setUpdateDocument(false);
          if (onUpdateReservation) onUpdateReservation();
        }}
      ></ReservationDocumentUpdate>
      <div className="text-lg font-medium p-4 border-b flex items-center justify-between">
        <div>
          {t("reservationDocuments.name") + document.first_name}
          <br></br>
          {t("reservationDocuments.surname") + document.last_name}
        </div>
        {!chk_documents_confirm && (
          <div>
            <button
              onClick={() => {
                setUpdateDocument(true);
              }}
              className={
                "focus:outline-none focus:ring-2 ring-cyan-600 font-medium hover:bg-cyan-100 px-2 py-1 rounded-2xl text-sm text-cyan-600"
              }
            >
              {t("general.edit")}
            </button>
            {document.guest_id !== 0 && (
              <button
                onClick={() => {
                  setDeleteConfirmDialog(true);
                }}
                className={
                  "focus:outline-none focus:ring-2 ring-red-600 font-medium hover:bg-red-100 px-2 py-1 rounded-2xl text-sm text-red-600"
                }
              >
                {t("general.delete")}
              </button>
            )}
          </div>
        )}
      </div>
      <div></div>
      <div className="p-4">
        <div className="flex mb-2 flex-wrap text-sm">
          <div className={"w-44 xs:w-full text-gray-500"}>
            {t("reservationDocuments.sex")}
          </div>
          <div className={"flex-1"}>{document.sex}</div>
        </div>

        <div className="flex mb-2 flex-wrap text-sm">
          <div className={"w-44 xs:w-full text-gray-500"}>
            {t("reservationDocuments.dateOfBirth")}
          </div>
          <div className={"flex-1"}>
            {getFormattedDate(
              document.dob,
              "YYYY-MM-DDThh:mm:ss",
              " DD-MM-YYYY"
            )}
          </div>
        </div>

        <div className="flex mb-2 flex-wrap text-sm">
          <div className={"w-44 xs:w-full text-gray-500"}>
            {t("reservationDocuments.placeOfBirth")}
          </div>
          <div className={"flex-1"}>
            {province && province.name}
            {province && ' - ' + province.province}
            {country && ' - ' + country.name} 

          </div>
        </div>

        <div className="flex mb-2 flex-wrap text-sm">
          <div className={"w-44 xs:w-full text-gray-500"}>
            {t("reservationDocuments.citizenship")}
          </div>
          <div className={"flex-1"}>{citizenship && citizenship.name}</div>
        </div>
        {document.iddoc && (
          <div>
            <div className="flex mb-2 flex-wrap text-sm">
              <div className={"w-44 xs:w-full text-gray-500"}>
                {t("reservationDocuments.documentType")}
              </div>
              <div className={"flex-1"}>
                {t("reservationDocuments.documentTypes." + document.doctype)}
              </div>
            </div>
            <div className="flex mb-2 flex-wrap text-sm">
              <div className={"w-44 xs:w-full text-gray-500"}>
                {t("reservationDocuments.documentNumber")}
              </div>
              <div className={"flex-1"}>{document.iddoc}</div>
            </div>

            <div className="flex mb-2 flex-wrap text-sm">
              <div className={"w-44 xs:w-full text-gray-500"}>
                {t("reservationDocuments.releasedBy")}
              </div>
              <div className={"flex-1"}>
                {docCity && docCity.name}
                {docCity && ' - ' + docCity.province}
                {docCountry && ' - ' + docCountry.name}

              </div>
            </div>
          </div>
        )}
        {document.docs_issue && (
          <div className="flex mb-2 flex-wrap text-sm">
            <div className={"w-44 xs:w-full text-gray-500"}>
              {t("reservationDocuments.dateOfIssue")}
            </div>
            <div className={"flex-1"}>
              {getHumanDateFromISO(document.docs_issue, false)}
            </div>
          </div>
        )}
        {document.docs_expiration && (
          <div className="flex mb-2 flex-wrap text-sm">
            <div className={"w-44 xs:w-full text-gray-500"}>
              {t("reservationDocuments.dateOfExpiration")}
            </div>
            <div className={"flex-1"}>
              {getHumanDateFromISO(document.docs_expiration, false)}
            </div>
          </div>
        )}
        {residenceCountry && (
          <div className="flex mb-2 flex-wrap text-sm">
            <div className={"w-44 xs:w-full text-gray-500"}>
              {t("reservationDocuments.residence")}
            </div>
            <div className={"flex-1"}>
              {residenceCity && residenceCity.name}
              {residenceCity &&  ' - ' +residenceCity.province}
              {residenceCountry && ' - ' + residenceCountry.name}

            </div>
          </div>
        )}
        {document.extra?.map(
          (extra: CheckinExtraFilled, index: number) =>
            extra.type !== "pic" && (
              <div className="flex mb-2 flex-wrap text-sm" key={index}>
                <div className={"w-44 xs:w-full text-gray-500"}>
                  {extra.name}
                </div>
                <div className={"flex-1"}>{extra.value}</div>
              </div>
            )
        )}
      </div>
      <div className={""}>
        <div className={"border-t w-full"}>
          <div className="p-4">
            {/* {
                    (!document.picFront && !document.picBack && !document.picSelf) ?
                    t('reservationDocuments.noDocumentReceived')
                    : */}
            <div className="-mx-2 flex">
              {document.picFront && (
                <div className={"px-2"}>
                  <AsyncImage
                    isApi={document.picSelf.indexOf("http") === -1}
                    zoomable={true}
                    rounded
                    width={"70px"}
                    height={"70px"}
                    label={"Fronte"}
                    url={"/api/v3" + document.picFront}
                    viewBackground
                  ></AsyncImage>
                </div>
              )}

              {document.picBack && (
                <div className={"px-2"}>
                  <AsyncImage
                    isApi={document.picSelf.indexOf("http") === -1}
                    zoomable={true}
                    rounded
                    width={"70px"}
                    height={"70px"}
                    label={"Retro"}
                    url={
                      document.picBack.indexOf("http") > -1
                        ? document.picBack
                        : "/api/v3" + document.picBack
                    }
                    viewBackground
                  ></AsyncImage>
                </div>
              )}

              {document.picSelf && (
                <div className={"px-2"}>
                  <AsyncImage
                    isApi={document.picSelf.indexOf("http") === -1}
                    zoomable={true}
                    rounded
                    width={"70px"}
                    height={"70px"}
                    label={"Selfie"}
                    url={
                      document.picSelf.indexOf("http") > -1
                        ? document.picSelf
                        : "/api/v3" + document.picSelf
                    }
                    viewBackground
                  ></AsyncImage>
                </div>
              )}
              {document.signature && (
                <div className={"px-2"}>
                  <AsyncImage
                    isApi={document.signature.indexOf("http") === -1}
                    zoomable={true}
                    rounded
                    width={"70px"}
                    height={"70px"}
                    label={"Signature"}
                    url={
                      document.signature.indexOf("http") > -1
                        ? document.signature
                        : "/api/v3" + document.signature
                    }
                  ></AsyncImage>
                </div>
              )}
              {document.extra?.map(
                (extra: CheckinExtraFilled, index: number) =>
                  extra.type === "pic" &&
                  extra.value && (
                    <div className={"px-2"}>
                      <AsyncImage
                        isApi={extra.value.indexOf("http") === -1}
                        zoomable={true}
                        rounded
                        width={"70px"}
                        height={"70px"}
                        label={extra.name}
                        url={
                          extra.value.indexOf("http") > -1
                            ? extra.value
                            : "/api/v3" + extra.value
                        }
                      ></AsyncImage>
                    </div>
                  )
              )}
            </div>

            {/* } */}
          </div>
        </div>
        {reservation.docs_auto_confirmed && (
          <div className="flex border-t justify-center py-3 ">
            <button
              onClick={() => {
                //post per guestId
                denyDocMutation.mutate({resv_key:resv_key, guest_id:document.guest_id})
              }}
              className={
                "focus:outline-none focus:ring-2 ring-yellow-600 font-medium hover:bg-yellow-100 px-2 py-1 rounded-2xl text-sm text-yellow-600"
              }
            >
              {t("resv.doc_refused")}
            </button>
          </div>
        )}
      </div>
      <Modal visible={deleteConfirmDialog}>
        <ModalCard title={t("reservationDocuments.deleteConfirm")}>
          <ModalSection>
            <ModalActions
              onClose={() => setDeleteConfirmDialog(false)}
              onSave={() => {
                setDeletionLoading(true);

                deletionDocumentMutation.mutate();
              }}
              saveLabel={t("general.confirm")}
              isLoading={deletionLoading}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </div>
  );
}
