import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  fetchAllModules,
  // fetchCheckins,
  fetchContracts,
  fetchCustomCheckins,
  updateReservationV3,
} from "../../shared/queries";
import { moduleId } from "../../shared/utils/config";
import { BaseSelectItem, ReservationV3 } from "../../types";
import Button from "../Button";
import Checkbox from "../Checkbox";
import FormField from "../FormField";
import FormSectionTitle from "../FormSectionTitle";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import dayjs from "dayjs";

type ReservationOnlineCheckinProps = {
  reservation: ReservationV3;
  onSave?: () => void;
};

export default function ReservationOnlineCheckin({
  reservation,
  onSave,
}: ReservationOnlineCheckinProps) {
  const { t } = useTranslation();

  const { data: contracts, isLoading: loadingContracts } = useQuery(
    "contracts",
    () => fetchContracts(reservation.local.local_key, true)
  );

  const { data: checkIns, isLoading: loadingCheckins } = useQuery(
    "checkins",
    () => fetchCustomCheckins(), //fetchCheckins(reservation.local.local_key)
    {
      onSuccess() {
        checkIns?.results?.push({
          //checkIns?.data.
          objkey: "Vikey default",
          id: 0,
          DEL: 0,
          address: "",
          billing_status: "",
          city: "",
          contact_fullname: "",
          date: "",
          email_enabled: 0,
          enable_local_integration: 0,
          enable_sepa: 0,
          invoice_company: "",
          invoice_vatnum: "",
          is_company: 0,
          lang: "",
          last_updated: "",
          lat: null,
          lng: null,
          mail: "",
          monitor_enabled: 0,
          name: "",
          notification_key_id: 0,
          ns_id: "",
          objtype: "",
          password: "",
          phone: "",
          province: "",
          request_pm: 0,
          role: "",
          short: "",
          short_complete: "",
          sms_enabled: 0,
          status: "",
          system_status: "",
          u_address: "",
          u_billing_status: "",
          u_city: "",
          u_contact_fullname: "",
          u_date: "",
          u_email_enabled: 0,
          u_enable_local_integration: 0,
          u_enable_sepa: 0,
          u_invoice_company: "",
          u_invoice_vatnum: "",
          u_is_company: 0,
          u_lang: "",
          u_last_updated: "",
          u_lat: null,
          u_lng: null,
          u_mail: "",
          u_monitor_enabled: 0,
          u_name: "",
          u_notification_key_id: -1,
          u_ns_id: "",
          u_password: "",
          u_phone: "",
          u_province: "",
          u_request_pm: 0,
          u_role: "",
          u_short: "",
          u_short_complete: "",
          u_sms_enabled: 0,
          u_status: "",
          u_system_status: "",
          u_user_key: "",
          u_verif_date: null,
          u_verified_mail: 0,
          u_verified_mail_code: null,
          u_verified_phone: 0,
          u_verified_phone_code: null,
          u_vikeypay_fee: 0,
          u_zip: "",
          u_zoho_lead_id: "",
          user_key: "",
          verif_date: null,
          verified_mail: 0,
          verified_mail_code: null,
          verified_phone: 0,
          verified_phone_code: null,
          vikeypay_fee: 0,
          zip: "",
          zoho_lead_id: "",
        });
      },
    }
  );

  const [chargeStay, setChargeStay] = useState<boolean>(
    reservation.chk_paystay
  );
  const [chargeTax, setChargeTax] = useState<boolean>(reservation.chk_citytax);
  const [requireDocuments, setRequireDocuments] = useState<boolean>(
    reservation.chk_documents
  );
  const [requireContract, setRequireContract] = useState<boolean>(
    reservation.chk_contract
  );
  const [requireInvoice, setRequireInvoice] = useState<boolean>(
    reservation.require_invoice
  );
  const [chargeStayCommission, setChargeStayCommission] = useState<boolean>(
    reservation.chk_paystay_chargeguest
  );
  const [chargeTaxCommission, setChargeTaxCommission] = useState<boolean>(
    reservation.chk_citytax_chargeguest
  );
  const [requireNumGuest, setRequireNumGuest] = useState<boolean>(
    reservation.chk_asknumguests
  );
  const [requireMainPhoto, setRequireMainPhoto] = useState<boolean>(
    reservation.chk_photo
  );
  const [requireAllPhoto, setRequireAllPhoto] = useState<boolean>(
    reservation.chk_photo_all
  );
  const [requireOtp, setRequireOtp] = useState<boolean>(reservation.chk_otp);
  const [guestsCount, setGuestsCount] = useState<number>(
    reservation.guests_num === undefined || reservation.guests_num === null
      ? 1
      : reservation.guests_num
  );
  const [customCheckin, setCustomCheckin] = useState(
    reservation.cust_checkin_ptr
  );
  const [customContract, setCustomContract] = useState(
    reservation.cust_contract_ptr
  );

  const [activeVerifyDocs, setActiveVerifyDocs] = useState(reservation.chk_docs_auto_confirmation);

  const [activeTax, setActiveTax] = useState(false);
  const [custom1, setCustom1] = useState<any>(reservation.custom1);
  const [custom2, setCustom2] = useState<any>(reservation.custom2);
  const [custom3, setCustom3] = useState<any>(reservation.custom3);
  const [custom4, setCustom4] = useState<any>(reservation.custom4);
  const [custom5, setCustom5] = useState<any>(reservation.custom5);
  const [custom6, setCustom6] = useState<any>(reservation.custom6);

  const [customSave, setCustomSave] = useState<number>(1);

  const isFirstRun = useRef<boolean>(true);

  const [activeContract, setActiveContract] = useState<any>(false);
  useEffect(() => {
    if (isFirstRun.current === true) {
      isFirstRun.current = false;
      return;
    }
    const timer = setTimeout(() => {
      mutation.mutate();
    }, 600);
    return () => clearTimeout(timer);
  }, [
    chargeStay,
    chargeTax,
    requireDocuments,
    requireContract,
    requireInvoice,
    chargeStayCommission,
    chargeTaxCommission,
    requireNumGuest,
    requireMainPhoto,
    requireAllPhoto,
    requireOtp,
    customCheckin,
    customContract,
    guestsCount,
    customSave,
    activeVerifyDocs
  ]);

  const mutation = useMutation(
    () =>
      updateReservationV3({
        resv_key: reservation.resv_key,
        email: reservation.guest_email,
        local_key: reservation.local.local_key,
        chk_paystay: chargeStay ? 1 : 0,
        chk_citytax: chargeTax ? 1 : 0,
        chk_documents: requireDocuments ? 1 : 0,
        chk_contract: requireContract ? 1 : 0,
        require_invoice: requireInvoice ? 1 : 0,
        chk_paystay_chargeguest: chargeStayCommission ? 1 : 0,
        chk_citytax_chargeguest: chargeTaxCommission ? 1 : 0,
        chk_asknumguests: requireNumGuest ? 1 : 0,
        chk_photo: requireMainPhoto ? 1 : 0,
        guests_num: isNaN(guestsCount) ? 1 : guestsCount,
        chk_photo_all: requireAllPhoto ? 1 : 0,
        chk_otp: requireOtp ? 1 : 0,
        custom1: requireContract ? custom1 : null,
        custom2: requireContract ? custom2 : null,
        custom3: requireContract ? custom3 : null,
        custom4: requireContract ? custom4 : null,
        custom5: requireContract ? custom5 : null,
        custom6: requireContract ? custom6 : null,
        cust_checkin_ptr: customCheckin,
        cust_contract_ptr: customContract,
        chk_docs_auto_confirmation: activeVerifyDocs ? 1 : 0,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onSave) onSave();
      },
    }
  );

  function currentCheckinValue() {
    if (customCheckin === 0) {
      return {
        label: "Vikey Default",
        value: 0,
      };
    } else {
      const checkIn = checkIns?.results?.find((c) => c.id === customCheckin);
      if (checkIn)
        return {
          label: checkIn.objkey,
          value: checkIn.id,
        };
    }
  }

  function currentContractValue() {
    if (customContract === 0) {
      return {
        label: t("reservationOnlineCheckIn.noContract"),
        value: 0,
      };
    } else {
      const contract = contracts?.results?.find((c) => c.id === customContract);
      if (contract)
        return {
          label: contract.objkey,
          value: contract.id,
        };
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([reservation?.local_key], () => {
    if (reservation?.local_key) {
      return fetchAllModules(reservation?.local_key).then((data: any) => {
        const moduleFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.city_tax
        );
        if (moduleFinded.active) {
          setActiveTax(true);
        } else {
          setActiveTax(false);
        }
        const moduleContractFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.contract
        );
        if (moduleContractFinded.active) {
          setActiveContract(true);
        } else {
          setActiveContract(false);
        }
        return data;
      });
    }
  });

  return (
    <div>
      <FormSectionTitle
        borderless
        title={t("reservationOnlineCheckIn.checkInProcedure")}
      ></FormSectionTitle>

      <FormField label={t("reservationOnlineCheckIn.checkInProcedure")}>
        <SelectInput<BaseSelectItem, false>
          defaultValue={{
            label: "Vikey default",
            value: 0,
          }}
          value={currentCheckinValue()}
          onChange={(val) => {
            setCustomCheckin(val?.value);
          }}
          options={
            checkIns && checkIns.results // checkIns?.data.
              ? checkIns.results.map((c) => {
                  return {
                    label: c.objkey,
                    value: c.id,
                  };
                })
              : []
          }
        />
      </FormField>

      <FormSectionTitle
        title={t("reservationOnlineCheckIn.customizeCheckInProcedure")}
      ></FormSectionTitle>

      <div className={"flex flex-col -my-2"}>
        <div className={"py-2"}>
          <Checkbox
            checked={chargeStay}
            onChange={() => setChargeStay(!chargeStay)}
            label={t("reservationOnlineCheckIn.requestStayPayment")}
            disabled={
              (!reservation.local && !chargeStay) ||
              (reservation.local == null && !chargeStay) ||
              (reservation.local.stripe_account == null && !chargeStay)
            }
            hint={t("reservationOnlineCheckIn.requestStayPaymentText")}
          ></Checkbox>

          {chargeStay && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl bg-white">
              <Checkbox
                checked={chargeStayCommission}
                onChange={() => setChargeStayCommission(!chargeStayCommission)}
                label={t("reservationOnlineCheckIn.chargeGuestCommission")}
                hint={t(
                  "reservationOnlineCheckIn.chargeGuestStayCommissionText"
                )}
              ></Checkbox>
            </div>
          )}
        </div>

        <div className={"py-2"}>
          <Checkbox
            checked={chargeTax}
            onChange={() => setChargeTax(!chargeTax)}
            label={t("reservationOnlineCheckIn.requestTaxPayment")}
            disabled={
              (chargeTax === false &&
                (!reservation.local ||
                  reservation.local == null ||
                  reservation.local.stripe_account == null ||
                  reservation.local.city_tax_params == null)) ||
              (!activeTax && !chargeTax)
            }
            hint={t("reservationOnlineCheckIn.requestTaxPaymentText")}
          ></Checkbox>

          {chargeTax && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl bg-white">
              <Checkbox
                checked={chargeTaxCommission}
                onChange={() => setChargeTaxCommission(!chargeTaxCommission)}
                label={t("reservationOnlineCheckIn.chargeGuestCommission")}
                hint={t(
                  "reservationOnlineCheckIn.chargeGuestTaxCommissionText"
                )}
              ></Checkbox>
            </div>
          )}
        </div>

        <div className={"py-2"}>
          <Checkbox
            checked={requireDocuments}
            onChange={() => setRequireDocuments(!requireDocuments)}
            label={t("reservationOnlineCheckIn.requestDocuments")}
            hint={t("reservationOnlineCheckIn.requestDocumentsText")}
          ></Checkbox>

          {requireDocuments && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl bg-white">
              <FormField className={"mb-4"} label={t("resv.guestCount")}>
                <TextInput
                  type="number"
                  numbered={true}
                  value={guestsCount}
                  onChange={(value) => {
                    const newValue = parseInt(value);
                    if(!isNaN(newValue)){
                      setGuestsCount(newValue);
                    } if(value === ''){
                      setGuestsCount(0)
                    }
                  }}
                ></TextInput>
              </FormField>

              <Checkbox
                checked={requireNumGuest}
                onChange={() => setRequireNumGuest(!requireNumGuest)}
                label={t("reservationOnlineCheckIn.requestGuestCount")}
              ></Checkbox>

              <Checkbox
                checked={requireMainPhoto}
                onChange={() => {
                  setRequireMainPhoto((prev) => {
                    if(prev){
                      setActiveVerifyDocs(false)
                      setRequireAllPhoto(false)
                    }
                    return !prev
                  })
                }}
                label={t("resv.requestDocumentMainGuest")}
              ></Checkbox>

              <Checkbox
                checked={requireAllPhoto}
                onChange={() => {
                  setRequireAllPhoto((prev) => {
                    if(!requireMainPhoto) setRequireMainPhoto(true)
                    return !prev
                  })
                }}
                label={t("resv.requestDocumentOtherGuests")}
              ></Checkbox>

            {dataModules?.modules?.map((module: any) => {
              if (module.id === moduleId.docs_auto_confirmation) {
                return (
                  <Checkbox
                    label={`${t("resv.activeRecognition")} ${
                      module.date_closed
                        ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                            module.date_closed
                          ).format("DD-MM-YYYY")})`
                        : ""
                    }`}
                    disabled={
                      !module.active || !requireMainPhoto
                    }
                    onChange={() => setActiveVerifyDocs(!activeVerifyDocs)}
                    checked={activeVerifyDocs}
                  />
                );
              }
              return null;
            })}
            </div>
          )}
        </div>

        <div className={"py-2"}>
          <Checkbox
            disabled={
              !contracts ||
              (contracts?.results &&
                contracts.results.length <= 0 &&
                !requireContract)  ||
                (!activeContract && !requireContract)
            }
            checked={requireContract}
            onChange={() => setRequireContract(!requireContract)}
            label={t("reservationOnlineCheckIn.requestContractSign")}
            hint={t("reservationOnlineCheckIn.requestContractSignText")}
          ></Checkbox>

          {requireContract && (
            <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl bg-white">
              <FormField
                label={t("reservationOnlineCheckIn.contractToBeSigned")}
              >
                <SelectInput<BaseSelectItem, false>
                  value={currentContractValue()}
                  isLoading={loadingContracts}
                  onChange={(val) => setCustomContract(val?.value)}
                  options={
                    contracts && contracts.results
                      ? contracts.results.map((c) => {
                          return {
                            label: c.objkey,
                            value: c.id,
                          };
                        })
                      : []
                  }
                />
              </FormField>

              <Checkbox
                className={"mt-4"}
                checked={requireOtp}
                onChange={() => setRequireOtp(!requireOtp)}
                label={t("reservationOnlineCheckIn.requestOTP")}
                hint={t("reservationOnlineCheckIn.requestOTPText")}
              ></Checkbox>

              {/* tag component */}
              {requireContract ? (
                <>
                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (1) ${t('apartmentContent.custom_contract_1description')} ##Custom-1##`}
                  >
                    <TextInput
                      value={custom1}
                      onChange={(value) => setCustom1(value)}
                    ></TextInput>
                  </FormField>

                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (2) ${t('apartmentContent.custom_contract_1description')} ##Custom-2##`}
                  >
                    <TextInput
                      value={custom2}
                      onChange={(value) => setCustom2(value)}
                    ></TextInput>
                  </FormField>

                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (3) ${t('apartmentContent.custom_contract_1description')} ##Custom-3##`}
                  >
                    <TextInput
                      value={custom3}
                      onChange={(value) => setCustom3(value)}
                    ></TextInput>
                  </FormField>

                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (4) ${t('apartmentContent.custom_contract_1description')} ##Custom-4##`}
                  >
                    <TextInput
                      value={custom4}
                      onChange={(value) => setCustom4(value)}
                    ></TextInput>
                  </FormField>
                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (5) ${t('apartmentContent.custom_contract_1description')} ##Custom-5##`}
                  >
                    <TextInput
                      value={custom5}
                      onChange={(value) => setCustom5(value)}
                    ></TextInput>
                  </FormField>
                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (6) ${t('apartmentContent.custom_contract_1description')} ##Custom-6##`}
                  >
                    <TextInput
                      value={custom6}
                      onChange={(value) => setCustom6(value)}
                    ></TextInput>
                  </FormField>

                  <div className="mt-5 flex justify-end">
                    <Button
                      loading={mutation.isLoading}
                      color={"primary"}
                      label={t("general.save")}
                      onClick={() => setCustomSave((prev: any) => prev + 1)}
                    ></Button>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>

        <div className={"py-2"}>
          <Checkbox
            checked={requireInvoice}
            onChange={() => setRequireInvoice(!requireInvoice)}
            label={t("reservationOnlineCheckIn.requestBillingData")}
            hint={t("reservationOnlineCheckIn.requestBillingDataText")}
          ></Checkbox>
        </div>
      </div>
    </div>
  );
}
