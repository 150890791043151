import Joi from 'joi'

export default Joi.object({
  payment_amount: Joi.alternatives().conditional(Joi.ref('chk_payments'), {
    is: 1,
    then: Joi.number().required().messages({
      'number.base': 'Importo del pagamento obbligatorio',
      'number.required': 'Importo del pagamento obbligatorio'
    }),
    otherwise: Joi.any()
  }),
  chk_citytax_chargeguest: Joi.any(),
  chk_paystay_chargeguest: Joi.any(),
  require_invoice: Joi.number(),
  payment_text: Joi.alternatives().conditional(Joi.ref('chk_payments'), {
    is: 1,
    then: Joi.string().required().messages({
      'string.base': 'Descrizione del pagamento obbligatoria',
      'string.required': 'Descrizione del pagamento obbligatoria',
      'string.empty': 'Descrizione del pagamento obbligatoria'
    }),
    otherwise: Joi.any()
  }),
  guests_num: Joi.any(),
  guest_email: Joi.alternatives().conditional(Joi.ref('chk_mail'), {
    switch: [{
      is: 1,
      then: Joi.string().required().messages({
        'string.base': 'Email ospite obbligatoria se comunicazione mail attiva',
        'string.required': 'Email ospite obbligatoria se comunicazione mail attiva',
        'string.empty': 'Email ospite obbligatoria se comunicazione mail attiva',
      })
    }],
    otherwise: Joi.any()
  }),
  guest_phone: Joi.alternatives().conditional(Joi.ref('chk_sms'), {
    is: 1,
    then: Joi.string().required().messages({
      'string.base': 'Telefono ospite obbligatorio se sms remind attivo',
      'string.required': 'Telefono ospite obbligatorio se sms remind attivo',
      'string.empty': 'Telefono ospite obbligatorio se sms remind attivo',
    }),
    otherwise: Joi.any()
  }),
  local_key: Joi.string().required().messages({
    'string.base': 'Devi scegliere un appartamento',
    'string.required': 'Devi scegliere un appartamento',
    'string.empty': 'Devi scegliere un appartamento',
  }),
  lang: Joi.string().required().messages({
    'string.base': 'Devi scegliere una lingua',
    'string.required': 'Devi scegliere una lingua',
    'string.empty': 'Devi scegliere una lingua',
  }),
  name: Joi.string().required().messages({
    'string.base': 'Nome ospite obbligatorio',
    'string.required': 'Nome ospite obbligatorio',
    'string.empty': 'Nome ospite obbligatorio',
  }),
  price: Joi.number().required(),
  date_from: Joi.string().required().messages({
    'string.base': 'Data di arrivo obbligatoria',
    'string.required': 'Data di arrivo obbligatoria',
    'string.empty': 'Data di arrivo obbligatoria',
  }),
  date_to: Joi.string().required().messages({
    'string.base': 'Data di partenza obbligatoria',
    'string.required': 'Data di partenza obbligatoria',
    'string.empty': 'Data di partenza obbligatoria',
  }),
  notify_now: Joi.any(),
  notify_7days: Joi.any(),
  notify_3days: Joi.any(),
  notify_1days: Joi.any(),
  notify_done_1days: Joi.any(),
  chk_payments: Joi.any(),
  chk_contract: Joi.any(),
  chk_citytax: Joi.any(),
  chk_paystay: Joi.any(),
  chk_otp: Joi.any(),
  chk_photo: Joi.any(),
  chk_photo_all: Joi.any(),
  cust_checkin_ptr: Joi.any(),
  cust_email_ptr: Joi.any(),
  cust_contract_ptr: Joi.any(),
  chk_asknumguests: Joi.any(),
  chk_sms: Joi.any(),
  chk_whatsapp: Joi.any(),
  chk_sms_done: Joi.any(),
  chk_whatsapp_done: Joi.any(),
  chk_documents: Joi.any(),
  chk_mail: Joi.any(),
  chk_cc: Joi.any(),
  notify_mail_guest_active: Joi.any(),
  custom1: Joi.any(),
  custom2: Joi.any(),
  custom3: Joi.any(),
  custom4: Joi.any(),
  chk_docs_auto_confirmation: Joi.any(),
  flag_cm_add_calendar: Joi.any(),
  channex_set_availability: Joi.any(),
})
