import CircleSpinner from './CircleSpinner';
import Icon from './Icon';

type ButtonProps = {
  className?: string;
  label?: string;
  icon?: string;
  iconSize?: string;
  flat?: boolean;
  disabled?: boolean;
  padding?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'negative' | 'success' | 'info';
  loading?: boolean;
  children?: any;
  onClick?: (event: React.MouseEvent) => void;
  refs?:any;
};

export default function Button({
  label,
  className,
  disabled = false,
  flat = false,
  color = 'primary',
  loading = false,
  size = 'medium',
  padding = 'md',
  icon,
  iconSize,
  children,
  onClick,
  refs
}: ButtonProps) {
  function getButtonBackgroundColor() {
    switch (color) {
      case 'primary':
        return `${
          flat
            ? 'bg-transparent active:bg-cyan-100 shadow-none'
            : 'bg-cyan-600'
        } active:shadow-none `;
      case 'negative':
        return `${
          flat
            ? 'bg-transparent active:bg-red-200 shadow-none'
            : 'bg-red-600 active:bg-red-700'
        } active:shadow-none`;
      case 'info':
        return 'hover:underline'
    }
  }

  function getButtonTextColor() {
    if (flat) {
      switch (color) {
        case 'primary':
          return 'text-cyan-600';
        case 'negative':
          return 'text-red-600';
      }
    } else {
      switch (color) {
        case 'primary':
          return 'text-white';
        case 'negative':
          return 'text-white';
      }
    }
  }

  function getLoadingColor() {
    switch (color) {
      case 'primary':
        if (flat) return 'primary';
        return 'white';
      case 'negative':
        if (flat) return 'negative';
        return 'white';
    }
  }

  function getLabelSize() {
    switch (size) {
      case 'small':
        return 'text-sm';
      case 'medium':
        return 'text-md';
    }
  }

  function getButtonPadding() {
    switch (padding) {
      case 'xs':
        return 'px-3';
      case 'sm':
        return 'px-3 py-1';
      case 'md':
        return 'px-4 py-2';
      case 'lg':
        return 'px-4 py-2';
      case 'xl':
        return 'px-4 py-2';
      default:
        return 'px-4 py-2';
    }
  }

  return (
    <button
      ref={refs}
      onClick={(e) => {
        if (!disabled && onClick) onClick(e);
      }}
      type="button"
      className={`${
        disabled && 'opacity-30'
      } hover:opacity-90 whitespace-nowrap relative font-medium active:opacity-100 active:brightness-50 ${getButtonPadding()} rounded-2xl ${getButtonBackgroundColor()} ${getButtonTextColor()} ${className ?? ''}`}
    >
      <div className={'flex items-center'}>
        {loading && (
          <div className={''}>
            <CircleSpinner color={getLoadingColor()}></CircleSpinner>
          </div>
        )}
        {icon && !loading && (
          <Icon
            className={`${label ? 'mr-2' : ''}`}
            name={icon}
            size={iconSize || '20px'}
          ></Icon>
        )}
        {children && children}
        {label && <div className={`${getLabelSize()}`}>{label}</div>}
      </div>
    </button>
  );
}
