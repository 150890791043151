import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  fetchAllModules,
  fetchApartmentSettings,
  fetchContracts,
  fetchCustomCheckins,
  fetchCustomEmails,
  fetchSettingsLocalChannex,
  fetchV3AllLocals,
  putSettingsLocalChannex,
} from "../../shared/queries";
import { useTranslation } from "react-i18next";
import FormField from "../FormField";
import SelectInput from "../SelectInput";
import { BaseSelectItem, LocalTable } from "../../types";
import { getOptionList, times } from "../../shared/utils/formatting";
import { moduleId } from "../../shared/utils/config";
import Checkbox from "../Checkbox";
import dayjs from "dayjs";
import PaystaySelector from "../integration/channel-selector/PaystaySelector";
import CitytaxSelector from "../integration/channel-selector/CitytaxSelector";
import DocumentSelector from "../integration/channel-selector/DocumentSelector";
import { toast } from "react-toastify";
import Button from "../Button";
import Icon from "../Icon";

type Props = {
  local_key: string;
  rate_plan_id?: any;
  onGoBack?: () => void;
  onSuccessMutation?: () => void;
  refetchLocalList?: () => void;
};

const EditRoomSettings = (props: Props) => {
  const {
    local_key,
    rate_plan_id,
    onGoBack,
    onSuccessMutation,
    refetchLocalList,
  } = props;
  const { t } = useTranslation();
  const [integration, setIntegration] = useState<any>();
  const [activeContract, setActiveContract] = useState<any>(false);
  const { data: dataModules } = useQuery<any>(
    ["getmodules", local_key],
    () =>
      fetchAllModules(local_key).then((data: any) => {
        const moduleFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.contract
        );
        if (moduleFinded && moduleFinded.active) {
          setActiveContract(true);
        } else {
          setActiveContract(false);
        }
        return data;
      }),
    {
      enabled: local_key !== null,
    }
  );
  const { refetch, isLoading: isLoadingSetting } = useQuery(
    ["settingsChannex", rate_plan_id],
    () => fetchSettingsLocalChannex(rate_plan_id),
    {
      enabled:
        (rate_plan_id !== null || rate_plan_id !== undefined) && !!onGoBack,
      onSuccess: (data) => {
        setIntegration({
          ...data.results,
          integr_chk_paystay_filterchannel: data.results
            .integr_chk_paystay_filterchannel
            ? JSON.parse(data.results.integr_chk_paystay_filterchannel)
            : null,
          integr_chk_citytax_filterchannel: data.results
            .integr_chk_citytax_filterchannel
            ? JSON.parse(data.results.integr_chk_citytax_filterchannel)
            : null,
          integr_chk_asknumguests_filterchannel: data.results
            .integr_chk_asknumguests_filterchannel
            ? JSON.parse(data.results.integr_chk_asknumguests_filterchannel)
            : null,
          integr_avoid_add_filterchannel: data.results
            .integr_avoid_add_filterchannel
            ? JSON.parse(data.results.integr_avoid_add_filterchannel)
            : null,
        });
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: settingsDef } = useQuery<any>(
    ["settingsDef", local_key],
    () => fetchApartmentSettings(local_key as string),
    {
      enabled: local_key !== null && !onGoBack,
      onSuccess: (data) => {
        const result = data.result;
        setIntegration((prev: any) => {
          return {
            ...prev,
            integr_time_checkin: result.integr_time_checkin,
            integr_time_checkout: result.integr_time_checkout,
            integr_chk_asknumguests: result.chk_asknumguests,
            integr_chk_contract: result.chk_contract,
            integr_chk_documents: result.chk_documents,
            integr_chk_invoice: result.require_invoice,
            integr_chk_mail: result.chk_mail,
            integr_notify_mail_guest_active: result.notify_mail_guest_active,
            integr_chk_sms: result.chk_sms,
            integr_chk_sms_done: result.chk_sms_done,
            integr_chk_whatsapp: result.chk_whatsapp,
            integr_chk_whatsapp_done: result.chk_whatsapp_done,
            integr_chk_otp: result.chk_otp,
            integr_chk_photo: result.chk_photo,
            integr_chk_photo_all: result.chk_photo_all,
            integr_chk_cc: result.chk_cc,
            integr_chk_citytax: result.chk_citytax,
            integr_chk_paystay: result.chk_paystay,
            integr_cust_contract_ptr: result.cust_contract_ptr ?? 0,
            integr_cust_checkin_ptr: result.cust_checkin_ptr ?? 0,
            integr_cust_email_ptr: result.cust_email_ptr ?? 0,
            integr_notify_now: result.notify_now,
            integr_notify_7days: result.notify_7days,
            integr_notify_3days: result.notify_3days,
            integr_notify_1days: result.notify_1days,
            integr_notify_done_1days: result.notify_done_1days,
            integr_chk_docs_auto_confirmation: result.integr_chk_docs_auto_confirmation,
            integr_chk_citytax_chargeguest: result.chk_citytax_chargeguest,
            integr_chk_paystay_chargeguest: result.chk_paystay_chargeguest,
          };
        });
      },
    }
  );

  const { data: checkInTemplates, isFetching: fetchingCheckInTemplates } =
    useQuery(["checkInTemplates", local_key], () => fetchCustomCheckins(), {
      enabled: local_key !== null,
    });

  const { data: emailTemplates, isFetching: fetchingEmailTemplates } = useQuery(
    ["emailTemplates", local_key],
    () => fetchCustomEmails(local_key ? local_key : undefined, true),
    {
      enabled: local_key !== null,
    }
  );

  const { data: contracts, isFetching: fetchingContracts } = useQuery(
    ["contracts", local_key],
    () => fetchContracts(local_key ? local_key : undefined, true),
    {
      enabled: local_key !== null && local_key !== undefined,
    }
  );
  const { data: apartmentDetail } = useQuery(
    ["apartmentDetail", local_key],
    () =>
      fetchV3AllLocals().then((data) =>
        data.results?.find((local) => local.local_key === local_key)
      ),
    {
      enabled: local_key !== null && local_key !== undefined,
    }
  );

  const editRoomVikeySettings = useMutation(
    (data: any) =>
      putSettingsLocalChannex({ channex_rate_plan_id: rate_plan_id, ...data }),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetch();
        if (refetchLocalList) refetchLocalList();
        if (onGoBack) onGoBack();
        if (onSuccessMutation) onSuccessMutation();
      },
    }
  );

  useEffect(() => {
    checkInTemplates?.results?.push({ objkey: "Vikey Default", id: 0 });
  }, [checkInTemplates]);

  useEffect(() => {
    emailTemplates?.results?.push({ objkey: "Vikey Default", id: 0 });
  }, [emailTemplates]);
  return (
    <div className="text-sm">
      {onGoBack && (
        <div className="mb-5">
          <Icon
            name="arrow-narrow-left"
            size="18px"
            onClick={() => {
              onGoBack();
            }}
            className="cursor-pointer"
          />
          {t("general.goBack")}
        </div>
      )}
      <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
        <div className={"flex-1"}>
          <FormField label={t("resv.checkInTime")}>
            <SelectInput<BaseSelectItem, false>
              isDisabled={isLoadingSetting}
              isLoading={isLoadingSetting}
              menuShouldScrollIntoView={false}
              menuPortalTarget={document.body}
              styles={{
                container: (base) => ({ ...base, zIndex: 999 }),
                menuPortal: (base) => ({ ...base, zIndex: 999 }),
              }}
              value={{
                label: integration?.integr_time_checkin
                  ? integration?.integr_time_checkin.slice(0, 5)
                  : "15:00",
                value: integration?.integr_time_checkin
                  ? integration?.integr_time_checkin.slice(0, 5)
                  : "15:00",
              }}
              onChange={(item) => {
                setIntegration((prev: any) => {
                  return {
                    ...prev,
                    integr_time_checkin: item ? item.value : "15:00",
                  };
                });
              }}
              options={times}
            />
          </FormField>
        </div>

        <div className={"flex-1"}>
          <FormField label={t("resv.checkOutTime")}>
            <SelectInput<BaseSelectItem, false>
              isDisabled={isLoadingSetting}
              isLoading={isLoadingSetting}
              menuShouldScrollIntoView={false}
              menuPortalTarget={document.body}
              styles={{
                container: (base) => ({ ...base, zIndex: 999 }),
                menuPortal: (base) => ({ ...base, zIndex: 999 }),
              }}
              value={{
                label: integration?.integr_time_checkout
                  ? integration?.integr_time_checkout.slice(0, 5)
                  : "10:00",
                value: integration?.integr_time_checkout
                  ? integration?.integr_time_checkout.slice(0, 5)
                  : "10:00",
              }}
              onChange={(item) => {
                setIntegration((prev: any) => {
                  return {
                    ...prev,
                    integr_time_checkout: item ? item.value : "10:00",
                  };
                });
              }}
              options={times}
            />
          </FormField>
        </div>
      </div>

      <FormField label={t("resv.onlineCheckIn")} className="mt-4">
        <SelectInput<BaseSelectItem, false>
          isDisabled={isLoadingSetting}
          isLoading={isLoadingSetting || fetchingCheckInTemplates}
          styles={{
            container: (base) => ({ ...base, zIndex: 999 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          menuShouldScrollIntoView={false}
          menuPortalTarget={document.body}
          value={getOptionList(checkInTemplates?.results, "objkey", "id").find(
            (item) => item.value === integration?.integr_cust_checkin_ptr
          )}
          onChange={(item) => {
            if (item) {
              setIntegration((prev: any) => {
                return {
                  ...prev,
                  integr_cust_checkin_ptr: item.value,
                };
              });
            }
          }}
          options={getOptionList(checkInTemplates?.results, "objkey", "id")}
        />
      </FormField>

      <FormField label={t("reservations.color")} className="mt-4">
        <div className="flex space-x-2">
          {[
            { label: "White", color: "bg-gray-200", value: 1 },
            { label: "Green", color: "bg-green-600", value: 2 },
            { label: "Blue", color: "bg-blue-600", value: 3 },
            { label: "Yellow", color: "bg-yellow-300", value: 4 },
            { label: "Orange", color: "bg-yellow-600", value: 5 },
            { label: "Red", color: "bg-red-600", value: 6 },
          ].map((color) => (
            <button
              key={color.value}
              onClick={() => {
                setIntegration((prev: any) => {
                  return {
                    ...prev,
                    integr_mark: color.value,
                  };
                });
              }}
              className={`transition-all ${color.color} w-5 h-5 rounded-2xl ${
                integration?.integr_mark !== color.value && `opacity-40`
              } ${
                integration?.integr_mark === color.value &&
                `ring-4 ring-${color.color}-200`
              }`}
            ></button>
          ))}
        </div>
      </FormField>

      <div className="font-medium text-lg pt-4">Features</div>

      <div>
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.sms) {
            return (
              <Checkbox
                label={`${t("resv.sendRemindSMS")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active || isLoadingSetting}
                loading={isLoadingSetting}
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_chk_sms: !integration?.integr_chk_sms,
                    };
                  });
                }}
                checked={
                  integration?.integr_chk_sms === 0
                    ? false
                    : integration?.integr_chk_sms
                }
              />
            );
          }
          return null;
        })}

        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.whatsapp) {
            return (
              <Checkbox
                label={`${t("resv.sendRemindWHATSAPP")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active || isLoadingSetting}
                loading={isLoadingSetting}
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_chk_whatsapp: !integration?.integr_chk_whatsapp,
                    };
                  });
                }}
                checked={
                  integration?.integr_chk_whatsapp === 0
                    ? false
                    : integration?.integr_chk_whatsapp
                }
              />
            );
          }
          return null;
        })}
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.sms_done) {
            return (
              <Checkbox
                label={`${t("resv.sendRemindSMSdone")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active || isLoadingSetting}
                loading={isLoadingSetting}
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_chk_sms_done: !integration?.integr_chk_sms_done,
                    };
                  });
                }}
                checked={
                  integration?.integr_chk_sms_done === 0
                    ? false
                    : integration?.integr_chk_sms_done
                }
              />
            );
          }
          return null;
        })}
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.whatsapp_done) {
            return (
              <Checkbox
                label={`${t("resv.sendRemindWHATSAPPdone")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active || isLoadingSetting}
                loading={isLoadingSetting}
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_chk_whatsapp_done: !prev?.integr_chk_whatsapp_done,
                    };
                  });
                }}
                checked={
                  integration?.integr_chk_whatsapp_done === 0
                    ? false
                    : integration?.integr_chk_whatsapp_done
                }
              />
            );
          }
          return null;
        })}

        <Checkbox
          label={t("resv.enableEmail")}
          onChange={() => {
            setIntegration((prev: any) => {
              return {
                ...prev,
                integr_chk_mail: !prev?.integr_chk_mail,
              };
            });
          }}
          checked={
            integration?.integr_chk_mail === 0
              ? false
              : integration?.integr_chk_mail
          }
          disabled={isLoadingSetting}
          loading={isLoadingSetting}
        />

        {integration?.integr_chk_mail && (
          <div className="bg-gray-100 border rounded-2xl p-4 mb-2">
            <SelectInput<BaseSelectItem, false>
              styles={{
                container: (base) => ({ ...base, zIndex: 999 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuShouldScrollIntoView={false}
              menuPortalTarget={document.body}
              isDisabled={isLoadingSetting}
              isLoading={fetchingEmailTemplates || isLoadingSetting}
              value={getOptionList(
                emailTemplates?.results,
                "objkey",
                "id"
              ).find(
                (item) => item.value === integration?.integr_cust_email_ptr
              )}
              onChange={(item) => {
                if (item) {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_cust_email_ptr: item.value,
                    };
                  });
                }
              }}
              options={getOptionList(emailTemplates?.results, "objkey", "id")}
            />

            <div className="mt-4">
              <Checkbox
                label={t("resv.enableCCEmail")}
                checked={
                  integration?.integr_chk_cc === 0
                    ? false
                    : integration?.integr_chk_cc
                }
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_chk_cc: !prev.integr_chk_cc,
                    };
                  });
                }}
                disabled={isLoadingSetting}
                loading={isLoadingSetting}
              />

              <Checkbox
                label={t("resv.createNotification")}
                checked={
                  integration?.integr_notify_now === 0
                    ? false
                    : integration?.integr_notify_now
                }
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_notify_now: !prev.integr_notify_now,
                    };
                  });
                }}
                disabled={isLoadingSetting}
                loading={isLoadingSetting}
              />

              <Checkbox
                label={t("resv.sevenDayNotification")}
                checked={
                  integration?.integr_notify_7days === 0
                    ? false
                    : integration?.integr_notify_7days
                }
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_notify_7days: !prev?.integr_notify_7days,
                    };
                  });
                }}
                disabled={isLoadingSetting}
                loading={isLoadingSetting}
              />

              <Checkbox
                label={t("resv.threeDayNotification")}
                checked={
                  integration?.integr_notify_3days === 0
                    ? false
                    : integration?.integr_notify_3days
                }
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_notify_3days: !prev?.integr_notify_3days,
                    };
                  });
                }}
                disabled={isLoadingSetting}
                loading={isLoadingSetting}
              />

              <Checkbox
                label={t("resv.oneDayNotification")}
                checked={
                  integration?.integr_notify_1days === 0
                    ? false
                    : integration?.integr_notify_1days
                }
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_notify_1days: !prev?.integr_notify_1days,
                    };
                  });
                }}
                disabled={isLoadingSetting}
                loading={isLoadingSetting}
              />

              <Checkbox
                label={t("resv.completeNotification")}
                checked={
                  integration?.integr_notify_done_1days === 0
                    ? false
                    : integration?.integr_notify_done_1days
                }
                onChange={() => {
                  setIntegration((prev: any) => {
                    return {
                      ...prev,
                      integr_notify_done_1days: !prev?.integr_notify_done_1days,
                    };
                  });
                }}
                disabled={isLoadingSetting}
                loading={isLoadingSetting}
              />
            </div>
          </div>
        )}

        <Checkbox
          label={t("resv.enableGuestActive")}
          onChange={() => {
            setIntegration((prev: any) => {
              return {
                ...prev,
                integr_notify_mail_guest_active:
                  !prev?.integr_notify_mail_guest_active,
              };
            });
          }}
          checked={
            integration?.integr_notify_mail_guest_active === 0
              ? false
              : integration?.integr_notify_mail_guest_active
          }
          disabled={isLoadingSetting}
          loading={isLoadingSetting}
        />

        <PaystaySelector
          local={apartmentDetail as LocalTable}
          integration={integration}
          setIntegration={setIntegration}
        />

        <CitytaxSelector
          local={apartmentDetail as LocalTable}
          integration={integration}
          setIntegration={setIntegration}
        />

        <DocumentSelector
          local={apartmentDetail as LocalTable}
          integration={integration}
          setIntegration={setIntegration}
        />

        <Checkbox
          label={t("resv.mandatoryContractSign")}
          disabled={
            !contracts ||
            (contracts?.results &&
              contracts.results.length <= 0 &&
              !integration?.integr_chk_contract) ||
            (!activeContract && !integration?.integr_chk_contract)
          }
          loading={isLoadingSetting}
          onChange={() => {
            setIntegration((prev: any) => {
              return {
                ...prev,
                integr_chk_contract: !prev?.integr_chk_contract,
              };
            });
          }}
          checked={
            integration?.integr_chk_contract === 0
              ? false
              : integration?.integr_chk_contract
          }
        />

        {integration?.integr_chk_contract && (
          <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
            <FormField label={t("resv.contract")}>
              <SelectInput<BaseSelectItem, false>
                menuShouldScrollIntoView={false}
                menuPortalTarget={document.body}
                styles={{
                  container: (base) => ({ ...base, zIndex: 999 }),
                  menuPortal: (base) => ({ ...base, zIndex: 999 }),
                }}
                isDisabled={isLoadingSetting}
                isLoading={fetchingContracts || isLoadingSetting}
                value={getOptionList(contracts?.results, "objkey", "id").find(
                  (item) => item.value === integration?.integr_cust_contract_ptr
                )}
                options={getOptionList(contracts?.results, "objkey", "id")}
                onChange={(item) => {
                  if (item) {
                    setIntegration((prev: any) => {
                      return {
                        ...prev,
                        integr_cust_contract_ptr: item.value,
                      };
                    });
                  }
                }}
              />
            </FormField>

            <Checkbox
              className={"mt-4"}
              label={t("resv.requestOTP")}
              onChange={() => {
                setIntegration((prev: any) => {
                  return {
                    ...prev,
                    integr_chk_otp: !integration?.integr_chk_otp,
                  };
                });
              }}
              checked={
                integration?.integr_chk_otp === 0
                  ? false
                  : integration?.integr_chk_otp
              }
              disabled={isLoadingSetting}
              loading={isLoadingSetting}
            />
          </div>
        )}

        <Checkbox
          label={t("resv.requestInvoice")}
          onChange={() => {
            setIntegration((prev: any) => {
              return {
                ...prev,
                integr_chk_invoice: !prev?.integr_chk_invoice,
              };
            });
          }}
          checked={
            integration?.integr_chk_invoice === 0
              ? false
              : integration?.integr_chk_invoice
          }
          disabled={isLoadingSetting}
          loading={isLoadingSetting}
        />
        <div className="bg-gray-50 border rounded-2xl p-4 pb-2 mb-2">
          <FormField label={t("integration.avoidAdd")}>
            <Checkbox
              label={"Airbnb"}
              onChange={() => {
                setIntegration((prev: any) => {
                  return {
                    ...prev,
                    integr_avoid_add_filterchannel: {
                      EXPEDIA: integration.integr_avoid_add_filterchannel?.EXPEDIA ??
                        false,
                      BOOKING: integration.integr_avoid_add_filterchannel?.BOOKING ??
                        false,
                      OTHER: integration.integr_avoid_add_filterchannel?.OTHER ??
                        false,
                      AIRBNB: !prev?.integr_avoid_add_filterchannel?.AIRBNB,
                    },
                  };
                });
              }}
              checked={
                integration?.integr_avoid_add_filterchannel?.AIRBNB === 0
                  ? false
                  : integration?.integr_avoid_add_filterchannel?.AIRBNB
              }
              disabled={isLoadingSetting}
              loading={isLoadingSetting}
            />
          </FormField>
        </div>
      </div>
      <div className="text-center">
        <Button
          padding={"md"}
          size={"small"}
          label={t("general.save")}
          onClick={() => {
            const dataToSend = {
              ...integration,
              integr_chk_citytax_chargeguest: integration.integr_chk_citytax
                ? integration.integr_chk_citytax_chargeguest
                : false,
              integr_chk_citytax_filterchannel: integration.integr_chk_citytax
                ? JSON.stringify({
                    EXPEDIA:
                      integration.integr_chk_citytax_filterchannel?.EXPEDIA ??
                      false,
                    BOOKING:
                      integration.integr_chk_citytax_filterchannel?.BOOKING ??
                      false,
                    AIRBNB:
                      integration.integr_chk_citytax_filterchannel?.AIRBNB ??
                      false,
                    OTHER:
                      integration.integr_chk_citytax_filterchannel?.OTHER ??
                      false,
                  })
                : JSON.stringify({
                    EXPEDIA: false,
                    BOOKING: false,
                    AIRBNB: false,
                    OTHER: false,
                  }),
              integr_chk_paystay_chargeguest: integration.integr_chk_paystay
                ? integration.integr_chk_paystay_chargeguest
                : false,
              integr_chk_paystay_filterchannel: integration.integr_chk_paystay
                ? JSON.stringify({
                    EXPEDIA:
                      integration.integr_chk_paystay_filterchannel?.EXPEDIA ??
                      false,
                    BOOKING:
                      integration.integr_chk_paystay_filterchannel?.BOOKING ??
                      false,
                    AIRBNB:
                      integration.integr_chk_paystay_filterchannel?.AIRBNB ??
                      false,
                    OTHER:
                      integration.integr_chk_paystay_filterchannel?.OTHER ??
                      false,
                  })
                : JSON.stringify({
                    EXPEDIA: false,
                    BOOKING: false,
                    AIRBNB: false,
                    OTHER: false,
                  }),
              integr_chk_photo: integration.integr_chk_documents
                ? integration.integr_chk_photo
                : false,
              integr_chk_docs_auto_confirmation:
                integration.integr_chk_documents
                  ? integration.integr_chk_docs_auto_confirmation
                  : false,
              integr_chk_photo_all: integration.integr_chk_documents
                ? integration.integr_chk_photo_all
                : false,
              integr_chk_asknumguests: integration.integr_chk_documents
                ? integration.integr_chk_asknumguests
                : false,
              integr_chk_asknumguests_filterchannel:
                integration.integr_chk_documents
                  ? JSON.stringify({
                      EXPEDIA:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.EXPEDIA ?? false,
                      BOOKING:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.BOOKING ?? false,
                      AIRBNB:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.AIRBNB ?? false,
                      OTHER:
                        integration.integr_chk_asknumguests_filterchannel
                          ?.OTHER ?? false,
                    })
                  : JSON.stringify({
                      EXPEDIA: false,
                      BOOKING: false,
                      AIRBNB: false,
                      OTHER: false,
                    }),
              integr_avoid_add_filterchannel:
                integration.integr_avoid_add_filterchannel
                  ? JSON.stringify({
                      AIRBNB:
                        integration.integr_avoid_add_filterchannel?.AIRBNB ??
                        false,
                      EXPEDIA: integration.integr_avoid_add_filterchannel?.EXPEDIA ??
                        false,
                      BOOKING: integration.integr_avoid_add_filterchannel?.BOOKING ??
                        false,
                      OTHER: integration.integr_avoid_add_filterchannel?.OTHER ??
                        false,
                    })
                  : JSON.stringify({
                      EXPEDIA: false,
                      BOOKING: false,
                      OTHER: false,
                      AIRBNB: false,
                    }),
              integr_chk_otp: integration.integr_chk_contract
                ? integration.integr_chk_otp
                : false,
              integr_cust_contract_ptr: integration.integr_chk_contract
                ? integration.integr_cust_contract_ptr
                : 0,
              integr_cust_email_ptr: integration.integr_chk_mail
                ? integration.integr_cust_email_ptr
                : 0,
              integr_chk_cc: integration.integr_chk_mail
                ? integration.integr_chk_cc
                : 0,
              integr_notify_now: integration.integr_chk_mail
                ? integration.integr_notify_now
                : 0,
              integr_notify_7days: integration.integr_chk_mail
                ? integration.integr_notify_7days
                : 0,
              integr_notify_3days: integration.integr_chk_mail
                ? integration.integr_notify_3days
                : 0,
              integr_notify_1days: integration.integr_chk_mail
                ? integration.integr_notify_1days
                : 0,
              integr_notify_done_1days: integration.integr_chk_mail
                ? integration.integr_notify_done_1days
                : 0,
              integr_time_checkin: integration.integr_time_checkin
                ? integration.integr_time_checkin
                : "15:00",
              integr_time_checkout: integration.integr_time_checkout
                ? integration.integr_time_checkout
                : "10:00",
            };
            delete dataToSend.date_creation;
            delete dataToSend.date_updated;
            editRoomVikeySettings.mutate(dataToSend);
          }}
          disabled={editRoomVikeySettings.isLoading}
          loading={editRoomVikeySettings.isLoading}
        />
      </div>
    </div>
  );
};

export default EditRoomSettings;
