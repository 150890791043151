import { useEffect, useState } from 'react';
import { BaseSelectItem, CheckinExtra, CheckinLang } from '../../types';
import Checkbox from '../Checkbox';
import FormField from '../FormField';
import Icon from '../Icon';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import ModalSection from '../ModalSection';
import SelectInput from '../SelectInput';
import TextInput from '../TextInput';
import { useTranslation } from 'react-i18next';


type ExtraFieldManagerProps = {
  lang: CheckinLang;
  section: 'generic' | 'invoice' | 'payments' | 'contract' | 'documents';
  onChange: (extras: CheckinExtra[]) => void;
};
type FieldType = 'text' | 'select' | 'pic';



export default function ExtraFieldManager({
  lang,
  section,
  onChange,
}: ExtraFieldManagerProps) {
  const [extras, setExtras] = useState<CheckinExtra[]>([]);
  const [currentExtra, setCurrentExtra] = useState<CheckinExtra | undefined>(
    undefined
  );
  const [oldExtra, setOldExtra] = useState<any>(
    undefined
  );

  const { t } = useTranslation();

  const types = [
    { label: t('extraCustomCheckIn.textType'), value: 'text' },
    { label: t('extraCustomCheckIn.listType'), value: 'select' },
    { label: t('extraCustomCheckIn.imageType'), value: 'pic' },
  ];

  useEffect(() => {
    if (lang && lang.extra && lang.extra !== "{}") setExtras(JSON.parse(lang.extra));
  }, []);

  useEffect(() => {
    onChange(extras);
  }, [extras]);

  function addNewExtra() {
    setExtras([
      ...extras,
      {
        isRequired: false,
        name: t('extraCustomCheckIn.newField'),
        pos: section,
        type: 'text',
        selvals: [],
      },
    ]);
  }

  return (
    <div>
      <div className="bg-white border rounded-2xl">
        <div className="px-4 py-3">
          <div className="text-lg font-medium">{t('extraCustomCheckIn.extraFields')}</div>
          <div className="text-sm text-gray-600">
            {t('extraCustomCheckIn.extraFieldsDesc')}
          </div>
        </div>
        {extras
          .filter((e) => e.pos === section)
          .map((extra, index) => (
            <div
              key={index}
              className={
                'border-t p-4 justify-between items-center hover:text-cyan-600 flex hover:bg-gray-100 cursor-pointer'
              }
            >
              <div>
                <div className="font-medium">{extra.name}</div>
                <div className={'text-sm'}>
                  {extra.type === 'text' && t('extraCustomCheckIn.textType')}
                  {extra.type === 'select' && t('extraCustomCheckIn.listType')}
                  {extra.type === 'pic' && t('extraCustomCheckIn.imageType')}
                </div>
              </div>
              <div className={'flex space-x-4 items-center'}>
                <Icon
                  onClick={() => {
                    setCurrentExtra(extra);
                    setOldExtra(extra);
                  }}
                  name={'pencil'}
                  size={'20px'}
                />
                <Icon
                  onClick={() => {
                    const filtered: CheckinExtra[] = extras.filter((_e, i, a) => a.findIndex((ext) => ext.name === extra.name && ext.pos === section) !== i)
                    setExtras(
                      [...filtered]
                    )
                  }}
                  className={'text-red-600'}
                  name={'trash'}
                  size={'20px'}
                />
              </div>
            </div>
          ))}
        <div
          onClick={() => {
            addNewExtra();
          }}
          className="border-t hover:bg-gray-100 hover:text-cyan-600 cursor-pointer text-center font-medium px-4 py-3"
        >
         {t('extraCustomCheckIn.addField')}
        </div>
      </div>

      <Modal visible={currentExtra !== undefined}>
        {currentExtra && (
          <ModalCard
            style={{
              width: '100%',
              maxWidth: '600px',
            }}
            title={currentExtra.name}
          >
            <ModalSection className={'p-4'}>
              <div className="space-y-6">
                <FormField label={'Nome'}>
                  <TextInput
                    value={currentExtra.name}
                    onChange={(val) =>
                      setCurrentExtra({
                        ...currentExtra,
                        name: val,
                      })
                    }
                  ></TextInput>
                </FormField>

                <FormField label={t('extraCustomCheckIn.fieldType')}>
                  <SelectInput<BaseSelectItem, false>
                    menuShouldScrollIntoView={false}
                    menuPortalTarget={document.body}
                    value={types.find((t) => t.value === currentExtra.type)}
                    styles={{
                      container: (base) => ({ ...base, zIndex: 999 }),
                      menuPortal: (base) => ({ ...base, zIndex: 999 }),
                    }}
                    options={types}
                    onChange={(item) => {
                      if (item) {
                        setCurrentExtra({
                          ...currentExtra,
                          type: item.value as FieldType,
                        });
                      }
                    }}
                  />
                </FormField>

                {currentExtra.type === 'select' && (
                  <div>
                    {currentExtra.selvals.map((val, index) => (
                      <TextInput
                        key={index}
                        placeholder={t('extraCustomCheckIn.itemText')}
                        className={`${
                          index > 0 &&
                          index < currentExtra.selvals.length - 1 &&
                          'rounded-t-none rounded-b-none border-b-0'
                        } ${
                          index === 0 &&
                          'rounded-bl-none rounded-br-none border-b-0'
                        } ${
                          index === currentExtra.selvals.length - 1 &&
                          'rounded-tl-none rounded-tr-none'
                        }`}
                        value={val}
                        onChange={(val) => {
                          setCurrentExtra({
                            ...currentExtra,
                            selvals: currentExtra.selvals.map(
                              (selVal, sIndex) => {
                                if (index === sIndex) return val;
                                return selVal;
                              }
                            ),
                          });
                        }}
                        renderButton={() => (
                          <div
                            className="pr-2 text-red-600"
                            onClick={() => {
                              setCurrentExtra({
                                ...currentExtra,
                                selvals: currentExtra.selvals.filter(
                                  (sv, sIndex) => {
                                    if (index !== sIndex) return sv;
                                  }
                                ),
                              });
                            }}
                          >
                            <Icon name={'trash'} size={'20px'}></Icon>
                          </div>
                        )}
                      ></TextInput>
                    ))}

                    <div className="items-center space-x-2 mt-3 block text-cyan-600 flex">
                      <div>
                        <Icon name={'plus'} size={'20px'}></Icon>
                      </div>
                      <div>
                        <a
                          href="#"
                          className={'font-medium'}
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentExtra({
                              ...currentExtra,
                              selvals: [...currentExtra.selvals, ''],
                            });
                          }}
                        >
                          {t('extraCustomCheckIn.addItem')}
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                <Checkbox
                  checked={currentExtra.isRequired}
                  onChange={() =>
                    setCurrentExtra({
                      ...currentExtra,
                      isRequired: !currentExtra.isRequired,
                    })
                  }
                  label={t('extraCustomCheckIn.mandatory')}
                />
              </div>
            </ModalSection>
            <ModalActions
              isLoading={false}
              saveLabel={t('extraCustomCheckIn.saveItem')}
              onClose={() => {
                setCurrentExtra(undefined);
                setOldExtra(undefined)
              }}
              onSave={() => {
                const filtered = extras.map((e, i, a) => {
                  if(a.findIndex((ext) => ext.name === oldExtra.name && ext.pos === section) === i){
                    return currentExtra
                  }
                  return e
                })
                setExtras(filtered);
                setCurrentExtra(undefined);
                setOldExtra(undefined)
              }}
            ></ModalActions>
          </ModalCard>
        )}
      </Modal>
    </div>
  );
}
