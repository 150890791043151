import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  getFormattedDate,
} from '../../shared/utils/formatting';
import { LocalTable, ReservationV3 } from '../../types';
import DetailRow from '../DetailRow';
import FormSectionTitle from '../FormSectionTitle';
import UpdateReservationDetails from './UpdateReservationDetails';
import { fetchV3AllLocals } from '../../shared/queries';

type ReservationGeneralInfoProps = {
  reservation: ReservationV3;
  loading: boolean;
  onSave: () => void;
};

export default function ReservationGeneralInfo({
  reservation,
  loading,
  onSave,
}: ReservationGeneralInfoProps) {
  const { t } = useTranslation();

  const [updateDetails, setUpdateDetails] = useState<boolean>(false);

  const [apt, setApt] = useState<LocalTable | null>()
  const {
    data: locals,
  } = useQuery('localsv3', fetchV3AllLocals)


  function defaultApartment() {
    if (reservation && locals && locals.results) {
      const currentApartment = locals.results.find(
        (a) => a.local_key === reservation.local.local_key
      );
      if (currentApartment) {
        setApt(currentApartment)
      }
    }
  }
  
  useEffect(() => {
    defaultApartment()
  
    return () => {
      setApt(null)
    }
  }, [locals])

  return (
    <>
      <FormSectionTitle
        borderless
        title={t('general.generalInformation')}
        edit={true}
        onEdit={() => {
          setUpdateDetails(true);
        }}
      ></FormSectionTitle>

      <DetailRow label={t('resv.arrivalDeparture')}>
        <div className="flex space-x-5">
          <div>
            <div className="font-medium">
              {reservation.date_from &&
                getFormattedDate(
                  reservation.date_from,
                  'YYYY-MM-DD HH:mm:ss',
                  'DD-MM-YYYY'
                )}
            </div>
            <div className="text-gray-600 text-sm">
              {t('resv.hourFrom')}{' '}
              {reservation.date_from &&
                getFormattedDate(
                  reservation.date_from,
                  'YYYY-MM-DD HH:mm:ss',
                  'HH:mm'
                )}
            </div>
          </div>
          <div>{'->'}</div>
          <div>
            <div className="font-medium">
              {reservation.date_to &&
                getFormattedDate(
                  reservation.date_to,
                  'YYYY-MM-DD HH:mm:ss',
                  'DD-MM-YYYY'
                )}
            </div>
            <div className="text-gray-600 text-sm">
            {t('resv.hourTo')}{' '}
              {reservation.date_to &&
                getFormattedDate(
                  reservation.date_to,
                  'YYYY-MM-DD HH:mm:ss',
                  'HH:mm'
                )}
            </div>
          </div>
        </div>
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.guestName')}>
        {reservation.name}
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.guestEmail')}>
        {reservation.guest_email}
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.guestPhone')}>
        {reservation.guest_phone || 'ND'}
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.guestCount')}>
        {reservation.guests_num || 'ND'}
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.guestLanguage')}>
        {reservation.lang || 'ND'}
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.aptInternalName')}>
        {apt?.name || 'ND'}
      </DetailRow>
      
      <DetailRow loading={loading} label={t('resv.source')}>
        {/* {reservation.integr_type ?
        reservation.integr_type + " - " + reservation.integr_local_ref_name
        : t('resv.manualGeneration')} */}
        {
          (reservation?.channex_ref || reservation?.channex_booking_room_id)
            ? `Channel Manager ${reservation?.channex_ref ? (' - ' + reservation.channex_ref) : ''}`
            : reservation.integr_type
              ? reservation.integr_type + " - " + reservation.integr_local_ref_name
              : t('resv.manualGeneration')
        }
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.linkCheckIn')}>
        <a
          target={'_blank'}
          href={'https://guest.vikey.it/checkin/' + reservation.resv_key}
          className={'text-cyan-600'}
        >
          {'https://guest.vikey.it/checkin/' + reservation.resv_key}
        </a>
      </DetailRow>

      <DetailRow loading={loading} label={t('resv.openingPage')}>
        <a
          target={'_blank'}
          href={'https://guest.vikey.it/reservations/' + reservation.resv_key}
          className={'text-cyan-600'}
        >
          {'https://guest.vikey.it/reservations/' + reservation.resv_key}
        </a>
      </DetailRow>
      <UpdateReservationDetails
        data={reservation}
        visible={updateDetails}
        onSave={() => {
          setUpdateDetails(false);
          onSave();
        }}
        onClose={() => setUpdateDetails(false)}
      ></UpdateReservationDetails>
    </>
  );
}
