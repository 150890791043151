import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchPaymentMethod,
  fetchSubscription,
  updateApartment,
} from "../../shared/queries";
import {
  Local,
  Subscription,
  ListApiResponse,
  PaymentMethod,
  BaseSelectItem,
} from "../../types";
import FormSectionTitle from "../FormSectionTitle";
import EmptyState from "../EmptyState";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";
import Button from "../Button";
import BaseTable from "../BaseTable";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalActions from "../ModalAction";
import ModalSection from "../ModalSection";

type ApartmentSubcriptionProps = {
  data: Local;
};

export default function ApartmentSubcription({
  data,
}: ApartmentSubcriptionProps) {
  const { t } = useTranslation();

  const history = useHistory();

  const { isLoading: loadingSubscription, data: subscription } = useQuery<
    ListApiResponse<Subscription>,
    Error
  >(["subscription", data.local_key], () => fetchSubscription(data.local_key));

  const { isLoading: loadingMethods, data: paymentMethods } = useQuery<
    ListApiResponse<PaymentMethod>,
    Error
  >(["paymentMethods", data.local_key], () => fetchPaymentMethod());

  const mutatePaymentAccount = useMutation(
    (item: BaseSelectItem) =>
      updateApartment(data.local_key || "", {
        stripe_pm_id: item.value || null,
      }),
    {
      onSuccess: () => {
        toast.success(t("apartmentSubscription.accountAssociated"));
      },
    }
  );

  const [paymentMethod, setPaymentMethod] = useState<{
    label: string;
    value: string;
  }>();

  const [itemsDetail, setItemsDetail] = useState<any>({ list: [], module: "" });

  const [totalPrice, setTotalPrice] = useState<any>((0).toFixed(2));

  useEffect(() => {
    if (!paymentMethods) return;
    const account = paymentMethods?.results.find(
      (p) => p.id === data.stripe_pm_id
    );
    if (account) {
      let last4 = "";
      let type = t("apartmentSubscription.creditCard");
      if (account.card) last4 = account.card.last4;
      if (account.sepa_debit) {
        last4 = account.sepa_debit.last4;
        let type = t("apartmentSubscription.sepa");
      }
      setPaymentMethod({
        label: type + " •••• •••• •••• " + last4,
        value: account.id,
      });
    }
  }, [data, paymentMethods]);

  function getPaymentMethodList() {
    const methods = [] as any;
    if (paymentMethods && paymentMethods.results) {
      paymentMethods.results.forEach((payment: PaymentMethod) => {
        if (payment.card) {
          methods.push({
            label:
              t("apartmentSubscription.creditCard") +
              " •••• •••• •••• " +
              payment.card.last4,
            value: payment.id,
          } as {
            label: string;
            value: string;
          });
        }

        if (payment.sepa_debit) {
          methods.push({
            label:
              t("apartmentSubscription.sepa") +
              " •••• •••• •••• " +
              payment.sepa_debit.last4,
            value: payment.id,
          } as {
            label: string;
            value: string;
          });
        }
      });
    }
    return methods;
  }

  useEffect(() => {
    if (subscription?.results) {
      const initialValue = 0;
      const tempTotal = subscription?.results
        ?.reduce((accum, curr) => accum + curr.calc_mrr, initialValue)
        .toFixed(2);
      setTotalPrice(tempTotal);
    }
  }, [subscription?.results]);

  return (
    <div>
      {subscription &&
        !loadingSubscription &&
        subscription.results &&
        subscription.results.length === 0 && (
          <EmptyState
            title={t("apartmentSubscription.noActiveSubscription")}
            subtitle={t("apartmentSubscription.noActiveSubscriptionText")}
            image={"/money.svg"}
            actionLabel={t("paymentMethods.paymentMethods")}
            action={() => {
              history.push("/configuration/payment-methods");
            }}
          />
        )}

      {subscription &&
        !loadingSubscription &&
        subscription.results &&
        subscription.results.length > 0 && (
          <div>
            <div className={"flex mb-6"}>
              <div className={"w-48 text-gray-600"}>
                {t("apartmentSubscription.subscriptionId")}
              </div>
              <div className={"flex-1"}>{subscription.results[0].id}</div>
            </div>

            <div className={"flex mb-6"}>
              <div className={"w-48 text-gray-600"}>
                {t("apartmentSubscription.status")}
              </div>
              <div className={"flex-1"}>
                {subscription.results[0].status === "ACTIVE" && (
                  <span
                    className={
                      "bg-green-600 text-white py-1 px-3 rounded-2xl text-xs font-medium"
                    }
                  >
                    ATTIVA
                  </span>
                )}
              </div>
            </div>

            <div className={"flex mb-6 items-center"}>
              <div className={"w-48 text-gray-600"}>
                {t("apartmentSubscription.paymentMethod")}
              </div>
              <div className={"w-72"}>
                <SelectInput<BaseSelectItem, false>
                  onChange={(value) => {
                    if (value) {
                      setPaymentMethod(value);
                      mutatePaymentAccount.mutate(value);
                    }
                  }}
                  value={paymentMethod}
                  options={getPaymentMethodList()}
                />
              </div>
            </div>

            <div className={"flex mb-6 items-center"}>
              <div className={"w-48 text-gray-600"}>
                {t("apartmentSubscription.cycles")}
              </div>
              <div className={"w-60"}>
                {subscription.results[0].billing_cycle}
              </div>
            </div>
            <FormSectionTitle
              title={`${t("apartmentSubscription.subinfo")} - ${t(
                "apartmentSubscription.total"
              )} ${totalPrice} EUR`}
            ></FormSectionTitle>

            <BaseTable
              currentPage={1}
              identifierKey={"items"}
              data={subscription.results}
              loading={loadingSubscription}
              displayRows={subscription.results.length}
              filters={[]}
              onEdit={(id) => {}}
              columns={[
                {
                  Header: t<string>("apartmentSubscription.product"),
                  accessor: (row) => {
                    return t(`module.id${row.module_id}`);
                  },
                },
                {
                  Header: t<string>("apartmentSubscription.total"),
                  accessor: (row) => {
                    return (
                      <>{row.billing_baseprice && <>{(row.billing_baseprice / 100).toFixed(2) >
                          row.calc_mrr.toFixed(2) && (
                          <span className="line-through opacity-60 mr-0.5">
                            {(row.billing_baseprice / 100).toFixed(2)}
                          </span>
                        )}
                        {row.calc_mrr.toFixed(2)} {row.currency}</>}
                        
                      </>
                    );
                  },
                },
                {
                  Header: t<string>("price.vat"),
                  accessor: (row) => `${row.vat_percentage}%`,
                },
                {
                  Header: t<string>("general.detail"),
                  accessor: (row, i) => (
                    <Button
                      icon="eye"
                      flat
                      onClick={() =>
                        setItemsDetail({
                          list: row.items,
                          name: t(`module.id${row.module_id}`),
                        })
                      }
                    />
                  ),
                },
              ]}
            />

            <Modal visible={itemsDetail.list?.length > 0}>
              <ModalCard
                className={"w-full max-w-4xl"}
                title={`${t("Storico abbonamento")} - ${itemsDetail.name}`}
                renderActions={() => (
                  <ModalActions
                    saveLabel={t("general.done")}
                    onClose={() => setItemsDetail({ list: [], module: "" })}
                  ></ModalActions>
                )}
              >
                <ModalSection>
                  <BaseTable
                    className="p-5 cursor-not-allowed"
                    currentPage={1}
                    identifierKey={"items"}
                    data={itemsDetail.list}
                    loading={loadingSubscription}
                    displayRows={itemsDetail.length}
                    filters={[]}
                    onEdit={(id) => {}}
                    columns={[
                      {
                        Header: t<string>("apartmentSubscription.state"),
                        accessor: (row) => {
                          return row.status;
                        },
                      },
                      {
                        Header: t<string>("apartmentSubscription.competence"),
                        accessor: (row) => {
                          return (
                            <>
                              {row.month_refer
                                ? dayjs(
                                    row.month_refer.toString(),
                                    "YYYYMM"
                                  ).format("MMMM YYYY")
                                : "ND"}
                            </>
                          );
                        },
                      },
                      {
                        Header: t<string>("apartmentSubscription.amount"),
                        accessor: (row) =>
                          `${(row.amount / 100).toFixed(2)} ${row.currency}`,
                      },
                      {
                        Header: t<string>("price.vat"),
                        accessor: (row, i) => `${row.vat_percentage}%`,
                      },
                    ]}
                  />
                </ModalSection>
              </ModalCard>
            </Modal>
          </div>
        )}
    </div>
  );
}
