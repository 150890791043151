import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { updateSingleDocument } from "../../shared/queries"
import { BaseSelectItem, GuestDocument } from "../../types"
import FormField from "../FormField"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import TextInput from "../TextInput"
import DatePicker from "../DatePicker"
import SelectInput from "../SelectInput";
import { deepStrictEqual } from "assert"
import CountrySelector from "../CountrySelector"
import { any, object } from "joi"
import ProvinceSelector from '../ProvinceSelector'
import CountySelector from '../CountySelector'



type ReservationDocumentUpdateProps = {
  data: GuestDocument,
  resv_key: string | undefined,
  visible: boolean,
  onClose?: () => void,
  onSave?: () => void
}

export default function ReservationDocumentUpdate ({
  data,
  resv_key,
  visible,
  onClose,
  onSave
} : ReservationDocumentUpdateProps) {

  const queryClient = useQueryClient()
  const { t } = useTranslation() 

  const sexOptions = [
    {label: "F", value:"F"},
    {label: "M", value:"M"}
  ]


  const docOption=[{label: t('reservationDocuments.documentTypes.PASSPORT') ,value:'PASSPORT'},
  {label: t('reservationDocuments.documentTypes.IDCARD') ,value:'IDCARD'},
  {label: t('reservationDocuments.documentTypes.IDCARDE') ,value:'IDCARDE'},
  {label: t('reservationDocuments.documentTypes.DIVER') ,value:'DIVER'},
{label: t('reservationDocuments.documentTypes.NIE') ,value:'NIE'}]

  const [doc, setDoc] =useState<GuestDocument>({
    ...data,
  })
  

  /**
   * Mutate the selected stripe account
   */
  const mutation = useMutation(() => updateSingleDocument({resv_key: resv_key, 
                                                          data: doc}), {
    onSuccess: () => {
      toast.success(t('general.updated'));
      // queryClient.invalidateQueries(['apartment', data.local_key])
      if (onClose) onClose()
    },
    onError: (error: Error) => {
      toast.error(error.message || t('general.requestError'));
    }
  })



  return (
    <Modal
      visible={visible}>
      <ModalCard
       noscroll={false}
      className={'w-full max-w-3xl'}
       title={t('reservationDocuments.updateDocument')}>
        <ModalSection >
          <div className={'p-2 flex-wrap flex -mx-2'}>
          

          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.firstName')}>
            <TextInput
              value={doc.first_name}
              onChange={value => setDoc({...doc, first_name : value})}></TextInput>

          </FormField>
            

          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.lastName')}>
            <TextInput
              value={doc.last_name}
              onChange={value => setDoc({...doc, last_name : value})}></TextInput>

          </FormField>


          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.sex')}>
            <SelectInput<BaseSelectItem, false>
            value={{label: doc.sex, value: doc.sex}}
            options={sexOptions}
            onChange={item => setDoc({...doc, sex:item?.value})} /> 

          </FormField>


          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.residence')}>
            <CountrySelector
              
              initial={doc.residencecountry}
              onChange={item => setDoc({...doc, residencecountry:item.value, residenceprov: '', residencecity: ''})}></CountrySelector>
          </FormField>


          {(doc.residencecountry === '100000100' || doc.residencecountry == '100000239')  && (
          <div style={{width:'100%'}}>

            <FormField label={t('reservationDocuments.residenceprov')}
            className={'px-2 mb-4 w-full'}>
              
              <ProvinceSelector
                country={doc.residencecountry}
                initial={doc.residenceprov}
                onChange={item => setDoc({...doc, residenceprov:item.value, residencecity: '' })}></ProvinceSelector>
            </FormField>
          </div>)}


          {(doc.residencecountry === '100000100' || doc.residencecountry == '100000239')  && doc.residenceprov && (
          <div style={{width:'100%'}}>
            <FormField label={t('reservationDocuments.residencecity')}
            className={'px-2 mb-4 w-full'}>
              <CountySelector
                country={doc.residencecountry}
                initial={doc.residencecity}
                province={doc.residenceprov}
                onChange={item => setDoc({...doc, residencecity:item.value })}></CountySelector>
            </FormField>
          </div>
        )}



          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.dateOfBirth')}>
            <DatePicker
              value={doc.dob}
              inputFormat={"YYYY-MM-DDTHH:mm:ss.sss"}
              outputFormat={"YYYY-MM-DDTHH:mm:ss.sss"}
              displayFormat={'DD-MM-YYYY'}
              onChange={value => setDoc({...doc, dob:value+'Z'})}></DatePicker>

          </FormField>


          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.placeOfBirth')}>
            <CountrySelector
              initial={doc.country} 
              onChange={item => setDoc({...doc, country :item.value, prov:'', city:''})}/>
          </FormField>


          {(doc.country === '100000100' || doc.country == '100000239')  && (
          <div style={{width:'100%'}}>

            <FormField label={t('reservationDocuments.provOfBirth')}
            className={'px-2 mb-4 w-full'}>
              
              <ProvinceSelector
                country={doc.country}
                initial={doc.prov}
                onChange={item => setDoc({...doc, prov:item.value, city:'' })}></ProvinceSelector>
            </FormField>
          </div>)}


          {(doc.country === '100000100' || doc.country == '100000239')  && doc.prov && (
          <div style={{width:'100%'}}>
            <FormField label={t('reservationDocuments.cityOfBirth')}
            className={'px-2 mb-4 w-full'}>
              <CountySelector
                country={doc.country}
                initial={doc.city}
                province={doc.prov}
                onChange={item => setDoc({...doc, city:item.value })}></CountySelector>
            </FormField>
          </div>
        )}


          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.citizenship')}>
            <CountrySelector
              initial={doc.citizenship} 
              onChange={item => setDoc({...doc, citizenship:item.value })}/>
            
          </FormField>


          { <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.documentType')}>
            <SelectInput<BaseSelectItem, false>
            value={docOption.find((optiion)=>{
              if (optiion.value== doc.doctype){
                return optiion.label}})}
            options={docOption}
            onChange={item => setDoc({...doc, doctype:item?.value})} /> 

          </FormField> } 


          {/* <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.releasedBy')}>
            <CountrySelector
              initial={country.country} />
            
          </FormField> */}





          <FormField

          className={'px-2 mb-4 w-full'}
          label={t('reservationDocuments.releasedBy')}>
          <CountrySelector
            initial={doc.doccountry} 
            onChange={item => setDoc({...doc, doccountry :item.value, docprov: '', doccity: '' })}/>
          </FormField>


          {(doc.doccountry === '100000100' || doc.doccountry == '100000239')  && (
          <div style={{width:'100%'}}>

          <FormField label={t('reservationDocuments.releasedByProv')}
          className={'px-2 mb-4 w-full'}>
            
            <ProvinceSelector
              country={doc.doccountry}
              initial={doc.docprov}
              onChange={item => setDoc({...doc, docprov:item.value, doccity: ''})}></ProvinceSelector>
          </FormField>
          </div>)}


          {(doc.doccountry === '100000100' || doc.doccountry == '100000239')  && doc.docprov && (
          <div style={{width:'100%'}}>
          <FormField label={t('reservationDocuments.releasedByCity')}
          className={'px-2 mb-4 w-full'}>
            <CountySelector
              country={doc.doccountry}
              initial={doc.doccity}
              province={doc.docprov}
              onChange={item => setDoc({...doc, doccity:item.value } )}></CountySelector>
          </FormField>
          </div>
          )}
          


          {doc.docs_issue!== undefined &&
            <FormField
              className={'px-2 mb-4 w-full'}
              label={t('reservationDocuments.dateOfIssue')}>
              <DatePicker
                value={doc.docs_issue}
                inputFormat={"YYYY-MM-DDTHH:mm:ss.sss"}
                outputFormat={"YYYY-MM-DDTHH:mm:ss.sss"}
                displayFormat={'DD-MM-YYYY'}
                onChange={value => setDoc({...doc, docs_issue:value+'Z'})}></DatePicker>
            </FormField>
          }


          {doc.docs_expiration!== undefined &&
            <FormField
              className={'px-2 mb-4 w-full'}
              label={t('reservationDocuments.dateOfExpiration')}>
              <DatePicker
                value={doc.docs_expiration}
                inputFormat={"YYYY-MM-DDTHH:mm:ss.sss"}
                outputFormat={"YYYY-MM-DDTHH:mm:ss.sss"}
                displayFormat={'DD-MM-YYYY'}
                onChange={value => setDoc({...doc, docs_expiration:value+'Z'})}></DatePicker>
            </FormField>
          }


          <FormField

            className={'px-2 mb-4 w-full'}
            label={t('reservationDocuments.documentNumber')}>
            <TextInput
              value={doc.iddoc}
              onChange={value => setDoc({...doc, iddoc:value})}></TextInput>

          </FormField>


          
     
        </div>
        </ModalSection>
        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t('general.update')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={() => {
            mutation.mutate()
            if (onSave) onSave()
          }}></ModalActions>
          
      </ModalCard>
    </Modal>
  )

}