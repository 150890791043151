import React from "react";
import Button from "../Button";
import DropdownMenu from "../DropdownMenu";
import { Message } from "../../types";
import Icon from "../Icon";

import { useTranslation } from "react-i18next";

interface MessageThreadProps {
  thread_id: string;
  name: string;
  resv_key: string;
  isSelected: boolean;
  pinned: boolean;
  muted: boolean;
  archived: boolean;

  last_message?: Message;
  has_been_read: boolean;

  onSelect: () => void;
  onPin: (thread_id: string) => void;
  onArchive: (thread_id: string) => void;
  onMute: (thread_id: string) => void;
}

const truncateMessage = (message: Message, maxLength: number = 30): string => {
  if (!message.content) return "...";
  if (message.content.length <= maxLength) return message.content;
  return message.content.slice(0, maxLength) + "...";
};

const MessageThread: React.FC<MessageThreadProps> = ({
  thread_id,
  name,
  resv_key,
  isSelected,
  pinned,
  archived,
  muted,
  last_message,
  onSelect,
  onPin,
  onArchive,
  onMute,
  has_been_read,
}) => {
  const formatTime = (dateString: Date) => {
    const date = new Date(dateString);
    const today = new Date();


    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isToday = date >= today;
    const isYesterday = date >= yesterday && date < today;

    if (isToday) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${formattedHours}:${formattedMinutes}`;
    } else if (isYesterday) {
      return t("messaging.sentYesterday");
    } else {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  };
  const { t } = useTranslation();

  return (
    <ul
      className={`p-4 cursor-pointer ${
        isSelected ? "bg-cyan-100" : "hover:bg-gray-200"
      } ${has_been_read ? "font-normal" : "font-bold"}`}
      onClick={onSelect}
    >
      <div className="flex items-center justify-between">
        {!has_been_read && (
          <Icon className="bg-cyan-500 mr-2" name="unread" size="20px" />
        )}
        <div className="flex flex-col flex-1">
          <span className="font-semibold">{name}</span>
          <span className="font-semibold text-gray-600">{resv_key}</span>
          {last_message && (
            <span className="text-sm text-gray-400">
              {truncateMessage(last_message)}
            </span>
          )}
        </div>

        <div className="ml-auto text-right flex items-center">
          {muted && (
            <Icon className="bg-cyan-500 mr-2" name="silent" size="20px" />
          )}
          {pinned && (
            <Icon className="bg-cyan-500 mr-2" name="pin" size="20px" />
          )}
        </div>
        {/* Dropdown and date */}
        <div className="ml-auto text-right" >
          <DropdownMenu
            label=""
            icon="arrow-tip-down"
            color="info"
            options={[
              // { label: muted ? 'Unmute notifications' : 'Mute notifications', onClick: () => onMute(thread_id) },
              {
                label: pinned
                  ? t("messaging.actions.unpinChat")
                  : t("messaging.actions.pinChat"),
                onClick: () => onPin(thread_id),
              },
              {
                label: archived
                  ? t("messaging.actions.restoreChat")
                  : t("messaging.actions.archivechat"),
                onClick: () => onArchive(thread_id),
              },
            ]}
          />
          {last_message && (
            <span className="text-xs text-gray-400 block mt-1">
              {formatTime(last_message.sent_on)}
            </span>
          )}
        </div>
      </div>
    </ul>
  );
};

export default React.memo(MessageThread);
