import React, { useEffect, useRef, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Icon from '../Icon';

interface MessageInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
  rows?: number; // default number of rows 
  maxLength?: number;
  disabled?: boolean;
  emoji_lang?: string;
  emoji_icon_size?: string;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({
  value,
  onChange,
  onKeyDown,
  placeholder,
  className,
  style,
  rows = 3,
  maxLength,
  onFocus,
  onBlur,
  disabled,
  emoji_lang = "it",
  emoji_icon_size = "25px",
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleEmojiSelect = (emoji: any) => {
    const emojiSymbol = emoji.native;
    const textarea = textareaRef.current;

    if (textarea) {
      const { selectionStart, selectionEnd } = textarea;
      const newValue =
        value.slice(0, selectionStart) +
        emojiSymbol +
        value.slice(selectionEnd);

      onChange({
        ...({ target: { value: newValue } } as React.ChangeEvent<HTMLTextAreaElement>),
      });

      setTimeout(() => {
        textarea.focus();
        textarea.selectionStart = textarea.selectionEnd = selectionStart + emojiSymbol.length;
      }, 0);
    }

    setShowEmojiPicker(false);
  };

  const handleToggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  useEffect(() => {
    if (showEmojiPicker && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [showEmojiPicker]);


  return (
    <div className={`relative ${className}`} style={style}>
      <textarea
        ref={textareaRef}
        disabled={disabled}
        className="w-full rounded-lg p-2 pr-12 resize-none focus:outline-none focus:ring-2 focus:ring-cyan-600"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        rows={rows}
        maxLength={maxLength}
        onFocus={onFocus}
        onBlur={(e) => {
          // Prevent textarea blur if emoji picker is active
          if (showEmojiPicker) {
            e.preventDefault();
            textareaRef.current?.focus();
          } else if (onBlur) {
            onBlur(e);
          }
        }}
        style={{
          maxHeight: '200px',
        }}
      />

      {/* Toggle Emoji Picker Button */}
      <button
        type="button"
        className="absolute right-6 bottom-6"
        onClick={handleToggleEmojiPicker}
      >
        {showEmojiPicker ? (
          <Icon name="emojiSymbol:selected" size={emoji_icon_size} />
        ) : (
          <Icon name="emojiSymbol" size={emoji_icon_size} />
        )}
      </button>

      {/* Emoji Picker Component */}
      {showEmojiPicker && (
        <div className="absolute bottom-full right-0 mb-2 z-10 sm:mb-4 sm:right-4 sm:left-auto">
        <div className="max-w-xs sm:max-w-sm">
          <Picker data={data} onEmojiSelect={handleEmojiSelect} locale={emoji_lang} />
        </div>
      </div>
      
      )}
    </div>
  );
};

export default MessageInput;
