import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchAllModules, updateApartmentV3 } from "../../shared/queries";
import { moduleId } from "../../shared/utils/config";
import { CityTaxParams, Local } from "../../types";
import Button from "../Button";
import FormSectionTitle from "../FormSectionTitle";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import PageWrapper from "../PageWrapper";
import AddApartmentTax from "./AddApartmentTax";

type ApartmentTaxProps = {
  data: Local;
  onRefresh?: () => void;
};
export default function ApartmentTax({ data, onRefresh }: ApartmentTaxProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [currentTax, setCurrentTax] = useState<CityTaxParams | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const [newTax, setNewTax] = useState<CityTaxParams | null>(null);
  const [activeTax, setActiveTax] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [visible, setVisible] = useState(false);
  function handleNewTax() {
    if (!data.city_tax_params) {
      data.city_tax_params = [];
    }
    setNewTax({
      combination: "MAX",
      date_from_day: 1,
      date_from_mon: 1,
      date_to_day: 31,
      date_to_mon: 12,
      fixed: "0",
      percentage: "0",
      percentage_max: "999",
      perperson_maxdays: "0",
      perpercentage_maxdays: "999",
      perperson_price: "0",
      perperson_steps: [],
      perperson_steps_resid: [],
      perpercentage_steps_resid: [],
      perpercentage_steps: [],
      perstayprice: [],
      perstayprice_steps_resid: [],
      perstayprice_steps: [],
    });
    // setCurrentTaxIndex(data.city_tax_params.length - 1)
    // setCurrentTax(data.city_tax_params[data.city_tax_params.length - 1])
  }

  const mutation = useMutation(
    (d: any) => updateApartmentV3(data.local_key || "", d),
    {
      onSuccess: (data) => {
        toast.success(data.msg ? t(data.msg) : t("general.updated"));
        setCurrentTax(null);
        setNewTax(null);
        if (onRefresh) onRefresh();
      },
      onError: () => {
        if (onRefresh) onRefresh();
      }
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([data?.local_key], () => {
    if (data?.local_key) {
      return fetchAllModules(data?.local_key).then((data: any) => {
        const moduleFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.city_tax
        );
        if (moduleFinded) {
          if (moduleFinded?.active) {
            setVisible(false);
            setModuleName("");
            setActiveTax(true);
          } else {
            setVisible(true);
            setModuleName(t(`module.id${moduleFinded.id}`).toUpperCase());
            setActiveTax(false);
          }
        } else {
          setVisible(false);
          setModuleName("");
          setActiveTax(true);
        }
        return data;
      });
    }
  });

  return (
    <div>
      <FormSectionTitle
        borderless
        title={t("apartmentTax.cityTax")}
      ></FormSectionTitle>

      <div className={"flex border-b pb-2 border-gray-300"}>
        <div className={"px-1 flex-1"}>
          <div className={"text-gray-500 font-medium"}>
            {t("apartmentTax.validity")}
          </div>
        </div>

        <div className={"px-1 flex-1"}>
          <div className={"text-gray-500 font-medium"}>
            {t("apartmentTax.calc")}
          </div>
        </div>

        <div className={"px-1 flex-1"}>
          <div className={"text-gray-500 font-medium"}>
            {t("addAppartmentTax.fixedCost")}
          </div>
        </div>

        <div className={"px-1 flex-1"}>
          <div className={"text-gray-500 font-medium"}>
            {t("apartmentTax.esenzione")}
          </div>
        </div>

        <div className={"px-1 flex-1"}>
          <div className={"text-gray-500 font-medium"}></div>
        </div>
      </div>

      {data &&
        data.city_tax_params &&
        Array.isArray(data.city_tax_params) &&
        data.city_tax_params?.map((item: CityTaxParams, index: number) => (
          <div
            key={index}
            className={
              "border-b py-3 cursor-pointer hover:bg-white transition flex"
            }
            onClick={() => {
              if (activeTax) {
                setCurrentTax(item);
                setCurrentIndex(index);
              }
            }}
          >
            <div className={"px-1 flex-1"}>
              <div className={"text-sm text-gray-800"}>
                {t("apartmentTax.from")}{" "}
                <span
                  className={
                    "bg-cyan-100 border mx-1 border-cyan-300 text-xs py-1 font-medium rounded-2xl px-1"
                  }
                >
                  {item.date_from_day}/{item.date_from_mon}
                </span>{" "}
                al{" "}
                <span
                  className={
                    "mx-1 bg-cyan-100 border border-cyan-300 text-xs py-1 font-medium rounded-2xl px-1"
                  }
                >
                  {item.date_to_day}/{item.date_to_mon}
                </span>
              </div>
            </div>

            <div className={"px-1 flex-1"}>
              <div className={"text-sm text-gray-800"}>
                {t("apartmentTax.costPerNight")} (max {item.perperson_maxdays}
                {t("apartmentTax.days")})
              </div>
            </div>

            <div className={"px-1 flex-1"}>
              <div className={"text-sm text-gray-800"}>
                {item.fixed + " EUR"}
              </div>
            </div>

            <div className={"px-1 flex-1"}>
              <div className={"text-sm text-gray-800"}>
                {item.perperson_steps?.length} {t("apartmentTax.ageBands")}
              </div>
            </div>

            <div className={"px-1 flex-1"}>
              <div className={"text-sm text-gray-800"}>
                <button
                  disabled={!activeTax}
                  onClick={(e) => {
                    e.stopPropagation();
                    mutation.mutate({
                      city_tax_params: JSON.stringify(
                        data.city_tax_params.filter((tax, taxIndex) => {
                          if (taxIndex !== index) return tax;
                        })
                      ),
                    });
                  }}
                  className={"text-red-600 font-medium"}
                >
                  {t("general.delete")}
                </button>
              </div>
            </div>
          </div>
        ))}

      <div className={"mt-5 mb-6"}>
        <Button
          disabled={!activeTax}
          onClick={handleNewTax}
          label={t("general.add")}
        />
      </div>

      <Modal visible={newTax !== null || currentTax !== null}>
        <ModalCard
          className={"w-full max-w-xl"}
          title={t("apartmentTax.cityTax")}
        >
          <ModalSection className={"w-full max-w-xl"}>
            {newTax !== null && (
              <AddApartmentTax
                taxElement={newTax}
                onChange={(tax) => {
                  setNewTax(tax);
                  // setCurrentTax(tax)
                }}
              />
            )}
            {currentTax !== null && (
              <AddApartmentTax
                taxElement={currentTax}
                onChange={(tax) => {
                  setCurrentTax(tax);
                  // setCurrentTax(tax)
                }}
              />
            )}
          </ModalSection>
          <ModalActions
            saveLabel={t("general.confirm")}
            onClose={() => {
              // data.city_tax_params.splice(-1)
              setNewTax(null);
              setCurrentTax(null);
              setCurrentIndex(-1);
            }}
            onSave={() => {
              if (newTax) {
                data.city_tax_params.push(newTax);
              } else if (currentTax && currentIndex >= 0) {
                data.city_tax_params[currentIndex] = { ...currentTax };
              }
              mutation.mutate({
                city_tax_params: JSON.stringify(data.city_tax_params),
              });
            }}
          ></ModalActions>
        </ModalCard>
      </Modal>
      <Modal visible={visible} className={"w-full"}>
        <ModalCard
          title={`${moduleName.toUpperCase()} (${t("module.statusInactive")})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() => setVisible(false)}
              onSave={() =>
                history.push(`/modules/module#${moduleId.city_tax}`)
              }
              saveLabel={t("module.purchase")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </div>
  );
}
