import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { fetchAllModules } from "../../../shared/queries";
import { moduleId } from "../../../shared/utils/config";
import { LocalTable } from "../../../types";
import Checkbox from "../../Checkbox";

type CitytaxSelectorProps = {
  local: LocalTable | undefined;
  integration: any;
  setIntegration: any;
};
export default function CitytaxSelector({
  local,
  integration,
  setIntegration,
}: CitytaxSelectorProps) {
  const { t } = useTranslation();

  const [activeCityTax, setActiveCityTax] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([local?.local_key], () => {
    if (local?.local_key) {
      return fetchAllModules(local?.local_key).then((data: any) => {
        const moduleCitytaxFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.city_tax
        );
        if (moduleCitytaxFinded && moduleCitytaxFinded.active) {
          setActiveCityTax(true);
        } else {
          setActiveCityTax(false);
        }
        return data;
      });
    }
  });

  return (
    <>
      <Checkbox
        disabled={
          (!integration?.integr_chk_citytax &&
            (!local ||
              local == null ||
              local.stripe_account == null ||
              local.city_tax_params == null)) ||
          (!activeCityTax && !integration?.integr_chk_citytax)
        }
        label={t("resv.requestTaxPayment")}
        onChange={() => {
          setIntegration({
            ...integration,
            integr_chk_citytax: !integration?.integr_chk_citytax,
          });
        }}
        checked={integration?.integr_chk_citytax  === 0 ? false : integration?.integr_chk_citytax}
      />
      {integration?.integr_chk_citytax && (
        <div className="bg-gray-100 border rounded-2xl p-4 pb-2 mb-2">
          <Checkbox
            label={t("resv.chargeGuestTaxCommissionText")}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_citytax_chargeguest:
                  !integration.integr_chk_citytax_chargeguest,
              });
            }}
            checked={integration.integr_chk_citytax_chargeguest  === 0 ? false : integration.integr_chk_citytax_chargeguest}
          />
          <span
            className={`flex-1 cursor-pointer py-1 px-1 text-gray-400 font-medium }`}
          >
            {t("integration.channel")}
          </span>
          <Checkbox
            label={t("integration.enableFor") + " Airbnb"}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_citytax_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_citytax_filterchannel
                      ?.EXPEDIA ?? false,
                  BOOKING:
                    integration.integr_chk_citytax_filterchannel
                      ?.BOOKING ?? false,
                  OTHER:
                    integration.integr_chk_citytax_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    !integration.integr_chk_citytax_filterchannel?.AIRBNB,
                },
              });
            }}
            checked={integration.integr_chk_citytax_filterchannel?.AIRBNB === 0 ? false : integration.integr_chk_citytax_filterchannel?.AIRBNB}
          />
          <Checkbox
            label={t("integration.enableFor") + " Booking"}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_citytax_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_citytax_filterchannel
                      ?.EXPEDIA ?? false,
                  OTHER:
                    integration.integr_chk_citytax_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    integration.integr_chk_citytax_filterchannel?.AIRBNB ?? false,
                  BOOKING:
                    !integration.integr_chk_citytax_filterchannel?.BOOKING,
                },
              });
            }}
            checked={
              integration.integr_chk_citytax_filterchannel?.BOOKING  === 0 ? false : integration.integr_chk_citytax_filterchannel?.BOOKING
            }
          />
          <Checkbox
            label={t("integration.enableFor") + " Expedia"}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_citytax_filterchannel: {
                  BOOKING:
                    integration.integr_chk_citytax_filterchannel
                      ?.BOOKING ?? false,
                  OTHER:
                    integration.integr_chk_citytax_filterchannel?.OTHER ?? false,
                  AIRBNB:
                    integration.integr_chk_citytax_filterchannel?.AIRBNB ?? false,
                  EXPEDIA:
                    !integration.integr_chk_citytax_filterchannel?.EXPEDIA,
                },
              });
            }}
            checked={
              integration.integr_chk_citytax_filterchannel?.EXPEDIA === 0 ? false : integration.integr_chk_citytax_filterchannel?.EXPEDIA
            }
          />
          <Checkbox
            label={t("integration.enableForOther")}
            onChange={() => {
              setIntegration({
                ...integration,
                integr_chk_citytax_filterchannel: {
                  EXPEDIA:
                    integration.integr_chk_citytax_filterchannel
                      ?.EXPEDIA ?? false,
                  BOOKING:
                    integration.integr_chk_citytax_filterchannel
                      ?.BOOKING ?? false,
                  AIRBNB:
                    integration.integr_chk_citytax_filterchannel?.AIRBNB ?? false,
                  OTHER:
                    !integration.integr_chk_citytax_filterchannel?.OTHER,
                },
              });
            }}
            checked={integration.integr_chk_citytax_filterchannel?.OTHER === 0 ? false : integration.integr_chk_citytax_filterchannel?.OTHER}
          />
        </div>
      )}
    </>
  );
}
