import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { getFormattedDate } from "../../shared/utils/formatting";
import { CalendarDay, Local, ReservationTable } from "../../types";
import { useTranslation } from "react-i18next";

type ApartmentRowProps = {
  local: Local;
  days: CalendarDay[];
  startDate: string;
  reservations: ReservationTable[];
  onView: (r: ReservationTable) => void;
};

export default function ApartmentRow({
  startDate,
  local,
  days,
  reservations,
  onView,
}: ApartmentRowProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const [reservationList, setReservationList] = useState<any>(reservations);

  const verifyMonitorStatus = (status: string) => {
    switch (status) {
      case "NOTINIT":
        return (
          <div
            data-tip={"Inattivo"}
            className={"w-2 h-2 rounded-full bg-gray-600"}
          ></div>
        );
      case "ERROR":
        return (
          <div
            data-tip={"Errore"}
            className={"w-2 h-2 rounded-full bg-red-600"}
          ></div>
        );
      case "ACTIVE":
        return (
          <div
            data-tip={"Attivo"}
            className={"w-2 h-2 rounded-full bg-green-600"}
          ></div>
        );
      default:
        break;
    }
  };
  const verifyBg = (status: string) => {
    switch (status) {
      case "WAIT":
        return "bg-red-200 text-red-800 border-red-800";
      case "NONEED":
        return "bg-gray-100 text-gray-800 border-gray-800";
      case "OK":
        return "bg-green-100 text-green-800 border-green-800";
      case "PEND":
        return "bg-yellow-300 text-yellow-800 border-yellow-800";
      case "BOOKING":
        return "bg-blue-800 text-yellow-300";
      case "AIRBNB":
        return "bg-red-500 text-white ";
      default:
        break;
    }
  };
  const verifyStatus = (status: string) => {
    switch (status) {
      case "WAIT":
        return t("resv.notExecuted");
      case "NONEED":
        return t("resv.notNecessary");
      case "OK":
        return t("resv.confirmed");
      case "PEND":
        return t("resv.waitingConfirmation");
      default:
        break;
    }
  };
  /* const verifyBgSource = (source: string, status: string, type: string) => {
    if (type === "background") {
      if (source.toLowerCase() === "airbnb") {
        switch (status) {
          case "WAIT":
            return "linear-gradient(to right, #f472b5 0%, #f472b5 50%, #fecaca 50%, #fecaca 100%)";
          case "NONEED":
            return "linear-gradient(to right, #f472b5 0%, #f472b5 50%, #f3f4f6 50%, #f3f4f6 100%)";
          case "OK":
            return "linear-gradient(to right, #f472b5 0%, #f472b5 50%, #d1fae5 50%, #d1fae5 100%)";
          case "PEND":
            return "linear-gradient(to right, #f472b5 0%, #f472b5 50%, #fcd34d 50%, #fcd34d 100%)";
          default:
            break;
        }
      } else if (source.toLowerCase() === "booking") {
        switch (status) {
          case "WAIT":
            return "linear-gradient(to right, #2564eb 0%, #2564eb 50%, #fcd34d 50%, #fcd34d 100%)";
          case "NONEED":
            return "linear-gradient(to right, #2564eb 0%, #2564eb 50%, #fcd34d 50%, #fcd34d 100%)";
          case "OK":
            return "linear-gradient(to right, #2564eb 0%, #2564eb 50%, #fcd34d 50%, #fcd34d 100%)";
          case "PEND":
            return "linear-gradient(to right, #2564eb 0%, #2564eb 50%, #fcd34d 50%, #fcd34d 100%)";
          default:
            break;
        }
      } else {
        switch (status) {
          case "WAIT":
            return "linear-gradient(to right, #00BDE1 0%, #00BDE1 50%, #fcd34d 50%, #fcd34d 100%)";
          case "NONEED":
            return "linear-gradient(to right, #00BDE1 0%, #00BDE1 50%, #fcd34d 50%, #fcd34d 100%)";
          case "OK":
            return "linear-gradient(to right, #00BDE1 0%, #00BDE1 50%, #fcd34d 50%, #fcd34d 100%)";
          case "PEND":
            return "linear-gradient(to right, #00BDE1 0%, #00BDE1 50%, #fcd34d 50%, #fcd34d 100%)";
          default:
            break;
        }
      }
    }
    if (type === "borderText") {
      switch (status) {
        case "WAIT":
          return "border-red-800 text-red-800";
        case "NONEED":
          return "border-gray-800 text-gray-800";
        case "OK":
          return "border-green-800 text-green-800";
        case "PEND":
          return "border-yellow-800 text-yellow-800";
        default:
          break;
      }
    }
  }; */

  useEffect(() => {
    setReservationList(
      reservations.map((r) => {
        const length =
          dayjs(r.check_out, "YYYY-MM-DD").diff(
            dayjs(r.check_in, "YYYY-MM-DD"),
            "days"
          ) + 1;
        const fromToday = dayjs(r.check_in, "YYYY-MM-DD").diff(
          dayjs(startDate),
          "days"
        );
        return {
          ...r,
          length,
          fromToday,
          width: (length / 20) * 100,
          marginLeft: (fromToday / 20) * 100,
        };
      })
    );
  }, [reservations, startDate]);

  return (
    <div
      className="hover:bg-white flex"
      style={{
        height:
          (reservationList.length > 0
            ? `${reservationList.length * 8 + 64}px`
            : "64px")
      }}
    >
      <div
        className={
          "bg-gray-100 z-50 text-sm space-x-2 font-medium text-gray-600 flex items-center w-52 border-b py-2 border-r truncate px-5"
        }
      ><div>
          {local.monitor_status && verifyMonitorStatus(local.monitor_status)}
        </div>
        
        <div className="whitespace-pre-wrap">{local.name}</div>
      </div>
<div className="flex-1 border-b flex relative place-content-center">
        <div className="absolute w-full h-full top-0 left-0 bg-red flex">
          {days.map((day, index) => (
            <div
              onClick={() => {
                history.push(
                  "/reservations/new?from=" +
                    day.date +
                    "&local=" +
                    local.local_key
                );
              }}
              key={index}
              className={`flex-1 h-full border-r hover:bg-gray-100 cursor-pointer`}
            ></div>
          ))}
        </div>
        <ul>
          {reservationList.map((reservation: any, index: number) => {
            if (reservation.local_key) {
              return (
                <motion.li
                  key={index}
                  initial={{
                    left: -90,
                  }}
                  animate={{
                    left: 0,
                  }}
                  style={{
                    width: reservation.width + "%",
                    marginLeft: reservation.marginLeft + "%",
                    marginTop:
                      reservationList.length > 0 ? index * 7 + 5 : index * 24,
                  }}
                  className={`absolute cst-shadow truncate px-4 cursor-pointer flex flex-nowrap items-center w-full h-6 p-1 rounded-2xl font-medium text-xs border-2 ${verifyBg(
                    reservation.source
                  )}`}
                  data-tip={`${reservation.source} | ${getFormattedDate(
                    reservation.check_in,
                    "YYYY-MM-DD",
                    "DD-MM-YYYY"
                  )} -> ${getFormattedDate(
                    reservation.check_out,
                    "YYYY-MM-DD",
                    "DD-MM-YYYY"
                  )} ${reservation.guests_num ? `| ${t('resv.guestCount')}: ${reservation.guests_num}`: ''}`}
                  data-for="soclose"
                >
                  <div className={"w-full"}>
                    {reservation.source}
                    <span
                      className={"border-white opacity-30 mx-1 border-r h-full"}
                    ></span>
                    {getFormattedDate(
                      reservation.check_in,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )}
                    <span
                      className={"border-white opacity-30 mx-1 border-r h-full"}
                    ></span>
                    {getFormattedDate(
                      reservation.check_out,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )}
                  </div>
                  <ReactTooltip
                    id="soclose"
                    getContent={(dataTip) => <span>{dataTip}</span>}
                    effect="float"
                  />
                </motion.li>
              );
            }
            return (
              <motion.li
                key={index}
                initial={{
                  left: -90,
                }}
                animate={{
                  left: 0,
                }}
                onClick={() => {
                  if (onView) onView(reservation);
                }}
                style={{
                  width: reservation.width + "%",
                  marginLeft: reservation.marginLeft + "%",
                  marginTop:
                    reservationList.length > 0 ? index * 7 + 5 : index * 24,
                }}
                className={`absolute cst-shadow truncate px-4 cursor-pointer flex flex-nowrap items-center w-full h-6 p-1 rounded-2xl font-medium text-xs border-2 ${verifyBg(
                  reservation.checkin_status
                )}`}
                data-tip={`${reservation.code}| ${
                  reservation.guest
                } | ${getFormattedDate(
                  reservation.check_in,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )} -> ${getFormattedDate(
                  reservation.check_out,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )} | Check-In: ${verifyStatus(reservation.checkin_status)} ${reservation.guests_num ? `| ${t('resv.guestCount')}: ${reservation.guests_num}`: ''}`}
                data-for="soclose"
              >
                <div className={"w-full"}>
                  {reservation.code}
                  <span
                    className={"border-white opacity-30 mx-1 border-r h-full"}
                  ></span>
                  {reservation.guest}
                  <span
                    className={"border-white opacity-30 mx-1 border-r h-full"}
                  ></span>
                  {getFormattedDate(
                    reservation.check_in,
                    "YYYY-MM-DD",
                    "DD-MM-YYYY"
                  )}
                  <span
                    className={"border-white opacity-30 mx-1 border-r h-full"}
                  ></span>
                  {getFormattedDate(
                    reservation.check_out,
                    "YYYY-MM-DD",
                    "DD-MM-YYYY"
                  )}
                </div>
                <ReactTooltip
                  id="soclose"
                  getContent={(dataTip) => <span>{dataTip}</span>}
                  effect="float"
                />
              </motion.li>
            );
          })}
        </ul>
      </div>
      
    </div>
  );
}