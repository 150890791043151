import { useMutation, useQuery } from "react-query";
import {
  fetchCheckinsV3,
  fetchContracts,
  fetchCustomEmails,
  fetchStripeAccounts,
  updateApartment,
} from "../../shared/queries";
import {
  ApiIntegrationItem,
  BaseSelectItem,
  ListApiResponseData,
  ListApiResponseV3,
  Local,
  StripeAccount,
} from "../../types";
import CircleSpinner from "../CircleSpinner";
import FormField from "../FormField";
import FormSectionTitle from "../FormSectionTitle";
import TextInput from "../TextInput";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";
import { getOptionList } from "../../shared/utils/formatting";
type ApartmentConnectionProps = {
  data: Local;
  onRefresh?: () => void;
};

export default function ApartmentConnection({
  data,
  onRefresh,
}: ApartmentConnectionProps) {
  const { t } = useTranslation();

  const { data: stripeAccounts, isLoading: loadingStripe } = useQuery<
    ListApiResponseData<StripeAccount>,
    Error
  >(["stripe", data.local_key], () => fetchStripeAccounts(data.local_key));

  const { data: checkInsEmail } = useQuery<
  ListApiResponseV3<ApiIntegrationItem>,
    Error
  >(["checkinsEmail", data.local_key], () => fetchCheckinsV3(data.local_key, true));

  const { data: emailTemplates, isFetching: fetchingEmailTemplates } = useQuery(
    ["emailTemplates", data],
    () => fetchCustomEmails(data.local_key),
    {
      enabled: data && data !== undefined,
    }
  );

  const { data: contracts, isFetching: fetchingContracts } = useQuery(
    ["contracts"],
    () => fetchContracts(data.local_key, true),
    {
      enabled: data && data !== undefined,
    }
  );

  const [stripeAccount, setStripeAccount] = useState<{
    label: string;
    value: number | undefined;
  }>({
    label: "Vikey Default",
    value: 0,
  });
  const [customCheckin, setCustomCheckin] = useState<{
    label: string;
    value: number | undefined;
  }>({
    label: "Vikey Default",
    value: 0,
  });
  const [customCheckinNoInst, setCustomCheckinNoInst] = useState<{
    label: string;
    value: number | undefined;
  }>({
    label: "Vikey Default",
    value: 0,
  });
  const [customContract, setCustomContract] = useState<{
    label: string;
    value: number | undefined;
  }>({
    label: "No Contract",
    value: 0,
  });
  const [customMail, setCustomMail] = useState<{
    label: string;
    value: number | undefined;
  }>();

  const [customMailExtra, setCustomMailExtra] = useState<{
    label: string;
    value: number | undefined;
  }>();
  /**
   * We set the default value for the stripe account field
   */
  useEffect(() => {
    if (data && stripeAccounts) {
      const account = stripeAccounts.data.find((account: StripeAccount) => {
        return account.id === data.stripe_account;
      });
      if (account && account.id) {
        setStripeAccount({
          label: account.name + " (" + account.customer_id + ")",
          value: account.id,
        });
      }
    }
  }, [stripeAccounts]);

  useEffect(() => {
    if (data.default_cust_checkin_ptr !== 0) {
      const checkinTemplate = checkInsEmail?.results?.find(
        (a) => a.id === data.default_cust_checkin_ptr
      );

      if (checkinTemplate && checkinTemplate.id)
        setCustomCheckin({
          label: checkinTemplate.objkey ?? "",
          value: checkinTemplate.id,
        });
    }

    if (data.default_cust_checkin_noinst_ptr !== 0) {
      const checkinTemplate = checkInsEmail?.results?.find(
        (a) => a.id === data.default_cust_checkin_noinst_ptr
      );
      if (checkinTemplate && checkinTemplate.id)
        setCustomCheckinNoInst({
          label: checkinTemplate.objkey ?? "",
          value: checkinTemplate.id,
        });
    }
  }, [checkInsEmail]);

  useEffect(() => {
    const test = getOptionList(contracts?.results, "objkey", "id", undefined, {
      label: "No Contract",
      value: 0,
    }).find((item) => item.value === data.default_cust_contract_ptr);
    if (test) setCustomContract(test);
  }, [contracts]);

  useEffect(() => {
    const test = getOptionList(
      emailTemplates?.results,
      "objkey",
      "id",
      undefined,
      {
        label: "Vikey Default",
        value: 0,
      }
    ).find((item) => item.value === data.default_cust_email_ptr);
    if (test) setCustomMail(test);
  }, [emailTemplates]);

  useEffect(() => {
    const test = getOptionList(
      emailTemplates?.results,
      "objkey",
      "id",
      undefined,
      {
        label: "Vikey Default",
        value: 0,
      }
    ).find((item) => item.value === data.default_cust_email_noinst_ptr);
    if (test) setCustomMailExtra(test);
  }, [emailTemplates]);

  /**
   * Mutate the selected stripe account
   */
  const mutateStripeAccount = useMutation(
    (item: { label: any; value: any }) =>
      updateApartment(data.local_key || "", {
        stripe_account: item.value || null,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  /**
   * Mutate the selected stripe account
   */
  const mutateApiIntegration = useMutation(
    (items: any) =>
      updateApartment(data.local_key || "", {
        default_cust_checkin_ptr:
          items.default_cust_checkin_ptr || customCheckin?.value,
        default_cust_checkin_noinst_ptr:
          items.default_cust_checkin_noinst_ptr || customCheckin?.value,
        default_cust_contract_ptr:
          items.default_cust_contract_ptr ?? customContract?.value,
        default_cust_email_ptr:
          items.default_cust_email_ptr ?? customMail?.value,
        default_cust_email_noinst_ptr:
          items.default_cust_email_noinst_ptr ?? customMailExtra?.value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  const mutateApiExternalKey = useMutation(
    (val: string) =>
      updateApartment(data.local_key || "", {
        external_key: val,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  const [istatUser, setIstatUser] = useState<string>(
    data.turismo5_user === undefined ? "" : data.turismo5_user
  );
  const mutateIstatUser = useMutation(
    (value: string) =>
      updateApartment(data.local_key || "", {
        turismo5_user: value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  const [istatPass, setIstatPass] = useState<string>(
    data.turismo5_pass === undefined ? "" : data.turismo5_pass
  );
  const mutateIstatPass = useMutation(
    (value: string) =>
      updateApartment(data.local_key || "", {
        turismo5_pass: value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  const [istatId, setIstatId] = useState<string>(
    data.turismo5_id === undefined ? "" : data.turismo5_id
  );
  const mutateIstatId = useMutation(
    (value: string) =>
      updateApartment(data.local_key || "", {
        turismo5_id: value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );
  const [istatProperty, setIstatProperty] = useState<string>(
    data.turismo5_property === undefined ? "" : data.turismo5_property
  );
  const mutateIstatProperty = useMutation(
    (value: string) =>
      updateApartment(data.local_key || "", {
        turismo5_property: value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  const [spainName, setSpainName] = useState<string>(
    data.spain_apartment_name === undefined ? "" : data.spain_apartment_name
  );
  const mutateSpainName = useMutation(
    (value: string) =>
      updateApartment(data.local_key || "", {
        spain_apartment_name: value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  const [spainId, setSpainId] = useState<string>(
    data.spain_apartment_id === undefined ? "" : data.spain_apartment_id
  );
  const mutateSpainId = useMutation(
    (value: string) =>
      updateApartment(data.local_key || "", {
        spain_apartment_id: value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  const [licenseId, setLicenseId] = useState<string>(
    data.license_id === undefined ? "" : data.license_id
  );
  const mutateLicenseId = useMutation(
    (value: string) =>
      updateApartment(data.local_key || "", {
        license_id: value,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onRefresh) onRefresh();
      },
    }
  );

  return (
    <div className={"mb-6"}>
      <FormSectionTitle borderless title={"Stripe"}></FormSectionTitle>

      {loadingStripe ? (
        <div>
          <CircleSpinner></CircleSpinner>{" "}
          {t("apartmentConnection.loadingAccounts")}
        </div>
      ) : stripeAccounts &&
        stripeAccounts.data &&
        stripeAccounts.data.length > 0 ? (
        <div className={"flex -mx-2"}>
          <FormField
            className={"px-2 flex-1"}
            label={t("apartmentConnection.paymentAccount")}
            tooltip={t("apartmentConnection.paymentAccountText")}
          >
            <SelectInput<BaseSelectItem, false>
              value={stripeAccount}
              className={"w-full"}
              onChange={(val) => {
                if (val) {
                  setStripeAccount(val);
                  mutateStripeAccount.mutate(val);
                }
              }}
              options={[
                ...stripeAccounts.data
                  .filter((a) => a.id && a.name)
                  .map((a) => {
                    return {
                      label: a.name + " (" + a.customer_id + ")",
                      value: a.id,
                    };
                  }),
                {
                  label: t("apartmentConnection.noAccounts"),
                  value: undefined,
                },
              ]}
            />
          </FormField>
        </div>
      ) : (
        <div>
          {t("apartmentConnection.noStripeConnected")},{" "}
          <a href="#" className={"font-medium text-cyan-600"}>
            {t("apartmentConnection.connectStripeNow")}.
          </a>
        </div>
      )}

      <FormSectionTitle
        title={"ISTAT"}
        subtitle={t("apartmentConnection.istat")}
      ></FormSectionTitle>
      <FormField
        tooltip={t("apartmentConnection.turismo5User")}
        label={t("apartmentConnection.turismo5User")}
      >
        <TextInput
          value={istatUser}
          onChange={(val) => {
            setIstatUser(val);
            mutateIstatUser.mutate(val);
          }}
        ></TextInput>
      </FormField>

      <FormField
        className={"mt-6"}
        tooltip={t("apartmentConnection.turismo5Pass")}
        label={t("apartmentConnection.turismo5Pass")}
      >
        <TextInput
          onChange={(val) => {
            setIstatPass(val);
            mutateIstatPass.mutate(val);
          }}
          value={istatPass}
        ></TextInput>
      </FormField>

      <FormField
        className={"mt-6"}
        tooltip={t("apartmentConnection.turismo5Id")}
        label={t("apartmentConnection.turismo5Id")}
      >
        <TextInput
          onChange={(val) => {
            setIstatId(val);
            mutateIstatId.mutate(val);
          }}
          value={istatId}
        ></TextInput>
      </FormField>

      <FormField
        className={"mt-6"}
        tooltip={t("apartmentConnection.turismo5Property")}
        label={t("apartmentConnection.turismo5Property")}
      >
        <TextInput
          onChange={(val) => {
            setIstatProperty(val);
            mutateIstatProperty.mutate(val);
          }}
          value={istatProperty}
        ></TextInput>
      </FormField>

      {/* IF YES SPAGNAAAA */}
      <FormSectionTitle
        title={t("apartmentConnection.spainIstat")}
        subtitle={t("apartmentConnection.spainIstatText")}
      ></FormSectionTitle>
      <FormField
        tooltip={t("apartmentConnection.spainApartmentId")}
        label={t("apartmentConnection.spainApartmentId")}
      >
        <TextInput
          value={spainId}
          onChange={(val) => {
            setSpainId(val);
            mutateSpainId.mutate(val);
          }}
        ></TextInput>
      </FormField>

      <FormField
        className={"mt-6"}
        tooltip={t("apartmentConnection.spainApartmentName")}
        label={t("apartmentConnection.spainApartmentName")}
      >
        <TextInput
          onChange={(val) => {
            setSpainName(val);
            mutateSpainName.mutate(val);
          }}
          value={spainName}
        ></TextInput>
      </FormField>
      {/* IF YES SPAGNAAAA */}

      <FormSectionTitle
        title={t("apartmentConnection.licenseIdName")}
        subtitle={t("apartmentConnection.licenseIdSubtitle")}
      ></FormSectionTitle>
      <FormField
        tooltip={t("apartmentConnection.licenseIdTooltip")}
        label={t("apartmentConnection.licenseIdLabel")}
      >
        <TextInput
          value={licenseId}
          onChange={(val) => {
            setLicenseId(val);
            mutateLicenseId.mutate(val);
          }}
        ></TextInput>
      </FormField>

      <FormSectionTitle
        title={t("apartmentConnection.apiIntegration")}
        subtitle={t("apartmentConnection.apiIntegrationText")}
      ></FormSectionTitle>

      <FormField
        tooltip={t("apartmentConnection.setupExternalKey")}
        label={t("apartmentConnection.externalKey")}
      >
        <TextInput
          onChange={(val) => {
            if (!val) {
              val = "";
            }
            mutateApiExternalKey.mutate(val);
            data.external_key = val;
          }}
          value={data.external_key}
        ></TextInput>
      </FormField>

      <FormSectionTitle
        title={t("apartmentConnection.vikeyAPI")}
        subtitle={t("apartmentConnection.vikeyAPIText")}
      ></FormSectionTitle>

      {checkInsEmail && checkInsEmail.results && (
        <>
          {/* TODO CONNECTION */}
          <FormField label={t("apartmentConnection.selectedCustomCheckin")}>
            <SelectInput<BaseSelectItem, false>
              value={customCheckin}
              className={"w-full"}
              onChange={(val) => {
                if (val) {
                  setCustomCheckin(val);
                  mutateApiIntegration.mutate({
                    default_cust_checkin_ptr: val.value,
                    default_cust_checkin_noinst_ptr: customCheckinNoInst?.value,
                  });
                }
              }}
              options={[
                ...checkInsEmail.results
                  .filter((a) => a.id && a.user.name)
                  .map((a) => {
                    return {
                      label: a.objkey,
                      value: a.id,
                    };
                  }),
                {
                  label: "Vikey default",
                  value: undefined,
                },
              ]}
            />
          </FormField>

          <FormField
            className={"mt-6"}
            label={t("apartmentConnection.selectedNotInstalledCheckin")}
          >
            <SelectInput<BaseSelectItem, false>
              value={customCheckinNoInst}
              className={"w-full"}
              onChange={(val) => {
                if (val) {
                  setCustomCheckinNoInst(val);
                  mutateApiIntegration.mutate({
                    default_cust_checkin_noinst_ptr: val.value,
                  });
                }
              }}
              options={[
                ...checkInsEmail.results
                  .filter((a) => a.id && a.user.name)
                  .map((a) => {
                    return {
                      label: a.objkey,
                      value: a.id,
                    };
                  }),
                {
                  label: "Vikey default",
                  value: undefined,
                },
              ]}
            />
          </FormField>

          <FormField
            className={"mt-6"}
            label={t("apartmentConnection.connectedContract")}
          >
            <SelectInput<BaseSelectItem, false>
              value={customContract}
              className={"w-full"}
              onChange={(val) => {
                if (val) {
                  setCustomContract(val);
                  mutateApiIntegration.mutate({
                    default_cust_contract_ptr: val.value,
                  });
                }
              }}
              isLoading={fetchingContracts}
              options={getOptionList(
                contracts?.results,
                "objkey",
                "id",
                undefined,
                {
                  label: "No Contract",
                  value: 0,
                }
              )}
            />
          </FormField>
          <FormField
            className={"mt-6"}
            label={t("apartmentConnection.customEmail")}
          >
            <SelectInput<BaseSelectItem, false>
              value={customMail}
              className={"w-full"}
              isLoading={fetchingEmailTemplates}
              onChange={(val) => {
                if (val) {
                  setCustomMail(val);
                  mutateApiIntegration.mutate({
                    default_cust_email_ptr: val.value,
                  });
                }
              }}
              options={getOptionList(
                emailTemplates?.results,
                "objkey",
                "id",
                undefined,
                {
                  label: "Vikey Default",
                  value: 0,
                }
              )}
            />
          </FormField>

          <FormField
            className={"mt-6"}
            label={t("apartmentConnection.selectedNotInstalledEmail")}
          >
            <SelectInput<BaseSelectItem, false>
              value={customMailExtra}
              className={"w-full"}
              isLoading={fetchingEmailTemplates}
              onChange={(val) => {
                if (val) {
                  setCustomMailExtra(val);
                  mutateApiIntegration.mutate({
                    default_cust_email_noinst_ptr: val.value,
                  });
                }
              }}
              options={getOptionList(
                emailTemplates?.results,
                "objkey",
                "id",
                undefined,
                {
                  label: "Vikey Default",
                  value: 0,
                }
              )}
            />
          </FormField>
        </>
      )}
    </div>
  );
}
