import dayjs from "dayjs";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { getFormattedDate } from "../../shared/utils/formatting";
import { CalendarDay, Local, ReservationTable } from "../../types";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import { useDispatch } from "react-redux";
import { setModalValueOverride } from "../../features/channelManager/channelManagerSlice";
import { IsSafari } from "../../shared/utils/customfunc";

type ApartmentRowProps = {
  local: Local;
  days: CalendarDay[];
  startDate: string;
  reservations: ReservationTable[];
  onView: (r: ReservationTable) => void;
  selectStart: any;
  objChannel: any;
  refetchRestrictions: any;
  viewDays: number;
};

export default function ApartmentRow({
  selectStart,
  startDate,
  local,
  days,
  reservations,
  onView,
  objChannel,
  viewDays,
  refetchRestrictions,
}: ApartmentRowProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [reservationList, setReservationList] = useState<any>(reservations);
  const [tempObj, setTempObj] = useState<any>();
  const [viewMore, setViewMore] = useState(false);
  // selezione giorni per resv
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [isDraggingDays, setIsDraggingDays] = useState(false);
  const [startPointDays, setStartPointDays] = useState({ x: 0, y: 0 });
  const containerRefDays = useRef(null);
  // selezione availability su timeline
  const [selectedDivs, setSelectedDivs] = useState<any>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  // selezione price su timeline
  const [selectedDivsPrice, setSelectedDivsPrice] = useState<any>([]);
  const [isDraggingPrice, setIsDraggingPrice] = useState(false);
  const [startPointPrice, setStartPointPrice] = useState({ x: 0, y: 0 });
  const [viewAllPerPerson, setViewAllPerPerson] = useState(false);
  const containerRefPrice: any = useRef([]);

  // Funzione per avviare la selezione
  const handleMouseDownDays = (e: any) => {
    setIsDraggingDays(true);
    setStartPointDays({ x: e.clientX, y: e.clientY });
    const container: any = containerRefDays.current;
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPointDays.x, currentPoint.x);
    const right = Math.max(startPointDays.x, currentPoint.x);
    const top = Math.min(startPointDays.y, currentPoint.y);
    const bottom = Math.max(startPointDays.y, currentPoint.y);
    const selectedIds: any = [...selectedDays];
    // Controlla quali div sono all'interno dell'area di selezione
    Array.from(container.children).forEach((div: any, index) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const idsFinded = selectedIds.find(
          (id: string) => id === e.target.textContent
        );
        if (idsFinded) {
          const isBefore = dayjs(e.target.textContent).isBefore(
            selectedIds[selectedIds.length - 1]
          );
          if (isBefore) {
            selectedIds.pop();
          }
        } else {
          selectedIds.push(e.target.textContent);
        }
      }
    });
    setSelectedDays(selectedIds);
  };

  // Funzione per aggiornare la selezione durante il trascinamento
  const handleMouseMoveDays = (e: any) => {
    if (!isDraggingDays) return;
    const container: any = containerRefDays.current;
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPointDays.x, currentPoint.x);
    const right = Math.max(startPointDays.x, currentPoint.x);
    const top = Math.min(startPointDays.y, currentPoint.y);
    const bottom = Math.max(startPointDays.y, currentPoint.y);
    const selectedIds: any = [...selectedDays];
    // Controlla quali div sono all'interno dell'area di selezione
    Array.from(container.children).forEach((div: any, index) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const idsFinded = selectedIds.find(
          (id: string) => id === e.target.textContent
        );
        if (idsFinded) {
          const isBefore = dayjs(e.target.textContent).isBefore(
            selectedIds[selectedIds.length - 1]
          );
          if (isBefore) {
            selectedIds.pop();
          }
        } else {
          selectedIds.push(e.target.textContent);
        }
      }
    });
    setSelectedDays(selectedIds); // Aggiorna la selezione
  };

  // Funzione per terminare la selezione
  const handleMouseUpDays = () => {
    if (!isDraggingDays) return;
    const dateFromSelected = selectedDays[0];
    const dateToSelected = selectedDays[selectedDays.length - 1];
    const isBefore = dayjs(dateFromSelected).isBefore(dateToSelected);
    let dateFrom = "";
    let dateTo = "";
    if (isBefore) {
      dateFrom = dateFromSelected;
      dateTo = dateToSelected;
    } else {
      dateFrom = dateToSelected;
      dateTo = dateFromSelected;
    }
    history.push(
      "/reservations/new?from=" +
        dateFrom +
        "&to=" +
        dateTo +
        "&local=" +
        local.local_key
    );
    setIsDraggingDays(false);
    setSelectedDays([]);
  };

  // Funzione per avviare la selezione
  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartPoint({ x: e.clientX, y: e.clientY });
    setSelectedDivs([]); // Resetta la selezione corrente
  };

  // Funzione per aggiornare la selezione durante il trascinamento
  const handleMouseMove = (e: any) => {
    if (!isDragging) return;
    const container: any = containerRef.current;
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPoint.x, currentPoint.x);
    const right = Math.max(startPoint.x, currentPoint.x);
    const top = Math.min(startPoint.y, currentPoint.y);
    const bottom = Math.max(startPoint.y, currentPoint.y);
    const selectedIds: any = [...selectedDivs];
    // Controlla quali div sono all'interno dell'area di selezione
    Array.from(container.children).forEach((div: any, index) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const idsFinded = selectedIds.find(
          (id: string) => id === e.target.textContent
        );
        if (idsFinded) {
          const isBefore = dayjs(e.target.textContent).isBefore(
            selectedIds[selectedIds.length - 1]
          );
          if (isBefore) {
            selectedIds.pop();
          }
        } else {
          selectedIds.push(e.target.textContent);
        }
      }
    });
    setSelectedDivs(selectedIds); // Aggiorna la selezione
  };

  // Funzione per terminare la selezione
  const handleMouseUp = () => {
    if (!isDragging) return;
    const dateFromSelected = selectedDivs[0];
    const dateToSelected = selectedDivs[selectedDivs.length - 1];
    const isBefore = dayjs(dateFromSelected).isBefore(dateToSelected);
    let dateFrom = "";
    let dateTo = "";
    if (isBefore) {
      dateFrom = dateFromSelected;
      dateTo = dateToSelected;
    } else {
      dateFrom = dateToSelected;
      dateTo = dateFromSelected;
    }
    dispatch(
      setModalValueOverride({
        display: true,
        type: "availability",
        roomType: local.name,
        ratePlan: null,
        restriction: "availability",
        currentPrice: 0,
        priceValue: 0,
        availabilityValue: false,
        dateRange: {
          from: dateFrom,
          to: dateTo,
        },
        roomTypeId: tempObj?.room_type_id,
        propertyId: tempObj?.property_id,
        stopSellValue: false,
        closedToArrivalValue: false,
        closedToDepartureValue: false,
        ratePlanId: "",
        minStayValue: 0,
        maxStayValue: 0,
      })
    );
    setIsDragging(false);
    setSelectedDivs([]);
  };

  // Funzione per avviare la selezione
  const handleMouseDownRate = (e: any) => {
    if (
      e.target.textContent.includes("per_room") ||
      e.target.textContent.includes("per_person")
    ) {
      const newArrayTemp = e.target.textContent.split("|||");
      if (newArrayTemp[9] && newArrayTemp[9] !== "false") {
        setIsDraggingPrice(true);
        setStartPointPrice({ x: e.clientX, y: e.clientY });
        setSelectedDivsPrice([]); // Resetta la selezione corrente
      }
    }
  };

  // Funzione per aggiornare la selezione durante il trascinamento
  const handleMouseMoveRate = (e: any) => {
    if (!isDraggingPrice) return;
    const container: any = containerRefPrice.current;
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPointPrice.x, currentPoint.x);
    const right = Math.max(startPointPrice.x, currentPoint.x);
    const top = Math.min(startPointPrice.y, currentPoint.y);
    const bottom = Math.max(startPointPrice.y, currentPoint.y);
    const selectedIdsPrice: any = [...selectedDivsPrice];
    // Controlla quali div sono all'interno dell'area di selezione
    container.forEach((div: any) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const newArrayTemp = e.target.textContent.split("|||");
        const idsFinded = selectedIdsPrice.find(
          (idsPrice: any) => idsPrice.id === newArrayTemp[3] + newArrayTemp[1]
        );
        const isSameRatePlanId = selectedIdsPrice.find(
          (idsPrice: any) => idsPrice.ratePlanId === newArrayTemp[3]
        );
        if (idsFinded) {
          const isBefore = dayjs(newArrayTemp[1]).isBefore(
            selectedIdsPrice[selectedIdsPrice.length - 1].date
          );
          if (isBefore) {
            selectedIdsPrice.pop();
          }
        } else if (isSameRatePlanId || selectedIdsPrice.length === 0) {
          if (newArrayTemp[9] && newArrayTemp[9] !== "false") {
            const newObjTemp = {
              rate: parseFloat(newArrayTemp[0]),
              date: newArrayTemp[1],
              title: newArrayTemp[2],
              ratePlanId: newArrayTemp[3],
              stopSell: newArrayTemp[4],
              closed_to_arrival: newArrayTemp[5],
              closed_to_departure: newArrayTemp[6],
              min_stay_arrival: newArrayTemp[7],
              max_stay: newArrayTemp[8],
              id: newArrayTemp[3] + newArrayTemp[1],
              editable: newArrayTemp[9],
              key: newArrayTemp[10],
            };
            selectedIdsPrice.push(newObjTemp);
          }
        }
      }
    });
    setSelectedDivsPrice(selectedIdsPrice); // Aggiorna la selezione
  };

  // Funzione per terminare la selezione
  const handleMouseUpRate = () => {
    if (!isDraggingPrice || selectedDivsPrice.length === 0) return;
    const lastSelected = selectedDivsPrice[selectedDivsPrice.length - 1];
    const dateFirstSelected = selectedDivsPrice[0]?.date;
    const dateSecondSelected = lastSelected?.date;
    const isBefore = dayjs(dateFirstSelected).isBefore(dateSecondSelected);
    let dateFrom = "";
    let dateTo = "";
    if (isBefore) {
      dateFrom = dateFirstSelected;
      dateTo = dateSecondSelected;
    } else {
      dateFrom = dateSecondSelected;
      dateTo = dateFirstSelected;
    }
    dispatch(
      setModalValueOverride({
        display: true,
        type: lastSelected.key,
        roomType: local.name,
        ratePlan: lastSelected?.title,
        ratePlanId: lastSelected.ratePlanId,
        restriction: "rate",
        currentPrice: parseFloat(lastSelected?.rate),
        priceValue: parseFloat(lastSelected?.rate),
        availabilityValue: 1,
        dateRange: {
          from: dateFrom,
          to: dateTo,
        },
        roomTypeId: tempObj?.room_type_id,
        propertyId: tempObj?.property_id,
        stopSellValue: lastSelected?.stop_sell,
        closedToArrivalValue: lastSelected?.closed_to_arrival,
        closedToDepartureValue: lastSelected?.closed_to_departure,
        minStayValue: lastSelected?.min_stay_arrival
          ? lastSelected?.min_stay_arrival
          : lastSelected?.min_stay_through,
        maxStayValue: lastSelected?.max_stay,
      })
    );
    setIsDraggingPrice(false);
    setSelectedDivsPrice([]);
  };

  const verifyMonitorStatus = (status: string) => {
    switch (status) {
      case "NOTINIT":
        return (
          <div
            data-tip={"Digital"}
            className={"w-2 h-2 rounded-full bg-purple-600"}
          ></div>
        );
      case "ERROR":
        return (
          <div
            data-tip={"Offline"}
            className={"w-2 h-2 rounded-full bg-red-600"}
          ></div>
        );
      case "ACTIVE":
        return (
          <div
            data-tip={"Online"}
            className={"w-2 h-2 rounded-full bg-cyan-600"}
          ></div>
        );
      default:
        break;
    }
  };
  const verifyBg = (status: string) => {
    switch (status) {
      case "WAIT":
        return "bg-red-200 text-gray-900 border-red-700";
      case "NONEED":
        return "bg-gray-100 text-gray-900 border-gray-700";
      case "OK":
        return "bg-green-100 text-gray-900 border-green-700";
      case "PEND":
        return "bg-yellow-300 text-gray-900 border-yellow-700";
      case "BOOKING":
        return "text-gray-900 border-blue-700";
      case "AIRBNB":
        return "text-gray-900 border-red-700";
      default:
        break;
    }
  };
  const verifyStatus = (status: string) => {
    switch (status) {
      case "WAIT":
        return t("resv.notExecuted");
      case "NONEED":
        return t("resv.notNecessary");
      case "OK":
        return t("resv.confirmed");
      case "PEND":
        return t("resv.waitingConfirmation");
      default:
        break;
    }
  };

  const logoImageComp = (source: string) => {
    switch (source) {
      case "AIRBNB":
        return (
          <img
            className={"w-5 h-5 rounded-full mr-1"}
            src="/images/logos/airbnbsmall.png"
            alt=""
          />
        );
      case "BOOKING":
        return (
          <img
            className={"w-5 h-5 rounded-full mr-1"}
            src="/images/logos/bookingsmall.png"
            alt=""
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      objChannel?.results[local.local_key as keyof typeof objChannel.results]
    ) {
      setTempObj(
        objChannel?.results[local.local_key as keyof typeof objChannel.results]
      );
    } else {
      setTempObj(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objChannel]);

  useEffect(() => {
    setReservationList(
      reservations.map((r) => {
        const length = dayjs(r.check_out, "YYYY-MM-DD").diff(
          dayjs(r.check_in, "YYYY-MM-DD"),
          "days"
        ); // +1
        const fromToday = dayjs(r.check_in, "YYYY-MM-DD").diff(
          dayjs(startDate),
          "days"
        );
        return {
          ...r,
          length,
          fromToday,
          width: (length / viewDays) * 100 - 1, //-9
          marginLeft: (fromToday / viewDays) * 100 + 3, // +4
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations, startDate]);

  useEffect(() => {
    if ((selectStart && selectStart.length < 0) || !selectStart) {
      setTempObj(null);
    }
    if (selectStart && selectStart.length === 1) {
      setViewMore(true);
    }
  }, [selectStart]);

  return (
    <div className="my-10">
      {/* AVAILABILITY */}
      {tempObj &&
        Object.keys(tempObj)?.map((key) => {
          if (key === "availability") {
            return (
              <div
                className="hover:bg-white flex"
                key={1}
                style={{
                  height: "25px",
                }}
              >
                <div
                  className={
                    "bg-gray-100 z-50 md:text-sm text-xs md:space-x-2 font-medium text-gray-600 flex items-start md:w-52 w-20 border-t border-r truncate md:px-5"
                  }
                >
                  <div className="w-full text-center">
                    {t(`apartment.${key}`)}
                  </div>
                </div>
                <div className="flex-1 border-t flex relative place-content-center">
                  <div
                    className="absolute w-full h-full top-0 left-0 flex"
                    ref={containerRef}
                    style={{ position: "relative", userSelect: "none" }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                  >
                    {days.map((day) => {
                      const specificDate = `${day.date.slice(
                        6,
                        10
                      )}-${day.date.slice(3, 5)}-${day.date.slice(0, 2)}`;
                      return (
                        <div
                          key={specificDate}
                          onClick={() => {
                            dispatch(
                              setModalValueOverride({
                                display: true,
                                type: key,
                                roomType: local.name,
                                ratePlan: null,
                                restriction: key,
                                currentPrice: 0,
                                priceValue: 0,
                                availabilityValue: tempObj[key][specificDate],
                                dateRange: {
                                  from: specificDate,
                                  to: specificDate,
                                },
                                roomTypeId: tempObj?.room_type_id,
                                propertyId: tempObj?.property_id,
                                stopSellValue: false,
                                closedToArrivalValue: false,
                                closedToDepartureValue: false,
                                ratePlanId: "",
                                minStayValue: 0,
                                maxStayValue: 0,
                              })
                            );
                          }}
                          className={`flex-1 relative h-full border-r cursor-pointer text-center align-middle w-full truncate items-center ${
                            tempObj[key][specificDate]
                              ? selectedDivs.includes(specificDate) ||
                                (selectedDivs.length > 0 &&
                                  dayjs(specificDate).isAfter(
                                    selectedDivs[0]
                                  ) &&
                                  dayjs(specificDate).isBefore(
                                    selectedDivs[selectedDivs.length - 1]
                                  ))
                                ? "blue"
                                : "bg-green-300 hover:bg-green-400"
                              : selectedDivs.includes(specificDate) ||
                                (selectedDivs.length > 0 &&
                                  dayjs(specificDate).isAfter(
                                    selectedDivs[0]
                                  ) &&
                                  dayjs(specificDate).isBefore(
                                    selectedDivs[selectedDivs.length - 1]
                                  ))
                              ? "blue"
                              : "bg-red-300 hover:bg-red-400"
                          }`}
                        >
                          <div
                            className={`w-8 h-8  m-auto rounded-full pointToCenter text-transparent`}
                          >
                            {specificDate}
                          </div>

                          {/* {tempObj[key][specificDate]} */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }
          return false;
        })}
      {/* reservations */}
      <div
        className="hover:bg-white flex border-t"
        style={{
          height:
            reservationList.length > 0
              ? `${reservationList.length * 24 + 64}px`
              : "64px",
        }}
      >
        <div
          className={
            "bg-gray-100 z-50 md:text-sm text-xs font-medium text-gray-600 flex gap-1 items-center md:w-52 w-20 border-b md:py-2 md:px-5 md:p-0 p-1 border-r truncate"
          }
        >
          <div>
            {local.monitor_status && verifyMonitorStatus(local.monitor_status)}
            <ReactTooltip />
          </div>

          <div className="whitespace-pre-wrap">{local.name}</div>
        </div>
        <div className="flex-1 border-b flex relative place-content-center">
          <div
            className="absolute w-full h-full top-0 left-0 bg-red flex"
            ref={containerRefDays}
            style={{ position: "relative", userSelect: "none" }}
            onMouseDown={handleMouseDownDays}
            onMouseMove={handleMouseMoveDays}
            onMouseUp={handleMouseUpDays}
          >
            {days.map((day, index) => {
              const specificDate = `${day.date.slice(6, 10)}-${day.date.slice(
                3,
                5
              )}-${day.date.slice(0, 2)}`;
              return (
                <div
                  onClick={() => {
                    history.push(
                      "/reservations/new?from=" +
                        day.date +
                        "&local=" +
                        local.local_key
                    );
                  }}
                  key={index}
                  className={`flex-1 h-full border-r hover:bg-gray-100 cursor-pointer`}
                  style={{
                    backgroundColor:
                      selectedDays.includes(specificDate) ||
                      (selectedDays.length > 0 &&
                        dayjs(specificDate).isAfter(selectedDays[0]) &&
                        dayjs(specificDate).isBefore(
                          selectedDays[selectedDays.length - 1]
                        ))
                        ? "lightblue"
                        : "transparent",
                  }}
                >
                  <span className="hidden">{specificDate}</span>
                </div>
              );
            })}
          </div>
          <ul>
            {reservationList.map((reservation: any, index: number) => {
              if (reservation.local_key) {
                return (
                  <React.Fragment key={reservation.code}>
                    <motion.li
                      initial={{
                        left: -90,
                      }}
                      animate={{
                        left: 0,
                      }}
                      style={{
                        width: reservation.width + "%",
                        marginLeft: reservation.marginLeft + "%",
                        marginTop:
                          reservationList.length > 0
                            ? index * 24 + 5
                            : index * 24, // 30
                      }}
                      className={`${
                        IsSafari()
                          ? "transform skew-x-20Minus"
                          : "transform skew-x-20Minus"
                      } absolute cst-shadow truncate px-4 cursor-pointer flex flex-nowrap items-center w-full h-6 p-1 font-medium text-xs border-2 ${verifyBg(
                        reservation.checkin_status
                      )} ${
                        (reservation?.source === "BOOKING" ||
                          reservation?.source === "AIRBNB") &&
                        verifyBg(reservation.source)
                      }`}
                      data-tip={`${reservation.source} - ${getFormattedDate(
                        reservation.check_in,
                        "YYYY-MM-DD",
                        "DD-MM-YYYY"
                      )} -> ${getFormattedDate(
                        reservation.check_out,
                        "YYYY-MM-DD",
                        "DD-MM-YYYY"
                      )} ${
                        reservation.guests_num
                          ? `- ${t("resv.guestCount")}: ${
                              reservation.guests_num
                            }`
                          : ""
                      } ${
                        reservation.tot_price
                          ? `- ${t(
                              "reservationNotes.price"
                            )}: ${reservation.tot_price.toFixed(2)}€`
                          : ``
                      }`}
                      data-for={`soclose${reservation.code}`}
                    >
                      <div
                        className={`w-full truncate ${
                          IsSafari() ? "" : "transform skew-x-20"
                        }`}
                      >
                        {reservation.source}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {getFormattedDate(
                          reservation.check_in,
                          "YYYY-MM-DD",
                          "DD-MM-YYYY"
                        )}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {getFormattedDate(
                          reservation.check_out,
                          "YYYY-MM-DD",
                          "DD-MM-YYYY"
                        )}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {reservation.guests_num
                          ? `- ${t("resv.guestCount")}: ${
                              reservation.guests_num
                            }`
                          : ""}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {reservation.tot_price
                          ? `- ${t(
                              "reservationNotes.price"
                            )}: ${reservation.tot_price.toFixed(2)}€`
                          : ``}
                      </div>
                    </motion.li>
                    <ReactTooltip
                      id={`soclose${reservation.code}`}
                      getContent={(dataTip) => <span>{dataTip}</span>}
                      effect="float"
                    />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={reservation.code}>
                  <motion.li
                    initial={{
                      left: -90,
                    }}
                    animate={{
                      left: 0,
                    }}
                    onClick={() => {
                      if (onView) onView(reservation);
                    }}
                    style={{
                      width: reservation.width + "%",
                      marginLeft: reservation.marginLeft + "%",
                      marginTop:
                        reservationList.length > 0
                          ? index * 24 + 5
                          : index * 24, // 30
                    }}
                    className={`${
                      IsSafari()
                        ? "transform skew-x-20Minus"
                        : "transform skew-x-20Minus"
                    } absolute cst-shadow truncate px-4 cursor-pointer flex flex-nowrap items-center w-full h-6 p-1 font-medium text-xs border-2 ${verifyBg(
                      reservation.checkin_status
                    )} ${
                      (reservation?.source === "BOOKING" ||
                        reservation?.source === "AIRBNB") &&
                      verifyBg(reservation.source)
                    }`}
                    data-tip={`${reservation.code} - ${
                      reservation.guest
                    } - ${getFormattedDate(
                      reservation.check_in,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )} -> ${getFormattedDate(
                      reservation.check_out,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )} - Check-In: ${verifyStatus(
                      reservation.checkin_status
                    )} ${
                      reservation.guests_num
                        ? `- ${t("resv.guestCount")}: ${reservation.guests_num}`
                        : ""
                    } ${
                      reservation.tot_price
                        ? `- ${t(
                            "reservationNotes.price"
                          )}: ${reservation.tot_price.toFixed(2)}€`
                        : ``
                    }`}
                    data-for={`soclose2${reservation.code}`}
                  >
                    <div
                      className={`w-full flex align-middle items-center truncate ${
                        IsSafari() ? "" : "transform skew-x-20"
                      }`}
                    >
                      {logoImageComp(reservation?.source)}
                      {`${reservation.code}
                    -
                    ${reservation.guest}
                    -
                    ${getFormattedDate(
                      reservation.check_in,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )}
                    -
                    ${getFormattedDate(
                      reservation.check_out,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )} - Check-In: ${verifyStatus(
                        reservation.checkin_status
                      )} ${
                        reservation.guests_num
                          ? `- ${t("resv.guestCount")}: ${
                              reservation.guests_num
                            }`
                          : ""
                      }`}
                      {reservation.tot_price
                        ? `- ${t(
                            "reservationNotes.price"
                          )}: ${reservation.tot_price.toFixed(2)}€`
                        : ``}
                    </div>
                  </motion.li>
                  <ReactTooltip
                    id={`soclose2${reservation.code}`}
                    getContent={(dataTip) => <span>{dataTip}</span>}
                    effect="float"
                  />
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
      {/* view ratelist */}
      {tempObj && (
        <div
          className={`w-full flex align-middle justify-center cursor-pointer border-2 ${
            viewMore
              ? "bg-gray-300 hover:bg-gray-200"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
          onClick={() => {
            setViewMore((prev) => !prev);
          }}
        >
          <p className="md:text-sm text-xs">{t("channelManager.rate_plan.rateList")}</p>{" "}
          {viewMore ? (
            <Icon name="arrow-up" size="10px" />
          ) : (
            <Icon name="arrow-down" size="10px" />
          )}
        </div>
      )}
      {/* per_person & per_room */}
      {tempObj &&
        viewMore &&
        Object.keys(tempObj)?.map((key, keyIdx) => {
          if (key === "per_person" || key === "per_room") {
            const keyPerPerson = key === "per_person";
            return tempObj[key as keyof typeof tempObj]?.map(
              (keyDi: any, idxOne: number) =>
                Object.keys(keyDi).map((desc: any, idi: number) => {
                  if (keyPerPerson) {
                    return (
                      <div
                        className={`hover:bg-white flex md:text-sm text-xs ${
                          keyDi[desc as keyof typeof keyDi].is_primary
                            ? ""
                            : viewAllPerPerson
                            ? ""
                            : "hidden"
                        }`}
                        key={idi}
                        ref={(el) =>
                          (containerRefPrice.current[idi + idxOne + keyIdx] =
                            el)
                        }
                        onMouseDown={(e) => handleMouseDownRate(e)}
                        onMouseMove={(e) => handleMouseMoveRate(e)}
                        onMouseUp={(e) => handleMouseUpRate()}
                        style={{
                          height: "50px",
                        }}
                      >
                        <div
                          className={
                            "bg-gray-100 z-50 md:text-sm text-xs md:space-x-2 font-medium text-gray-600 flex items-start md:w-52 w-20 border-b md:py-2 border-r truncate md:px-5 flex-col"
                          }
                        >
                          <div className="w-full">
                            <div className="flex">
                              {keyDi[desc as keyof typeof keyDi].is_primary && (
                                <Icon
                                  name={
                                    viewAllPerPerson ? "arrow-up" : "arrow-down"
                                  }
                                  size="16px"
                                  onClick={() =>
                                    setViewAllPerPerson((prev) => !prev)
                                  }
                                />
                              )}
                              • {keyDi[desc as keyof typeof keyDi]?.title}
                            </div>
                            <div
                              className="flex justify-end align-middle items-end"
                              data-tip={`occupancy ${
                                keyDi[desc as keyof typeof keyDi].is_primary
                                  ? "(primary)"
                                  : "(derived)"
                              }`}
                            >
                              <Icon name="occupancy" size="16px" />
                              {keyDi[desc as keyof typeof keyDi]?.occupancy}
                            </div>
                          </div>
                          <ReactTooltip effect="float" />
                        </div>
                        <div className="flex-1 border-b flex relative place-content-center">
                          <div
                            className="absolute w-full h-full top-0 left-0 flex"
                            key={desc + idi}
                          >
                            {days.map((day, index) => {
                              const specificDate = `${day.date.slice(
                                6,
                                10
                              )}-${day.date.slice(3, 5)}-${day.date.slice(
                                0,
                                2
                              )}`;
                              const infoDate =
                                keyDi[desc as keyof typeof keyDi]?.date[
                                  specificDate
                                ];
                              const editable =
                                keyDi[desc as keyof typeof keyDi].editable;
                              const isSelected = selectedDivsPrice.find(
                                (idsPrice: any) =>
                                  idsPrice.id === desc + specificDate
                              );
                              const isSelectedTwo =
                                selectedDivsPrice.length > 0 &&
                                dayjs(specificDate).isAfter(
                                  selectedDivsPrice[0].date
                                ) &&
                                dayjs(specificDate).isBefore(
                                  selectedDivsPrice[
                                    selectedDivsPrice.length - 1
                                  ].date
                                ) &&
                                keyDi[desc as keyof typeof keyDi].title ===
                                  selectedDivsPrice[
                                    selectedDivsPrice.length - 1
                                  ].title;
                              return (
                                <div
                                  onClick={() => {
                                    if (editable) {
                                      dispatch(
                                        setModalValueOverride({
                                          display: true,
                                          type: key,
                                          roomType: local.name,
                                          ratePlan:
                                            keyDi[desc as keyof typeof keyDi]
                                              ?.title,
                                          ratePlanId: desc,
                                          restriction: "rate",
                                          currentPrice: parseFloat(
                                            infoDate?.rate
                                          ),
                                          priceValue: parseFloat(
                                            infoDate?.rate
                                          ),
                                          availabilityValue:
                                            tempObj[key][specificDate],
                                          dateRange: {
                                            from: specificDate,
                                            to: specificDate,
                                          },
                                          roomTypeId: tempObj?.room_type_id,
                                          propertyId: tempObj?.property_id,
                                          stopSellValue: infoDate?.stop_sell,
                                          closedToArrivalValue:
                                            infoDate?.closed_to_arrival,
                                          closedToDepartureValue:
                                            infoDate?.closed_to_departure,
                                          minStayValue:
                                            infoDate?.min_stay_arrival
                                              ? infoDate?.min_stay_arrival
                                              : infoDate?.min_stay_through,
                                          maxStayValue: infoDate?.max_stay,
                                        })
                                      );
                                      setIsDragging(false);
                                      setIsDraggingDays(false);
                                      setIsDraggingPrice(false);
                                    }
                                  }}
                                  key={`${idi}${index}`}
                                  className={`flex-1 h-full border-r cursor-pointer text-center align-middle w-full truncate ${
                                    infoDate?.availability &&
                                    !infoDate?.stop_sell
                                      ? editable
                                        ? isSelected || isSelectedTwo
                                          ? "bg-cyan-200"
                                          : "hover:bg-gray-100"
                                        : "text-gray-400 cursor-not-allowed"
                                      : editable
                                      ? isSelected || isSelectedTwo
                                        ? "bg-cyan-200"
                                        : "bg-red-300 hover:bg-red-400"
                                      : "bg-red-300 hover:bg-red-400 text-gray-400 cursor-not-allowed"
                                  }`}
                                >
                                  {infoDate?.rate && parseFloat(infoDate?.rate)}
                                  <span className="text-transparent hidden">
                                    {`|||${specificDate}|||${
                                      keyDi[desc as keyof typeof keyDi]?.title
                                    }|||${desc}|||${infoDate?.stop_sell}|||${
                                      infoDate?.closed_to_arrival
                                    }|||${infoDate?.closed_to_departure}|||${
                                      infoDate?.min_stay_arrival
                                        ? infoDate?.min_stay_arrival
                                        : infoDate?.min_stay_through
                                    }|||${
                                      infoDate?.max_stay
                                    }|||${editable}|||${key}`}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div
                      className="hover:bg-white flex md:text-sm text-xs"
                      key={idi}
                      ref={(el) =>
                        (containerRefPrice.current[idi + idxOne + keyIdx] = el)
                      }
                      onMouseDown={(e) => handleMouseDownRate(e)}
                      onMouseMove={(e) => handleMouseMoveRate(e)}
                      onMouseUp={(e) => handleMouseUpRate()}
                      style={{
                        height: "50px",
                      }}
                    >
                      <div
                        className={
                          "bg-gray-100 z-50 md:text-sm text-xs md:space-x-2 font-medium text-gray-600 flex items-start md:w-52 w-20 border-b md:py-2 border-r truncate md:px-5 flex-col"
                        }
                      >
                        <div className="w-full">
                          <div className="md:text-sm text-xs whitespace-nowrap">
                            • {keyDi[desc as keyof typeof keyDi]?.title}
                          </div>
                          <div
                            className="flex justify-end align-middle items-end"
                            data-tip={`occupancy ${
                              keyDi[desc as keyof typeof keyDi].is_primary
                                ? "(primary)"
                                : "(derived)"
                            }`}
                          >
                            <Icon name="occupancy" size="16px" />
                            {keyDi[desc as keyof typeof keyDi]?.occupancy}
                          </div>
                        </div>
                        <ReactTooltip effect="float" />
                      </div>
                      <div className="flex-1 border-b flex relative place-content-center">
                        <div
                          className="absolute w-full h-full top-0 left-0 flex"
                          key={desc + idi}
                        >
                          {days.map((day, index) => {
                            const specificDate = `${day.date.slice(
                              6,
                              10
                            )}-${day.date.slice(3, 5)}-${day.date.slice(0, 2)}`;
                            const infoDate =
                              keyDi[desc as keyof typeof keyDi]?.date[
                                specificDate
                              ];
                            const editable =
                              keyDi[desc as keyof typeof keyDi].editable;
                            const isSelected = selectedDivsPrice.find(
                              (idsPrice: any) =>
                                idsPrice.id === desc + specificDate
                            );
                            const isSelectedTwo =
                              selectedDivsPrice.length > 0 &&
                              dayjs(specificDate).isAfter(
                                selectedDivsPrice[0].date
                              ) &&
                              dayjs(specificDate).isBefore(
                                selectedDivsPrice[selectedDivsPrice.length - 1]
                                  .date
                              ) &&
                              keyDi[desc as keyof typeof keyDi].title ===
                                selectedDivsPrice[selectedDivsPrice.length - 1]
                                  .title;
                            return (
                              <div
                                onClick={() => {
                                  if (editable) {
                                    dispatch(
                                      setModalValueOverride({
                                        display: true,
                                        type: key,
                                        roomType: local.name,
                                        ratePlan:
                                          keyDi[desc as keyof typeof keyDi]
                                            ?.title,
                                        ratePlanId: desc,
                                        restriction: "rate",
                                        currentPrice: parseFloat(
                                          infoDate?.rate
                                        ),
                                        priceValue: parseFloat(infoDate?.rate),
                                        availabilityValue:
                                          tempObj[key][specificDate],
                                        dateRange: {
                                          from: specificDate,
                                          to: specificDate,
                                        },
                                        roomTypeId: tempObj?.room_type_id,
                                        propertyId: tempObj?.property_id,
                                        stopSellValue: infoDate?.stop_sell,
                                        closedToArrivalValue:
                                          infoDate?.closed_to_arrival,
                                        closedToDepartureValue:
                                          infoDate?.closed_to_departure,
                                        minStayValue: infoDate?.min_stay_arrival
                                          ? infoDate?.min_stay_arrival
                                          : infoDate?.min_stay_through,
                                        maxStayValue: infoDate?.max_stay,
                                      })
                                    );
                                    setIsDragging(false);
                                    setIsDraggingDays(false);
                                    setIsDraggingPrice(false);
                                  }
                                }}
                                key={`${idi}${index}`}
                                className={`flex-1 h-full border-r cursor-pointer text-center align-middle w-full truncate ${
                                  infoDate?.availability && !infoDate?.stop_sell
                                    ? editable
                                      ? isSelected || isSelectedTwo
                                        ? "bg-cyan-200"
                                        : "hover:bg-gray-100"
                                      : "text-gray-400 cursor-not-allowed"
                                    : editable
                                    ? isSelected || isSelectedTwo
                                      ? "bg-cyan-200"
                                      : "bg-red-300 hover:bg-red-400"
                                    : "bg-red-300 hover:bg-red-400 text-gray-400 cursor-not-allowed"
                                }`}
                              >
                                {infoDate?.rate && parseFloat(infoDate?.rate)}
                                <span className="text-transparent hidden">
                                  {`|||${specificDate}|||${
                                    keyDi[desc as keyof typeof keyDi]?.title
                                  }|||${desc}|||${infoDate?.stop_sell}|||${
                                    infoDate?.closed_to_arrival
                                  }|||${infoDate?.closed_to_departure}|||${
                                    infoDate?.min_stay_arrival
                                      ? infoDate?.min_stay_arrival
                                      : infoDate?.min_stay_through
                                  }|||${
                                    infoDate?.max_stay
                                  }|||${editable}|||${key}`}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })
            );
          }
          return false;
        })}
    </div>
  );
}
