import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarDay, Local, ReservationTable } from "../../types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../shared/utils/formatting";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import { setModalValueOverride } from "../../features/channelManager/channelManagerSlice";
import Icon from "../Icon";
import i18n from "../../langs";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import BaseTable from "../BaseTable";
import Button from "../Button";
import { useHistory } from "react-router-dom";

type Props = {
  local: Local;
  days: CalendarDay[];
  startDate: string;
  reservations: ReservationTable[];
  onView: (r: ReservationTable) => void;
  selectStart: any;
  setStartDate: any;
  setEndDate: any;
  objChannel: any;
  refetchRestrictions: any;
};

const SingleAptCalendar = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const {
    reservations,
    startDate,
    setStartDate,
    setEndDate,
    onView,
    objChannel,
    selectStart,
    refetchRestrictions,
  } = props;
  const [initEvent, setInitEvent] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<"availability" | "price">("availability");
  const [modalData, setModalData] = useState({
    display: false,
    rateList: [],
  });
  const calendarRef = useRef<any>(null);

  const goPrevMonth = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
  };
  const goNextMonth = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
  };
  /* const restartDay = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
  }; */

  const verifyStatus = (status: string) => {
    switch (status) {
      case "WAIT":
        return t("resv.notExecuted");
      case "NONEED":
        return t("resv.notNecessary");
      case "OK":
        return t("resv.confirmed");
      case "PEND":
        return t("resv.waitingConfirmation");
      default:
        break;
    }
  };

  const renderEventContent = (eventInfo: any) => {
    const reservation = eventInfo.event.extendedProps.resvData;
    const verifyBg = (status: string) => {
      switch (status) {
        case "WAIT":
          return "bg-red-200 text-gray-900 border-red-700 ring-red-700";
        case "NONEED":
          return "bg-gray-100 text-gray-900 border-gray-700 ring-gray-700";
        case "OK":
          return "bg-green-100 text-gray-900 border-green-700 ring-green-700";
        case "PEND":
          return "bg-yellow-300 text-gray-900 border-yellow-700 ring-yellow-700";
        case "BOOKING":
          return " text-gray-900 border-blue-700 ring-blue-700";
        case "AIRBNB":
          return " text-gray-900 border-red-700 ring-red-700";
        default:
          break;
      }
    };
    if (reservation) {
      if (reservation.local_key) {
        return (
          <>
            <div
              className={`ring-white ring transform skew-x-20Minus p-0.5  ${verifyBg(
                reservation.checkin_status
              )} ${
                (reservation?.source === "BOOKING" ||
                  reservation?.source === "AIRBNB") &&
                verifyBg(reservation.source)
              }`}
              data-tip={`${reservation.source} - ${getFormattedDate(
                reservation.check_in,
                "YYYY-MM-DD",
                "DD-MM-YYYY"
              )} -> ${getFormattedDate(
                reservation.check_out,
                "YYYY-MM-DD",
                "DD-MM-YYYY"
              )} ${
                reservation.guests_num
                  ? `- ${t("resv.guestCount")}: ${reservation.guests_num}`
                  : ""
              }${
                reservation.tot_price
                  ? ` - ${t(
                      "reservationNotes.price"
                    )}: ${reservation.tot_price.toFixed(2)}€`
                  : ``
              }`}
              onClick={() => {
                if (onView) onView(reservation);
              }}
            >
              <p className="truncate">
                {`${reservation.source} - ${getFormattedDate(
                  reservation.check_in,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )} -> ${getFormattedDate(
                  reservation.check_out,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )} ${
                  reservation.guests_num
                    ? `- ${t("resv.guestCount")}: ${reservation.guests_num}`
                    : ""
                }`}
                {reservation.tot_price
                  ? ` - ${t(
                      "reservationNotes.price"
                    )}: ${reservation.tot_price.toFixed(2)}€`
                  : ``}
              </p>
            </div>
            <ReactTooltip />
          </>
        );
      }
      return (
        <>
          <div
            className={`mx-1 my-1 ring transform skew-x-20Minus p-0.5  ${verifyBg(
              reservation.checkin_status
            )} ${
              (reservation?.source === "BOOKING" ||
                reservation?.source === "AIRBNB") &&
              verifyBg(reservation.source)
            }`}
            data-tip={`${reservation.code} - ${
              reservation.guest
            } - ${getFormattedDate(
              reservation.check_in,
              "YYYY-MM-DD",
              "DD-MM-YYYY"
            )} -> ${getFormattedDate(
              reservation.check_out,
              "YYYY-MM-DD",
              "DD-MM-YYYY"
            )} - Check-In: ${verifyStatus(reservation.checkin_status)} ${
              reservation.guests_num
                ? `- ${t("resv.guestCount")}: ${reservation.guests_num}`
                : ""
            }${
              reservation.tot_price
                ? ` - ${t(
                    "reservationNotes.price"
                  )}: ${reservation.tot_price.toFixed(2)}€`
                : ``
            }`}
            onClick={() => {
              if (onView) onView(reservation);
            }}
          >
            <div
              className={
                "w-full flex align-middle items-center transform skew-x-20"
              }
            >
              {logoImageComp(reservation?.source)}
              <p className="truncate">
                {`${reservation.code} - ${
                  reservation.guest
                } - ${getFormattedDate(
                  reservation.check_in,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )} -> ${getFormattedDate(
                  reservation.check_out,
                  "YYYY-MM-DD",
                  "DD-MM-YYYY"
                )} - Check-In: ${verifyStatus(reservation.checkin_status)} ${
                  reservation.guests_num
                    ? `- ${t("resv.guestCount")}: ${reservation.guests_num}`
                    : ""
                }`}
                {reservation.tot_price
                  ? ` - ${t(
                      "reservationNotes.price"
                    )}: ${reservation.tot_price.toFixed(2)}€`
                  : ``}
              </p>
            </div>
          </div>
          <ReactTooltip />
        </>
      );
    }
    const eventData = eventInfo.event.extendedProps;
    if (view === "price") {
      if (eventData.type === "price") {
        return (
          <div
            className={`bg-gray-900 text-white cursor-pointer max-w-max rounded-full p-1 flex text-xs truncate font-bold`}
            onClick={() => {
              setModalData({
                display: true,
                rateList: eventData.rate,
              });
            }}
          >
            {t("reservationNotes.price")}
            <Icon name="pencil" size="10px" />
          </div>
        );
      }
    } else {
      if (eventData.type !== "price") {
        return (
          <div
            className={`${
              eventData && eventData?.availabilityValue
                ? "bg-gray-300 text-black"
                : "bg-gray-900 text-white"
            }  cursor-pointer max-w-max rounded-full p-1 flex text-xs truncate font-bold`}
            onClick={() => {
              dispatch(
                setModalValueOverride({
                  display: true,
                  type: "availability",
                  roomType: selectStart[0]?.label,
                  ratePlan: null,
                  restriction: "availability",
                  currentPrice: 0,
                  priceValue: 0,
                  availabilityValue: eventData?.availabilityValue,
                  dateRange: {
                    from: eventData?.availabilityDay,
                    to: eventData?.availabilityDay,
                  },
                  roomTypeId: eventData?.roomTypeId,
                  propertyId: eventData?.propertyId,
                  stopSellValue: false,
                  closedToArrivalValue: false,
                  closedToDepartureValue: false,
                  ratePlanId: "",
                  minStayValue: 0,
                  maxStayValue: 0,
                })
              );
              setModalData({
                display: false,
                rateList: [],
              });
            }}
          >
            {eventData && eventData?.availabilityValue ? "AVL" : "NOT AVL"}{" "}
            <Icon name="pencil" size="10px" />
          </div>
        );
      }
    }
  };

  const logoImageComp = (source: string) => {
    switch (source) {
      case "AIRBNB":
        return (
          <img
            className={"w-5 h-5 rounded-full mr-1"}
            src="/images/logos/airbnbsmall.png"
            alt=""
          />
        );
      case "BOOKING":
        return (
          <img
            className={"w-5 h-5 rounded-full mr-1"}
            src="/images/logos/bookingsmall.png"
            alt=""
          />
        );
      default:
        break;
    }
  };
  const getColums = () => {
    const cols = [
      {
        Header: t<string>("addService.title"),
        accessor: "title",
      },
      {
        Header: t<string>("tools.type"),
        accessor: "type",
      },
      {
        Header: t<string>("channelManager.rate_plan.rate"),
        accessor: (row: any) => (
          <>
            {row.rate} {row.currency}
          </>
        ),
      },
      {
        Header: "occupancy",
        accessor: "occupancy",
      },
      {
        Header: "is_primary",
        accessor: (row: any) => <>{row.is_primary ? "True" : "False"}</>,
      },
      {
        Header: t("general.edit"),
        accessor: (row: any) => (
          <>
            <Button
              icon="pencil"
              iconSize="16px"
              disabled={!row.editable}
              onClick={() => {
                dispatch(
                  setModalValueOverride({
                    display: true,
                    type: row.type,
                    roomType: selectStart[0]?.label,
                    ratePlan: row?.title,
                    ratePlanId: row?.ratePlanId,
                    restriction: "rate",
                    currentPrice: parseFloat(row?.rate),
                    priceValue: parseFloat(row?.rate),
                    availabilityValue: row?.availability,
                    dateRange: {
                      from: row?.startDate,
                      to: row?.endDate,
                    },
                    roomTypeId: row?.roomTypeId,
                    propertyId: row?.propertyId,
                    stopSellValue: row?.stop_sell,
                    closedToArrivalValue: row?.closed_to_arrival,
                    closedToDepartureValue: row?.closed_to_departure,
                    minStayValue: row?.min_stay_arrival
                      ? row?.min_stay_arrival
                      : row?.min_stay_through,
                    maxStayValue: row?.max_stay,
                  })
                );
                setModalData({
                  display: false,
                  rateList: [],
                });
              }}
            />
          </>
        ),
      },
    ];
    return cols;
  };
  useEffect(() => {
    const tempArray: any = [];
    const localData = objChannel?.results[selectStart[0]?.value];
    const availability = localData?.availability;
    setLoading(true);

    if (localData && view === "price") {
      Object.keys(localData)?.map((key) => {
        if (key === "per_person" || key === "per_room") {
          localData[key as keyof typeof localData]?.map((keyDi: any) => {
            Object.keys(keyDi).map((desc: any) => {
              const dateObj = keyDi[desc as keyof typeof keyDi]?.date;
              Object.keys(dateObj)?.map((day: any) => {
                const findRate = tempArray.find((obj: any) => obj.id === day);
                if (findRate) {
                  findRate.rate.push({
                    type: key,
                    title: keyDi[desc as keyof typeof keyDi]?.title,
                    currency: keyDi[desc as keyof typeof keyDi]?.currency,
                    editable: keyDi[desc as keyof typeof keyDi]?.editable,
                    is_primary: keyDi[desc as keyof typeof keyDi]?.is_primary,
                    occupancy: keyDi[desc as keyof typeof keyDi]?.occupancy,
                    roomTypeId: localData.room_type_id,
                    propertyId: localData.property_id,
                    ratePlanId: desc,
                    startDate: day,
                    endDate: day,
                    ...dateObj[day],
                  });
                } else {
                  tempArray.push({
                    id: day,
                    start: day,
                    end: day,
                    type: "price",
                    display: "background",
                    backgroundColor: "white",
                    rate: [
                      {
                        type: key,
                        title: keyDi[desc as keyof typeof keyDi]?.title,
                        currency: keyDi[desc as keyof typeof keyDi]?.currency,
                        editable: keyDi[desc as keyof typeof keyDi]?.editable,
                        is_primary:
                          keyDi[desc as keyof typeof keyDi]?.is_primary,
                        occupancy: keyDi[desc as keyof typeof keyDi]?.occupancy,
                        roomTypeId: localData.room_type_id,
                        propertyId: localData.property_id,
                        ratePlanId: desc,
                        startDate: day,
                        endDate: day,
                        ...dateObj[day],
                      },
                    ],
                  });
                }
                return false;
              });
              return false;
            });
            return false;
          });
        }
        return false;
      });
    }
    reservations.forEach((resv) => {
      tempArray.push({
        id: resv.code,
        title: resv.guest,
        resvData: resv,
        start: dayjs(resv.check_in).format("YYYY-MM-DD"),
        end: dayjs(resv.check_out).format("YYYY-MM-DD"),
      });
    });
    if (availability && view === "availability") {
      Object.keys(availability)?.forEach((key) => {
        if (key && availability[key]) {
          tempArray.push({
            display: "background",
            backgroundColor: "white",
            start: key,
            end: key,
            name: localData.name,
            availabilityValue: availability[key],
            roomTypeId: localData.room_type_id,
            propertyId: localData.property_id,
            availabilityDay: key,
          });
        } else if (key) {
          tempArray.push({
            display: "background",
            start: key,
            end: key,
            name: localData.name,
            availabilityValue: 0,
            roomTypeId: localData.room_type_id,
            propertyId: localData.property_id,
            backgroundColor: "gray",
            availabilityDay: key,
          });
        }
      });
    }
    setInitEvent(tempArray);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations, refetchRestrictions, view]);

  return (
    <div className="p-5">
      {!loading && (
        <FullCalendar
          locale={i18n.language}
          ref={calendarRef}
          customButtons={{
            prev: {
              click: () => {
                const newDate = dayjs(startDate).subtract(1, "month");
                setStartDate(
                  dayjs(newDate).startOf("month").format("YYYY-MM-DD")
                );
                setEndDate(
                  dayjs(newDate)
                    .add(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD")
                );
                goPrevMonth();
              },
            },
            next: {
              click: () => {
                const newDate = dayjs(startDate).add(1, "month");
                setStartDate(
                  dayjs(newDate).startOf("month").format("YYYY-MM-DD")
                );
                setEndDate(
                  dayjs(newDate)
                    .add(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD")
                );
                goNextMonth();
              },
            },
            custom2: {
              text:
                objChannel?.results[selectStart[0]?.value] &&
                `${t("channelManager.rate_plan.priceSettings")} / ${t(
                  "apartment.availability"
                )}`,
              click: () => {
                if (view === "price") {
                  setView("availability");
                } else {
                  setView("price");
                }
              },
            },
          }}
          firstDay={1}
          weekNumberCalculation={"ISO"}
          headerToolbar={{
            start: "prev",
            center: "title",
            end: "custom2 next",
          }}
          plugins={[dayGridPlugin, interactionPlugin]}
          selectable={view === "availability"}
          select={(info: any) => {
            if (view === "availability") {
              const localData = objChannel?.results[selectStart[0]?.value];
              const dateFrom = dayjs(info.startStr).format("YYYY-MM-DD");
              const dateTo = dayjs(info.endStr)
                .add(-1, "day")
                .format("YYYY-MM-DD");
              if (localData) {
                const avl = localData?.availability[dateFrom];
                dispatch(
                  setModalValueOverride({
                    display: true,
                    type: "availability",
                    roomType: selectStart[0]?.label,
                    ratePlan: null,
                    restriction: "availability",
                    currentPrice: 0,
                    priceValue: 0,
                    availabilityValue: avl,
                    dateRange: {
                      from: dateFrom,
                      to: dateTo,
                    },
                    roomTypeId: localData.room_type_id,
                    propertyId: localData.property_id,
                    stopSellValue: false,
                    closedToArrivalValue: false,
                    closedToDepartureValue: false,
                    ratePlanId: "",
                    minStayValue: 0,
                    maxStayValue: 0,
                  })
                );
                setModalData({
                  display: false,
                  rateList: [],
                });
              } else {
                history.push(
                  "/reservations/new?from=" +
                    dateFrom +
                    "&to=" +
                    dateTo +
                    "&local=" +
                    selectStart[0]?.value
                );
              }
            }
          }}
          selectMirror={true}
          dayMaxEvents={true}
          initialView="dayGridMonth"
          initialEvents={initEvent as any}
          events={initEvent}
          eventContent={renderEventContent}
          showNonCurrentDates={false}
        />
      )}
      <Modal visible={modalData.display} isResvDocs>
        <ModalCard className="w-full md:h-auto h-screen">
          <ModalSection>
            <div className={`p-4`}>
              <BaseTable
                identifierKey={t("channelManager.rate_plan.priceSettings")}
                title={t("channelManager.rate_plan.priceSettings")}
                data={modalData.rateList}
                totalRows={modalData.rateList ? modalData.rateList.length : 0}
                displayRows={100}
                filters={[]}
                columns={getColums()}
                onEdit={(id) => {}}
              />
            </div>
          </ModalSection>
          <ModalActions
            isLoading={false}
            onClose={() => {
              // resetValueOverride();
              setModalData({
                display: false,
                rateList: [],
              });
            }}
          />
        </ModalCard>
      </Modal>
    </div>
  );
};

export default SingleAptCalendar;
