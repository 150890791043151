import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Integration, IntegrationApartment } from '../../types'
import Icon from '../Icon'

type IntegrationListItemProps = {
  integration: Integration;
  disable: boolean;
}

function checkFeature (connection: any, key:any) : string {
  if (connection && connection.links && connection.links.length > 0) {
    if (connection.links[0][key]) return 'text-cyan-600'
    return 'opacity-50'
  }
  return 'opacity-50'
}

function getLinkName (connection: IntegrationApartment) {
  if (connection && connection.links && connection.links.length > 0) {
    return ' ↔ ' + connection.links[0]?.local?.name
  }
  return '↔ --'
}

export default function IntegrationListItem ({
  integration,
  disable
} : IntegrationListItemProps) {

  const history = useHistory()

  const { t } = useTranslation()

  return (
    <div
      onClick={() => {if(!disable) history.push('/integrations/' + integration.integr_type + '/' + integration.id)}}
      className={'bg-white border cst-shadow rounded-2xl w-full'}>
      <div className="px-4 py-4">
        <div className="flex flex-col md:flex-col justify-between -mx-2 font-medium lg:items-center w-full">
          <div className={'px-2 w-full'}>
            Vikey ({integration.id}) {'->'} {integration.integr_type} {integration.name}
            {integration.block  &&
              <div className={`text-xs mt-1 text-red-600 bg-red-200 px-2 py-1 rounded-2xl`}>
              BLOCK
            </div>
            }
            <div className={`text-xs text-gray-600 mt-1 ${(integration.status === 'ACTIVE') ? 'text-green-600' : 'text-red-600'}`}>
              {integration.status}
            </div>
          </div>
          <div className={'px-2'}>
            <div className="bg-gray-200 rounded-2xl text-xs px-2 py-1">
              {integration.typology}
            </div>
          </div>
        </div>
      </div>

      {
        integration.extapts.map((connection, index) => (
          connection.typology === integration.typology &&
          <div className={'border-t hover:bg-gray-100 cursor-pointer transition'} key={index}>
            <div className={'justify-between flex flex-col md:flex-row space-y-2 md:space-y-0 flex-wrap px-4 py-2 -mx-2'}>
              <div className={'flex-1 px-2 text-sm truncate'} data-tip={connection.name + getLinkName(connection)}>
                {connection.name} {getLinkName(connection)}
              </div>

              <div className={'flex-1 px-2'}>
                {
                  (connection.links.length > 0) ?
                  <div className="flex flex-1 flex-wrap -mx-1">
                    <div data-tip={'Email'} className={checkFeature(connection, 'integr_chk_mail') + ' px-1'}>
                      <Icon name={'mail'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'Mail Guest'} className={checkFeature(connection, 'integr_notify_mail_guest_active') + ' px-1'}>
                      <Icon name={'mail'} size={'15px'}></Icon>
                    </div>
                    <div data-tip={'Creation'} className={checkFeature(connection, 'integr_notify_now') + ' px-1'}>
                      <Icon name={'bell'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_notify_1days') + ' px-1'} data-tip={'Remind 1 day'}>
                      <Icon name={'bell'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_notify_3days') + ' px-1'} data-tip={'Remind 3 days'}>
                      <Icon name={'bell'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_notify_7days') + ' px-1'} data-tip={'Remind 7 days'}>
                      <Icon name={'bell'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_notify_done_1days') + ' px-1'} data-tip={'Check-in done 1 day'}>
                      <Icon name={'bell'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_sms') + ' px-1'} data-tip={'SMS remind'}>
                      <Icon name={'chat-alt'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_documents') + ' px-1'} data-tip={'IDs'}>
                      <Icon name={'identification'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_photo') + ' px-1'} data-tip={'Booker photo'}>
                      <Icon name={'camera'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_photo_all') + ' px-1'} data-tip={'Guest(s) photo'}>
                      <Icon name={'camera'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_contract') + ' px-1'} data-tip={'Contract'}>
                      <Icon name={'document-text'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_otp') + ' px-1'} data-tip={'OTP'}>
                      <Icon name={'finger-print'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_invoice') + ' px-1'} data-tip={'Invoice'}>
                      <Icon name={'receipt-tax'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_citytax') + ' px-1'} data-tip={'City tax'}>
                      <Icon name={'scale'} size={'15px'}></Icon>
                    </div>
                    <div className={checkFeature(connection, 'integr_chk_paystay') + ' px-1'} data-tip={'Stay payment'}>
                      <Icon name={'credit-card'} size={'15px'}></Icon>
                    </div>
                  </div>
                  :
                  <div className={'text-sm'}>
                    {t('integrationListItem.noConnections')}
                  </div>
                }
              </div>
            </div>
          </div>
        ))
      }
      {
        integration.extapts.length === 100 && <div className={'border-t hover:bg-gray-100 cursor-pointer transition'}>
        <div className={'justify-between flex flex-col md:flex-row space-y-2 md:space-y-0 flex-wrap px-4 py-2 -mx-2'}>
          <div className={'flex-1 px-2'}>
              <div className={'text-sm'}  data-tip={'Others'}>
                ...
              </div>
          </div>
        </div>
      </div>
      }
      <ReactTooltip></ReactTooltip>
    </div>
  )

}