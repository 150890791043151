import React, { useState } from "react";
import classNames from "classnames";
import { useMutation } from "react-query";
import DropdownMenu from "../DropdownMenu";
import Button from "../Button";
import { translateSingleMessage } from "../../shared/queries";
import { Dictionary } from "@reduxjs/toolkit";
import CircleSpinner from "../CircleSpinner";
import { MessageChannexAttachments } from "../../types";
import Icon from "../Icon";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface MessageBubbleProps {
  message: string;
  message_id: string;
  type: string;
  sent_on: Date;
  profile_lang: string | undefined;
  translations: Dictionary<any>;
  attachments: Array<MessageChannexAttachments>;
}

function extractFileName(url: string) {
  try {
    const pathSegments = new URL(url).pathname.split("/");
    const fileName = pathSegments[pathSegments.length - 1];
    return fileName || "unknown";
  } catch (error) {
    console.error("Invalid URL provided:", error);
    return null;
  }
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  message_id,
  type,
  profile_lang,
  sent_on,
  translations: initialTranslations,
  attachments,
}) => {
  const { t } = useTranslation();

  const [translated, setTranslated] = useState(false);
  const [localTranslations, setLocalTranslations] = useState(initialTranslations);

  const { mutate: translateMessage, isLoading: isTranslating } = useMutation(
    () => translateSingleMessage(message_id, profile_lang),
    {
      onSuccess: (translatedMessage) => {
        if (translatedMessage?.translations) {
          setLocalTranslations((prev) => ({
            ...prev,
            ...translatedMessage.translations,
          }));
        }
        setTranslated(true);
      },
      onError: (error:Error) => {
        toast.error(error.message ? t(error.message) : t("general.requestError"));
      },
    }
  );

  const handleMessageTranslate = () => {
    if (profile_lang && localTranslations[profile_lang]) {
      setTranslated(!translated);
    } else {
      translateMessage();
    }
  };

  const bubbleClass = classNames(
    "p-3 rounded-lg shadow-md transition-transform duration-200",
    {
      "bg-gray-300 text-black self-start hover:shadow-lg": type === "received",
      "bg-cyan-600 text-white self-end hover:shadow-lg": type === "sent-host",
      "bg-green-500 text-white self-end hover:shadow-lg": type === "sent-bot",
    }
  );

  const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  };

  return (
    <div className={`flex flex-col ${type === "received" ? "items-start" : "items-end"} mb-2`} style={{ maxWidth: "100%" }}>
      <div className={`flex ${type === "received" ? "items-center gap-2" : "justify-end"}`} style={{ width: "100%" }}>
        <div className={bubbleClass} style={{ maxWidth: "75%", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "pre-wrap" }}>
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: translated && profile_lang && localTranslations[profile_lang]
                  ? localTranslations[profile_lang].replace(/\n/g, "<br/>")
                  : message.replace(/\n/g, "<br/>"),
              }}
            />
          )}
          {attachments && attachments.length > 0 && (
            <div className="mt-2 space-y-1 max-w-[75%]">
              {attachments.map((attachment) => (
                <div key={attachment.id} className="flex items-center space-x-2 text-sm text-blue-500 hover:underline cursor-pointer">
                  <a href={attachment.relative_path} download={extractFileName(attachment.relative_path)} rel="noreferrer noopener" target="_blank" className="flex items-center space-x-2 text-blue-500 hover:underline cursor-pointer">
                    <Icon name="clip" size="20px" />
                    <span>{extractFileName(attachment.relative_path)}</span>
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
        {type === "received" && message && (!isTranslating ? (
          <Button className="flex" color="info" icon={translated ? "translated" : "translate"} iconSize={"20px"} onClick={handleMessageTranslate} />
        ) : (
          <CircleSpinner color="primary" />
        ))}
      </div>
      <div className="text-xs mt-1 text-gray-500" style={{ maxWidth: "75%", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "pre-wrap" }}>
        {type === "sent-bot" ? "Bot " : ""}
        {formatTime(sent_on)}
      </div>
    </div>
  );
};

export default React.memo(MessageBubble);
