import PageWrapper from "../components/PageWrapper";
import CircleSpinner from "../components/CircleSpinner";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
  fetchAllModules,
  fetchSingleThread,
  notificationConfirmation,
} from "../shared/queries";
import {
  fetchMessageThreads,
  fetchMessages,
  sendMessage,
  pinChat,
  archiveChat,
  muteChat,
  readSingleThread,
} from "../shared/queries";
import ProgressBar from "../components/ProgressBar";
import { motion } from "framer-motion";
import socketManagerV2 from "../shared/utils/socketv2";
import { RootState } from "../reducers";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMemo } from "react";

import Button from "../components/Button";

import MessageThread from "../components/messaging/MessageThread";
import MessageBubble from "../components/messaging/MessageBubble";
import ReservationDetail from "../components/calendar/ReservationDetail";
import { Thread, Message } from "../types";
import { mapMessage } from "../shared/utils/mappers";
import MessageInput from "../components/messaging/MessageInput";
import { moduleId } from "../shared/utils/config";
import Modal from "../components/Modal";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import ModalActions from "../components/ModalAction";
import { toast } from "react-toastify";

export default function Messaging() {
  const { profile } = useSelector((state: RootState) => state.login);

  const history = useHistory();
  const { t } = useTranslation();
  const { token } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [selectedChatID, setSelectedChatID] = useState<string | null>(null);
  const [currentResv, setCurrentResv] = useState<string | "">("");
  const [currentThread, setCurrentThread] = useState<Thread | null>(null);

  const [filter, setFilter] = useState<"all" | "unread" | "archived">("all");
  const [threads, setThreads] = useState<Thread[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentArchivedPage, setCurrentArchivedPage] = useState(1);
  const [currentMessagePage, setCurrentMessagePage] = useState(1);

  const [hasMore, setHasMore] = useState(true);
  const [hasMoreArchived, setHasMoreArchived] = useState(true);

  const [isLoadingMessages, setIsLoadingMessages] = useState(false);

  const messagesEnd = useRef<null | HTMLDivElement>(null);
  const [groupedMessages, setGroupedMessages] = useState<{
    [date: string]: Message[];
  }>({});
  const [sortedDates, setSortedDates] = useState<string[]>([]);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [disableSendMessage, setDisableSendMessage] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);


  const [displayMod, setDisplayMod] = useState({
    display: false,
    moduleName: "",
  });
  const threadsRef = useRef(threads);
  

  const pushMessageOnChat = (newMessage: Message, selectedChat: string) => {
    if (selectedChat && newMessage) {
      const exists = messages.some(
        (msg) => msg.message_id === newMessage.message_id
      );
      if (!exists) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setIsSendingMessage(false);
        setDisableSendMessage(false);
      }
    }
  };

  const pushNewMessage = useCallback(
    (new_message: Message | null) => {
      if (new_message) {
        const threadId = new_message.message_thread_id;
        // console.log("selected chat id ", selectedChatID);
        if (selectedChatID === threadId) {
          // setMessages((prevMessages) => [...prevMessages, new_message]);
          pushMessageOnChat(new_message, threadId);
          setThreads((prevThreads) =>
            moveThreadToTop(prevThreads, selectedChatID, {
              last_message: new_message,
              has_been_read: true,
            })
          );
          readSingleThread(selectedChatID);
          scrollToBottom();
        } else {
          if (
            !threadsRef.current.some(
              (thread) => thread.thread_id === new_message.message_thread_id
            )
          ) {
            // console.log("FETCHING UNLOADED THREAD ", threadId);
            fetchUnloadedThread(threadId);
          } else {
            setThreads((prevThreads) =>
              moveThreadToTop(prevThreads, threadId, {
                last_message: new_message,
                has_been_read: false,
              })
            );
          }
        }
      }
    },
    [selectedChatID]
  );

  const handleWebSocketMessages = useCallback(
    (data: any) => {
      if (data.type === "message") {
        const new_message = mapMessage(JSON.parse(data.data.data));
        pushNewMessage(new_message);
      }
    },
    [pushNewMessage]
  );
  const fetchUnloadedThread = async (thread_id: string) => {
    const exists = threads.some((thread) => thread.thread_id === thread_id);
    if (!exists) {
      const thread = await fetchSingleThread(thread_id);
      setThreads((prevThreads) => [thread, ...prevThreads]);
    }
  };

  const callback = useCallback(
    (data: any) => {
      console.log("RECEIVED websocket", data);
      handleWebSocketMessages(data);
    },
    [handleWebSocketMessages]
  );

  const callbackRef = useRef(callback);
  
  const loadMoreThreads = async () => {
    // console.log("LOADING MORE MESSAGES ");

    try {
      setIsFetching(true);
      const newThreads = await fetchMessageThreads({
        page:
          filter === "all" || filter === "unread"
            ? currentPage
            : currentArchivedPage,
        filter: filter,
      });

      if (newThreads.length > 0) {
        const existingThreadIds = new Set(
          threads.map((thread) => thread.thread_id)
        );

        const uniqueNewThreads = newThreads.filter(
          (thread) => !existingThreadIds.has(thread.thread_id)
        );

        if (uniqueNewThreads.length > 0) {
          setThreads((prevThreads) => [...prevThreads, ...uniqueNewThreads]);
        } else {
          filter === "all" || filter === "unread"
            ? setHasMore(false)
            : setHasMoreArchived(false);
        }
      } else {
        filter === "all" || filter === "unread"
          ? setHasMore(false)
          : setHasMoreArchived(false);
      }
    } catch (error) {
      console.error("Error fetching threads:", error);
      filter === "all" || filter === "unread"
        ? setHasMore(false)
        : setHasMoreArchived(false);
    } finally {
      setIsFetching(false);
    }
  };
  

  const confirmToken = useMutation(() => notificationConfirmation(token), {
    onSuccess: () => {
      setResult(t("general.operationCompleted"));
    },
    onError: (error:Error) => {
      toast.error(error.message ? t(error.message) : t("general.requestError"));
    },
    onMutate: (res) => {
      setLoading(false);
    },
  });
  

  const handleFilterThreads = (filterType: "all" | "unread" | "archived") => {
    setFilter(filterType);

    if (filterType === "all" || filterType === "unread") {
      setCurrentPage(1);
      setHasMore(true);
    } else {
      setCurrentArchivedPage(1);
      setHasMoreArchived(true);
    }

    setThreads([]);
  };
  

  const handleSelectChat = async (selectedThread: Thread) => {
    setIsLoadingMessages(true);
    setIsSendingMessage(false);
    setDisableSendMessage(false);
    setIsSidebarVisible(false);

    try {
      const data = await fetchMessages(
        selectedThread.thread_id,
        currentMessagePage
      );

      setMessages(data);
      setSelectedChat(selectedThread.name);
      setSelectedChatID(selectedThread.thread_id);
      setCurrentThread(selectedThread);
      const temp_threads = [...threads];
      const read_threads = temp_threads.map((thread) =>
        thread.thread_id === selectedThread.thread_id
          ? {
              ...thread,
              has_been_read: true,
            }
          : thread
      );
      setThreads(read_threads);
      readSingleThread(selectedThread.thread_id);

      scrollToBottom();
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoadingMessages(false);
    }
  };

  const moveThreadToTop = (
    threads: Thread[],
    threadID: string | null,
    updatedThread: Partial<Thread>
  ) => {
    // console.log("MOVING THREAD TO TOP (hopefully)");
    const movedThread = threads.find((thread) => thread.thread_id === threadID);

    return [
      ...(movedThread ? [{ ...movedThread, ...updatedThread }] : []),
      ...threads.filter((thread) => thread.thread_id !== threadID),
    ];
  };
  const sendMessageMutation = useMutation(
    (message: string) =>
      sendMessage(selectedChatID, message, currentThread?.resv_key),
    {
      onSuccess: (newMessage) => {
        setNewMessage("");
        // setMessages((prevMessages) => [...prevMessages, newMessage]);
      },
      onError: (error:Error) => {
        toast.error(error.message ? t(error.message) : t("general.requestError"));
        setIsSendingMessage(false);
        setDisableSendMessage(false);
      },
    }
  );
  const pinChatMutation = useMutation(pinChat, {
    onSuccess: (pinnedThread) => {
      setThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.thread_id === pinnedThread.thread_id ? pinnedThread : thread
        )
      );
    },
    onError: (error:Error) => {
      toast.error(error.message ? t(error.message) : t("general.requestError"));

    },
  });
  const archiveChatMutation = useMutation(archiveChat, {
    onSuccess: (archivedThread) => {
      setThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.thread_id === archivedThread.thread_id
            ? archivedThread
            : thread
        )
      );
    },
    onError: (error:Error) => {
      toast.error(error.message ? t(error.message) : t("general.requestError"));

    },
  });
  const muteChatMutation = useMutation(muteChat, {
    onSuccess: (mutedChat) => {
      setThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.thread_id === mutedChat.thread_id ? mutedChat : thread
        )
      );
    },
    onError: (error:Error) => {
      toast.error(error.message ? t(error.message) : t("general.requestError"));
    },
  });
  const handlePinChat = (thread_id: string) => {
    pinChatMutation.mutate(thread_id);
  };
  const handleArchiveChat = (thread_id: string) => {
    archiveChatMutation.mutate(thread_id);
  };
  const handleMuteChat = (thread_id: string) => {
    muteChatMutation.mutate(thread_id);
  };
  const scrollToBottom = () => {
    if (messagesEnd.current) {
      setTimeout(() => {
        messagesEnd.current?.scrollTo({
          top: messagesEnd.current.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  const handleSendMessage = () => {
    if (!newMessage.trim() || !selectedChatID) return;
    sendMessageMutation.mutate(newMessage);
    setIsSendingMessage(true);
    setDisableSendMessage(true);
  };
  
  // Filter threads based on the search input
  const filteredThreads = useMemo(() => {
    const matches = threads.flat().filter((thread) => {
      const matchesSearch =
        thread.name && thread.name.toLowerCase().includes(search.toLowerCase());
      const matchesFilter =
        (filter === "all" && !thread.archived) ||
        (filter === "unread" && !thread.has_been_read) ||
        (filter === "archived" && thread.archived);
      return matchesSearch && matchesFilter;
    });
    return matches;
  }, [threads, search, filter]);

  const pinnedThreads = useMemo(
    () => filteredThreads.filter((thread) => thread.pinned),
    [filteredThreads]
  );
  const normalThreads = useMemo(
    () => filteredThreads.filter((thread) => !thread.pinned),
    [filteredThreads]
  );
  const displayThreads = useMemo(
    () => [...pinnedThreads, ...normalThreads],
    [pinnedThreads, normalThreads]
  );
  const clearSearch = () => {
    setSearchValue("");
  };

  const { refetch: refetchDataModules } = useQuery<any>([], () => {
    fetchAllModules().then((data: any) => {
      const moduleFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.channel_manager
      );
      if (moduleFinded) {
        if (moduleFinded?.active_locals_count > 0) {
          setDisplayMod({
            display: false,
            moduleName: "",
          });
        } else {
          setDisplayMod({
            display: true,
            moduleName: t(`module.id${moduleFinded.id}`).toUpperCase(),
          });
        }
      } else {
        setDisplayMod({
          display: true,
          moduleName: "NOTAVAILABLE",
        });
      }
      return data;
    });
  });
  useEffect(() => {
    threadsRef.current = threads;
  }, [threads]);
  useEffect(() => {
    if (filter === "all" || filter === "unread" ? hasMore : hasMoreArchived) {
      loadMoreThreads();
    }
  }, [currentPage, currentArchivedPage, hasMore, hasMoreArchived, filter]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearch(searchValue), 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);
  useEffect(() => {
    setLoading(true);
    confirmToken.mutate(token);
  }, [token]);

  useEffect(() => {
    confirmToken.mutate(token);
  }, [token]);

  useEffect(() => {
    socketManagerV2.init("messages");
    return () => socketManagerV2.closeConnection();
  }, []);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    console.log("LISTENING ON SOCKET ")
    socketManagerV2.subscribe({
      topic: `message_channel/${profile?.user_key}`,
    });

    socketManagerV2.listen({
      event: "message",
      callback: (data) => callbackRef.current(data),
    });

    return () => {
      socketManagerV2.unsubscribe({
        topic: `message_channel/${profile?.user_key}`,
      });
      socketManagerV2.unlisten({
        event: "message",
        callback: (data) => callbackRef.current(data),
      });
    };
  }, [profile]);
  // Grouping and sorting messages by date
  useEffect(() => {
    const newGroupedMessages: { [date: string]: Message[] } = {};
    messages.forEach((msg) => {
      const dateKey = msg.sent_on.toISOString().split("T")[0];
      if (!newGroupedMessages[dateKey]) {
        newGroupedMessages[dateKey] = [];
      }
      newGroupedMessages[dateKey].push(msg);
    });

    setGroupedMessages(newGroupedMessages);
    setSortedDates(
      Object.keys(newGroupedMessages).sort(
        (a, b) => new Date(b).getTime() - new Date(a).getTime()
      )
    );
  }, [messages]);
  useEffect(() => {
    const fetchNewThreads = async () => {
      if (loading) return;

      const matches = threads.flat().filter((thread) => {
        const matchesSearch = thread.name
          .toLowerCase()
          .includes(search.toLowerCase());
        const matchesFilter =
          (filter === "all" && !thread.archived) ||
          (filter === "unread" && !thread.has_been_read) ||
          (filter === "archived" && thread.archived);
        return matchesSearch && matchesFilter;
      });

      if (matches.length === 0 && search) {
        setLoading(true);
        const newThreads = await fetchMessageThreads({
          query: search.toLowerCase(),
        });
        if (newThreads.length > 0) {
          setThreads((prevThreads) => [...prevThreads, ...newThreads.flat()]);
        } else {
          filter === "all" || filter === "unread"
            ? setHasMore(false)
            : setHasMoreArchived(false);
        }
        setLoading(false);
      }
    };

    fetchNewThreads();
  }, [threads, search, filter]);
  useEffect(() => {
    refetchDataModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      className="flex h-screen"
      style={{
        height: "85vh",
      }}
    >
      {/* Sidebar */}
      <div className={`w-full md:w-1/3 bg-gray-100 border-r flex flex-col h-full ${isSidebarVisible ? "" : "hidden"} sm:flex`}>
        <div className="flex items-center justify-between bg-gray-200 p-4 shadow-md rounded-t-lg">
          {/* Messaging Header Title */}
          <h1 className="text-2xl font-semibold text-gray-700">Messaging</h1>
        </div>

        {/* Sidebar Content */}
        <div className="p-4">
          <div className="relative">
            <input
              type="text"
              value={searchValue}
              onChange={(event) => {
                const value = event.target.value;
                
                // Guard Empty check, max length, and valid characters
                if (
                  value.length > 50 ||           
                  !/^[a-zA-Z0-9 ]*$/.test(value) 
                ) {
                  return; 
                }
            
                setSearchValue(value);  
              }}
              className="w-full border rounded-lg p-2 focus:border-cyan-600 focus:outline-none"
              placeholder={t("messaging.filter.search")}
            />
            {/* Clear Button */}
            {searchValue && (
              <button
                type="button"
                onClick={clearSearch}
                className="absolute text-gray-400 right-2 top-1/2 transform -translate-y-1/2 bg-transparent border-none cursor-pointer"
              >
                X
              </button>
            )}
          </div>
        </div>
        <div className="bg-gray-100 p-4 flex items-center justify-between overflow-auto">
          <div className="flex space-x-2">
          <Button
                className={`text-white-500 hover:text-white-700 ${
                  filter === "all"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-600"
                }`}
                onClick={() => {
                  if (filter === "all") {
                    return; 
                  }
                  handleFilterThreads("all");
                }}
                label={t("messaging.filter.all")}
              />

              <Button
                className={`text-white-500 hover:text-white-700 ${
                  filter === "unread"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-600"
                }`}
                onClick={() => {
                  if (filter === "unread") {
                    return; 
                  }
                  handleFilterThreads("unread");
                }}
                label={t("messaging.filter.unread")}
              />
            <Button
              className={`text-white-500 hover:text-white-700 ${
                filter === "archived"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
              onClick={() => handleFilterThreads("archived")}
              label={t("messaging.filter.archived")}
            />
          </div>
        </div>
        <div id="scrollableDiv" className="flex-1 overflow-y-auto mt-4">
          <InfiniteScroll
            dataLength={displayThreads.length}
            next={() => {
              if (filter === "all" || filter === "unread") {
                setCurrentPage((prevPage) => prevPage + 1);
              } else {
                setCurrentArchivedPage(
                  (prevArchivedPage) => prevArchivedPage + 1
                );
              }
            }}
            hasMore={
              filter === "all" || filter === "unread"
                ? hasMore || loading
                : hasMoreArchived || loading
            }
            loader={
              <div className="flex justify-center">
                <ProgressBar />
              </div>
            }
            endMessage={
              <p
                className="mt-4"
                style={{ textAlign: "center", color: "gray" }}
              >
                {t("messaging.endOfList")}
              </p>
            }
            scrollableTarget="scrollableDiv"
          >
            {displayThreads.map((thread, index) => (
              <MessageThread
                thread_id={thread.thread_id}
                key={thread.thread_id}
                name={thread.name}
                resv_key={thread.resv_key}
                archived={thread.archived}
                isSelected={selectedChatID === thread.thread_id}
                pinned={thread.pinned}
                last_message={thread.last_message}
                has_been_read={thread.has_been_read}
                muted={thread.muted}
                onSelect={() => {
                  if (selectedChatID === thread.thread_id) {
                    return;
                  }
                  handleSelectChat(thread);
                }}
                onPin={handlePinChat}
                onArchive={handleArchiveChat}
                onMute={handleMuteChat}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="w-full md:w-2/3 flex flex-col h-full">
        {selectedChat ? (
          <>
            {/* Chat Header */}
            <div className="bg-gray-200 p-4 flex items-center justify-between rounded-t-lg shadow-md">
              {/* Chat Name and Reservation Key */}
              <div className="text-gray-700">
                <div className="text-2xl font-semibold">
                  {currentThread?.name}
                </div>
                <div className="text-sm text-gray-500">
                  {currentThread?.resv_key}
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-4">
                <Button
                  className="text-white-500 hover:text-white-600 p-2 transition-colors duration-300"
                  onClick={() =>
                    setCurrentResv(currentThread ? currentThread.resv_key : "")
                  }
                  label=""
                  icon="information-circle"
                />
                <Button
                  className="text-white-500 hover:text-red-600 p-2 transition-colors duration-300"
                  onClick={() => {
                    setSelectedChat(null);
                    setSelectedChatID(null);
                    setCurrentThread(null);
                    setIsSidebarVisible(true);
                  }}
                  label=""
                  icon="close"
                />
              </div>
            </div>

            {currentResv && (
              <motion.div
                initial={{ right: -200 }}
                animate={{ right: 15 }}
                style={{
                  top: "0",
                  position: "fixed",
                  height: "100vh",
                  width: "100vw",
                  zIndex: 99,
                  padding: "2rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                onClick={(e) => setCurrentResv("")}
              >
                <div
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    borderRadius: "6px",
                    overflowX: "auto",
                    height: "100%",
                    boxShadow: "0px 2px 16px rgba(0,0,0,0.3)",
                    position: "relative",
                    backgroundColor: "#FFF",
                  }}
                  className="border border-gray-300"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div style={{ padding: "2rem" }}>
                    <ReservationDetail reservationId={currentResv} />
                  </div>
                </div>
              </motion.div>
            )}

            {/* Scrollable Chat Messages */}
            <div
              className="flex-1 bg-white p-4 overflow-y-auto flex flex-col"
              style={{ flexDirection: "column-reverse" }}
              ref={messagesEnd}
            >
              {!isLoadingMessages ? (
                sortedDates.map((date) => (
                  <div key={date} className="date-group">
                    <h3 className="text-sm text-gray-500 font-semibold mt-4 text-center">
                      {new Date(date).toLocaleDateString()}
                    </h3>
                    {groupedMessages[date].map((msg, index) => (
                      <MessageBubble
                        key={index}
                        message={msg.content}
                        message_id={msg.message_id}
                        type={msg.type}
                        sent_on={msg.sent_on}
                        translations={msg.translations}
                        profile_lang={profile?.lang}
                        attachments={msg.attachments}
                      />
                    ))}
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center h-full">
                  <CircleSpinner color={"primary"} />
                </div>
              )}
            </div>

            {/* Message Input */}
            <div className="bg-gray-100 p-4 flex items-center">
              <MessageInput
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                placeholder={t("messaging.typeMessage")}
                className="flex-1 rounded-lg p-2 mr-4"
                disabled={disableSendMessage}
                emoji_lang={profile?.lang}
              />
              {!isSendingMessage ? (
                <Button
                  className="bg-blue-500 text-white p-2 rounded-lg"
                  label=""
                  icon="send"
                  disabled = {!(newMessage.length > 0)}
                  onClick={handleSendMessage}
                />
              ) : (
                <div className="p-2">
                  <CircleSpinner color={"primary"} />
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {!isLoadingMessages && (
              <div className="flex-1 flex items-center justify-center sm:flex hidden">
              <p className="text-gray-500">
                {t("messaging.selectChatPrompt")}
              </p>
            </div>            
            )}
            {isLoadingMessages && (
              <div className="flex justify-center items-center h-full">
                <CircleSpinner color={"primary"} />
              </div>
            )}
          </>
        )}
      </div>
      <Modal visible={displayMod.display} className={"w-full"}>
        <ModalCard
          title={`${displayMod.moduleName.toUpperCase()} (${t(
            "module.statusInactive"
          )})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: displayMod.moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() =>
                setDisplayMod({
                  display: false,
                  moduleName: "",
                })
              }
              onSave={() => {
                if (displayMod.moduleName === "NOTAVAILABLE") {
                  toast.error("NOTAVAILABLE");
                } else {
                  history.push(`/modules/module#${moduleId.channel_manager}`);
                }
              }}
              saveLabel={t("module.purchase")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </PageWrapper>
  );
}
