import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../components/Button";
import DeleteDialog from "../components/DeleteDialog";
import Icon from "../components/Icon";
import PageWrapper from "../components/PageWrapper";
import ReservationAutomation from "../components/reservation/ReservationAutomation";
import ReservationColorSwitch from "../components/reservation/ReservationColorSwitch";
import ReservationDocuments from "../components/reservation/ReservationDocuments";
import ReservationGeneralInfo from "../components/reservation/ReservationGeneralInfo";
import ReservationLoadingSkeleton from "../components/reservation/ReservationLoadingSkeleton";
import ReservationNotifications from "../components/reservation/ReservationNotifications";
import ReservationOnlineCheckin from "../components/reservation/ReservationOnlineCheckin";
import ReservationOptions from "../components/reservation/ReservationOptions";
import ReservationServices from "../components/reservation/ReservationServices";
import TabSelector from "../components/TabSelector";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { deleteReservation, fetchReservationv3 } from "../shared/queries";
import { ReservationV3, SingleApiResponseV3 } from "../types";

type ReservationViewParams = {
  id: string | undefined;
};

export default function ReservationView() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<ReservationViewParams>();
  const { hash } = useLocation();

  const { data, isLoading, refetch } = useQuery<
    SingleApiResponseV3<ReservationV3>,
    Error
  >(["reservation", id], () => fetchReservationv3(id), {
    onError: () => {
      toast.error(t("general.error"));
      history.goBack();
    },
    onSuccess: () => {},
  });

  const [showDelete, setShowDelete] = useState(false);
  const [needDocumentValidation, setNeedDocumentValidation] = useState(false);
  const [reservation, setReservation] = useState<ReservationV3>();
  const tabs = [
    "#general",
    "#documents",
    "#notifications",
    "#services",
    "#automation",
    "#webcheckin",
  ];
  const [currentTab, setCurrentTab] = useState<string>("general");
  const [syncTitle, setSyncTitle] = useState<string>("");
  const [syncTooltip, setSyncTooltip] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.results) {
      const res = data.results;
      setReservation(res);
      setNeedDocumentValidation(
        res && !res.chk_documents_confirm && res.checkin_status === "PEND"
          ? true
          : false
      );
      if (res.chk_documents_done) {
        setSyncTitle(t("reservations.resDirtyCompl"));
        setSyncTooltip(t("reservations.resDirtyComplDesc"));
      } else {
        if (res.changed_by_user) {
          setSyncTitle(t("reservations.resDirty"));
          setSyncTooltip(t("reservations.resDirtyDesc"));
        } else {
          setSyncTitle(t("reservations.resNoDirty"));
          setSyncTooltip(t("reservations.resNoDirtyDesc"));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function isReservationActive() {
    if (reservation?.resv_status === "ACTIVE") return true;
    return false;
  }

  function isAbleToCheckIn() {
    if (!reservation) return false;
    if (
      (reservation.checkin_status === "NONEED" ||
        reservation.checkin_status === "OK") &&
      isReservationActive()
    ) {
      return true;
    }
  }

  const deleteMutation = useMutation(() => deleteReservation(id), {
    onSuccess: () => {
      history.push("/reservations");
    },
  });

  function renderCheckInStatus() {
    switch (reservation?.checkin_status) {
      case "NONEED":
        return (
          <div
            className={
              "font-medium text-xs flex items-center text-gray-800 bg-gray-100 rounded-full p-0.5 border-2 border-gray-800"
            }
          >
            <div className={"w-3 h-3 bg-gray-800 mr-2 rounded-full"}></div>{" "}
            {t("resv.notNecessary")}
          </div>
        );
      case "WAIT":
        return (
          <div
            className={
              "font-medium text-xs flex items-center text-red-800 bg-red-200 rounded-full p-0.5 border-2 border-red-800"
            }
          >
            <div className={"w-3 h-3 bg-red-800 mr-2 rounded-full"}></div>{" "}
            {t("resv.notExecuted")}
          </div>
        );
      case "PEND":
        return (
          <div
            className={
              "font-medium text-xs flex items-center text-yellow-800 bg-yellow-300 rounded-full p-0.5 border-2 border-yellow-800"
            }
          >
            <div className={"w-3 h-3 bg-yellow-800 mr-2 rounded-full"}></div>{" "}
            {t("resv.waitingConfirmation")}
          </div>
        );
      case "OK":
        return (
          <div
            className={
              "font-medium text-xs flex items-center text-green-800 bg-green-100 rounded-full p-0.5 border-2 border-green-800"
            }
          >
            <div className={"w-3 h-3 bg-green-800 mr-2 rounded-full"}></div>{" "}
            {t("resv.confirmed")}
          </div>
        );
      default:
        break;
    }
  }

  /* useBreadcrumbs([
    { label: t("reservations.reservations"), route: "/reservations" },
    { label: reservation ? reservation.resv_key || "..." : "..." },
  ]); */

  useEffect(() => {
    if (tabs.indexOf(hash) >= 0) {
      setCurrentTab(hash.substring(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.push(window.location.pathname + `#${currentTab}`);
  }, [currentTab]);
  return (
    <PageWrapper className={"pb-4"}>
      <DeleteDialog
        askConfirmation={true}
        isLoading={deleteMutation.isLoading}
        visible={showDelete}
        message={t("reservations.delete")}
        confirmationKey={t("general.iConfirm")}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          deleteMutation.mutate();
        }}
      />

      {isLoading && <ReservationLoadingSkeleton />}

      {reservation && reservation.local && !isLoading ? (
        <>
          <div>
            <div
              onClick={() => history.push('/reservations')}
              className={
                "flex items-center hover:text-gray-800 text-gray-500 cursor-pointer text-sm"
              }
            >
              <Icon
                name={"arrow-narrow-left"}
                className={"mr-2"}
                size={"20px"}
              />{" "}
              {t("general.backToList")}
            </div>
            <div className={"mt-8"}>
              <div className={"flex justify-between items-center"}>
                <div className={"flex-1 w-full"}>
                  <div className="flex w-full justify-between">
                    <div className={"flex items-center"}>
                      <div className={"mr-2"}>
                        <ReservationColorSwitch
                          reservation={reservation}
                          onSave={refetch}
                        />
                      </div>
                      <div className={"text-2xl font-bold"}>
                        <span className={"text-disambuigation"}>
                          {reservation.resv_key}
                        </span>{" "}
                        <span className={"text-gray-400 font-normal"}>–</span>{" "}
                        {reservation.name}
                      </div>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setCurrentTab("services");
                          setOpenModal(true);
                        }}
                        color={"primary"}
                        label={t("resv.vikeyPayExtra")}
                        size="medium"
                      />
                      <Button
                        onClick={() => setShowDelete(true)}
                        color={"negative"}
                        label={t("general.delete")}
                        flat
                      />
                    </div>
                  </div>

                  <div className={"py-2 border-t mt-2 border-gray-300 lg:mb-6"}>
                    <div className={"flex flex-wrap py-1 -mx-4"}>
                      <div
                        className={
                          "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                        }
                      >
                        <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                          <div className={"mb-1 font-medium"}>
                            {t("resv.total")}
                          </div>
                          <div>{reservation.price || "ND"} EUR</div>
                        </div>
                      </div>

                      <div
                        className={
                          "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                        }
                      >
                        <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                          <div className={"mb-1 font-medium"}>
                            {t("resv.apartment")}
                          </div>
                          <div>
                            <Link
                              className={"text-cyan-600"}
                              to={"/apartments/" + reservation.local.local_key}
                            >
                              {reservation.local.public_name}
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                        }
                      >
                        <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                          <div className={"mb-1 font-medium"}>
                            {t("resv.state")}
                          </div>
                          <div>{renderCheckInStatus()}</div>
                        </div>
                      </div>

                      <div
                        className={
                          "px-4 mb-3 w-1/2 md:mb-0 md:w-auto border-gray-200 text-gray-600 text-sm"
                        }
                      >
                        <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                          <div className={"mb-1 font-medium"}>
                            {t("resv.access")}
                          </div>
                          <div>
                            {isAbleToCheckIn() && (
                              <span className={"text-green-600 font-medium"}>
                                {t("resv.enabled")}
                              </span>
                            )}
                            {!isAbleToCheckIn() && (
                              <span className={"text-red-600 font-medium"}>
                                {t("resv.disabled")}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {reservation.integr_ref && (
                        <div
                          className={
                            "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-l border-gray-200 text-gray-600 text-sm"
                          }
                        >
                          <div
                            className={"pb-3 md:pb-0 border-b md:border-b-0"}
                          >
                            <div className={"mb-1 font-medium"}>
                              {t("reservations.synced")}
                            </div>
                            <div>
                              <div
                                data-tip={syncTooltip}
                                className={
                                  "font-medium flex items-center text-gray-600"
                                }
                              >
                                {syncTitle}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TabSelector
            tabs={[
              {
                key: "general",
                label: t("resv.general"),
                active: currentTab === "general",
              },
              {
                key: "documents",
                label: t("resv.docsandburo"),
                pulse: needDocumentValidation,
                active: currentTab === "documents",
              },
              {
                key: "notifications",
                label: t("resv.notifications"),
                active: currentTab === "notifications",
              },
              {
                key: "services",
                label: t("resv.services"),
                active: currentTab === "services",
              },
              {
                key: "automation",
                label: t("resv.automation"),
                active: currentTab === "automation",
              },
              {
                key: "webcheckin",
                label: t("resv.onlineCheckIn"),
                active: currentTab === "webcheckin",
              },
            ]}
            className={"mt-4 mb-8"}
            onChange={(tab) => setCurrentTab(tab.key)}
          />

          {currentTab === "general" && (
            <>
              <ReservationGeneralInfo
                loading={isLoading}
                reservation={reservation}
                onSave={() => refetch()}
              ></ReservationGeneralInfo>

              <ReservationOptions
                reservation={reservation}
                onViewDocuments={() => setCurrentTab("documents")}
              ></ReservationOptions>
            </>
          )}
          {currentTab === "notifications" && (
            <>
              <ReservationNotifications
                reservation={reservation}
                onSave={() => refetch()}
              ></ReservationNotifications>
            </>
          )}
          {currentTab === "documents" && (
            <ReservationDocuments
              onSave={() => {
                refetch();
              }}
              reservation={reservation}
            ></ReservationDocuments>
          )}

          {currentTab === "automation" && (
            <ReservationAutomation
              reservation={reservation}
            ></ReservationAutomation>
          )}

          {currentTab === "services" && (
            <ReservationServices
              reservation={reservation}
              openModal={openModal}
              isClosing={(isClosing: boolean) => {
                setOpenModal(isClosing);
              }}
            ></ReservationServices>
          )}

          {currentTab === "webcheckin" && (
            <ReservationOnlineCheckin
              reservation={reservation}
              onSave={refetch}
            ></ReservationOnlineCheckin>
          )}
        </>
      ) : (
        <div
          style={{
            boxShadow:
              "0px 2px 8px rgba(0,0,0,0.03), 0px 0px 0px 1px rgba(0,0,0,0.05)",
          }}
          className={"bg-gray-50 rounded-2xl overflow-hidden mt-1"}
        >
          <div
            className={
              "bg-white items-center w-full p-4 flex flex-wrap justify-between rounded-2xl rounded-tr-none rounded-tl-none"
            }
          >
            <div>
              <strong className={"text-gray-500"}>
                {t("resv.resNotAvailable")}
              </strong>
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
}
